import React from 'react';

import { GET } from '../../../utils/AxiosRequest';

import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import IconButton from '@mui/material/IconButton';
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';


import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { darken, lighten } from '@mui/material/styles';



export const DevisBody = React.forwardRef((props, ref) => {
	const apiRef = useGridApiRef();
	const [lastIndex, setLastIndex] = React.useState("");
	const [references, setReferences] = React.useState([]);
	const [rows, setRows] = React.useState([]);

	const columns = [
		{field: 'action', headerName: 'Action', width: 100, sortable: false, renderCell: (params) => (
			<Stack width='100%' direction='row'justifyContent="space-around">
				<IconButton color="primary" onClick={() => AddRow(params.row.id)}>
					<AddCircleTwoToneIcon />
				</IconButton>
				<IconButton color="error" onClick={() => DeleteRow(params.row.id)}>
					<RemoveCircleTwoToneIcon/>
				</IconButton>
			</Stack>
		)},
		{field: 'designation', headerName: 'Désignation', flex: 1, sortable: false, editable: true, renderCell: (params) => (
			params.row.designation
		) },
		{field: 'quantity', headerName: 'Quantité', flex: 0.5, sortable: false, editable: true, renderCell: (params) => (
			(params.row.quantity === "") ? "" : parseFloat(params.row.quantity).toFixed(2)
		) },
		{field: 'price', headerName: 'Prix Unitaire', flex: 0.5, sortable: false, editable: true, renderCell: (params) => (
			(params.row.price === "") ? "" : parseFloat(params.row.price).toFixed(2) + " € HT"
		)},
		{field: 'total', headerName: 'Total Hors-Taxe',flex: 0.5, sortable: false, renderCell: (params) => {
			if(params.row.dataId !== -2) {
				params.row.total = (params.row.price === "" || params.row.quantity === "") ? 0 : parseFloat(params.row.price * params.row.quantity);
				return (params.row.price === "" || params.row.quantity === "") ? "" : parseFloat(params.row.price * params.row.quantity).toFixed(2) + " € HT"
			}
			else return params.row.total.toFixed(2) + " € HT";
				
		}},
		{field: 'reference', headerName: 'Référence', flex: 0.75, sortable: false, editable: true, renderCell: (params) => (
			<Select variant='standard' fullWidth value={params.row.dataId} onChange={(event) => {
				var currentLine = references.find(e => event.target.value === e.id);
				var newRows = [];
				setRows((prevRows) => {
					newRows = prevRows.map((row, i) =>  (params.row.id === row.id) ? {...row, 
						designation: (event.target.value === 0) ? "" : currentLine.designation,
						price: (event.target.value === 0) ? "" : currentLine.prix,
						quantity: (event.target.value === 0) ? "" : 1.00,
						total: (event.target.value === 0) ? 0 : currentLine.prix,
						status: 'normal',
						dataId: event.target.value,
					} : row);
					props.updateTotal(GetTotal(newRows));
					props.checkSaveEnabled();
					return newRows;
				})
			}}>
				{references.map((data, i) => (
					<MenuItem key={i} value={data.id}>{data.reference}</MenuItem>
				))}
			</Select>
		) },
	]

	React.useImperativeHandle(ref, () => ({
		getBodyData: () => GetAllData(),
		getTotal: () => GetTotal(rows),
		isBodyEmpty: () => IsEmpty(),
		loadData: () => {
			GetAllTarifsFromBd()
		},
		loadDevis: (devis) => { 
			var newDevis = JSON.parse(devis)
			var tmp = [];
			newDevis.map((row) => tmp.push({
				id:  Math.random().toString(36).substr(2, 9),
				designation: row.designation,
				price: String(row.price).split('€')[0],
				quantity: row.quantity,
				total: row.total,
				dataId: row.reference,
				status: GetStatus(row.reference)
			})) 
			setRows(tmp);
			AddRow(tmp[tmp.length - 1].id);
		},
		addPremadeLines: (lines) => {
			var emptyLine = GetFirstEmptyLine();
			var tmp = [];
			setRows((prevRows) => {
				if(emptyLine === -1)
					tmp = prevRows.concat(lines);
				else {
					prevRows.forEach((row, i) => {
						if(emptyLine === i) lines.forEach((line) => tmp.push(line))
						else tmp.push(row);
					})
				}
				return tmp;
			});
			props.updateTotal(GetTotal(tmp));
			if(tmp[tmp.length - 1].id === lastIndex) setLastIndex(tmp[tmp.length - 1].id)
		},
		addSpecialLine: (type) => {
			if(type === 0) AddSousTotal();
			if(type === 1) AddJump();
			if(type === 2) AddLineForText();
		},
		reloadTarifs: () => GetAllTarifsFromBd(),

	}))

	React.useEffect(() => {
		GetAllTarifsFromBd();
	}, []);

	function GetStatus(id){
		if(id === -2) return "sousTotal";
		if(id === -3) return "jump";
		return '';
	}

	//#region AXIOS REQUEST
	function GetAllTarifsFromBd(){
		return GET("devis/getAllTarifs", {})
		.then(data => {
			data.push({id: -1, reference: '', designation: '', prix: ''}); //Add empty line
			data.push({id: -2, reference: '', designation: '', prix: ''}); //Add sous total
			data.push({id: -3, reference: '', designation: '', prix: ''}); //Add jump line
			data.push({id: -4, reference: '', designation: '', prix: ''}); //Add jump line
			setReferences(data);
			var tmpRows = []
			for(var i = 0; i < 10; i++){
				tmpRows.push({id:  Math.random().toString(36).substr(2, 9), designation: "", quantity: "", price: "", total: 0, status: 'normal', dataId: -1});
			}
			setRows(tmpRows);
			setLastIndex(tmpRows[9].id);
		})
	}
	//#endregion

	//#region UTILS

	function UpdateRow(info, value){
		var currentLine = rows.find(e => e.id === info.id);
		if(currentLine === undefined || value === undefined) return;

		switch(info.field){
			case "designation" : currentLine.designation = value;
				break;
			case "quantity" : currentLine.quantity = parseFloat(value);
				break;
			case "price" : currentLine.price = parseFloat(value);
				break;
			default : ;
				break;
		}
		var newRows = [];
		setRows((prevRows) => {
			newRows = prevRows.map((row, i) =>  (info.id === row.id) ? {...row, 
				designation: (currentLine.dataId === 0) ? "" : currentLine.designation,
				price: (currentLine.dataId === 0) ? "" : currentLine.price,
				quantity: (currentLine.dataId === 0) ? "" : currentLine.quantity,
				total: (currentLine.dataId === 0) ? 0 : currentLine.price * currentLine.quantity,
				status: 'normal',
				dataId: currentLine.dataId,
			} : row);
			props.updateTotal(GetTotal(newRows));
			props.checkSaveEnabled();
			return newRows;
		})
	}
	function AddRow(id){
		var tmp = []
		setRows((prevRows) => {
			prevRows.forEach((row, i) => {
				tmp.push(row);
				if(id === row.id){	
					tmp.push({id:  Math.random().toString(36).substr(2, 9), designation: "", quantity: "", price: "", total: 0, status: 'normal', dataId: -1})
				}
			})
			return tmp;
		})
		if(id === lastIndex) setLastIndex(tmp[tmp.length - 1].id)
		return tmp[tmp.length - 1].id;
	}

	function DeleteRow(id){
		var tmp = [];
		setRows((prevRows) => {
			prevRows.forEach((row) => {
				if(row.id !== id) tmp.push(row);
			})
			return tmp;
		})
		if(id === lastIndex) setLastIndex(tmp[tmp.length - 1].id)
	}

	function GetFirstEmptyLine(){
		var emptyLine = -1;
		rows.forEach((row, i) => {
			if(row.dataId === -1 && emptyLine === -1) emptyLine = i;
		})
		return emptyLine;

	}
	function AddSousTotal(){
		var emptyLine = GetFirstEmptyLine();
		var tmp = [];
		var total = 0.00;
		rows.forEach((row, i) => {
			if(row.dataId !== "" && row.dataId !== -1 && i <= emptyLine && row.dataId !== -2 && row.dataId !== -3){
				total += parseFloat(row.quantity) * parseFloat(row.price);
			}
		});
		if(emptyLine === -1){
			var str = Math.random().toString(36).substr(2, 9);
			tmp = rows.concat[{id: str, designation: "SOUS-TOTAL", quantity: "", price: "", total: parseFloat(total), status: 'sousTotal', dataId: -2}];
			setLastIndex(str)
		} else {
			tmp = [].concat(rows);
			tmp[emptyLine] = {id:  Math.random().toString(36).substr(2, 9), designation: "SOUS-TOTAL", quantity: "", price: "", total: parseFloat(total), status: 'sousTotal', dataId: -2}
			// tmp.splice(emptyLine, 0, )
		}
		setRows(tmp);
	}

	function AddJump(){
		var emptyLine = GetFirstEmptyLine();
		var tmp = [];
		if(emptyLine === -1){
			var str = Math.random().toString(36).substr(2, 9);
			tmp = rows.concat[{id: str, designation: "", quantity: "", price: "", total: 0, status: 'jump', dataId: -3}];
			setLastIndex(str);
		} else {
			tmp = [].concat(rows);
			tmp[emptyLine] = {id:  Math.random().toString(36).substr(2, 9), designation: "", quantity: "", price: "", total: 0, status: 'jump', dataId: -3}
			// tmp.splice(emptyLine, 0, )
		}
		setRows(tmp);
	}

	function AddLineForText(){
		var emptyLine = GetFirstEmptyLine();
		var tmp = [];
		if(emptyLine === -1){
			var str = Math.random().toString(36).substr(2, 9);
			tmp = rows.concat[{id: str, designation: "TEXTE À CHANGER", quantity: "", price: "", total: 0, status: '', dataId: -4}];
			setLastIndex(str);
		} else {
			tmp = [].concat(rows);
			tmp[emptyLine] = {id:  Math.random().toString(36).substr(2, 9), designation: "TEXTE À CHANGER", quantity: "", price: "", total: 0, status: '', dataId: -4}
			// tmp.splice(emptyLine, 0, )
		}
		setRows(tmp);
	}

	function GetAllData(){
		var data = [];
		rows.forEach((row, i) => {
			if((row.designation !== "" || row.dataId === -3) && row.dataId !== -1){
				data.push({
					designation: row.designation,
					quantity: row.quantity,
					price: row.price,
					total: row.total,
					reference: row.dataId
				})
			}
		})
		return data;
	}

	function GetTotal(array){
		var total = 0;
		array.forEach((row, i) => {
			if(row.designation !== "" && row.dataId !== -1 && row.dataId !== -2 && row.dataId !== -3 && row.dataId !== -4 && row.dataId !== ""){
				total += parseFloat(row.total);
			}
		})
		return total
	}

	function IsEmpty(){
		var empty = true;
		rows.forEach((row, i) => {
			if(row.designation !== "" && row.dataId !== -1 && row.dataId !== -2 && row.dataId !== -3 && row.dataId !== -3 && empty){
				empty = false;
			}
		})
		return empty;
	}
	//#endregion

	return (
		<Stack width="85%" marginTop={5} sx={{
			'& .grid--sousTotal': {
				bgcolor: (theme) =>
				  GetBackgroundColor(theme.palette.warning.main, theme.palette.mode),
				'&:hover': {
				  bgcolor: (theme) =>
					GetHoverBackgroundColor(theme.palette.warning.main, theme.palette.mode),
				},
			  },
			  '& .grid--jump': {
				bgcolor: (theme) =>
				  GetBackgroundColor("#a9a9a9", theme.palette.mode),
				'&:hover': {
				  bgcolor: (theme) =>
					GetHoverBackgroundColor("#a9a9a9", theme.palette.mode),
				},
			  },
		}}>
			<DataGrid apiRef={apiRef} columns={columns} rows={rows} disableColumnFilter disableColumnMenu disableDensitySelector disableSelectionOnClick disableColumnSelector onCellEditStart={(info, event, other) => {
				if(info.row.id === lastIndex) AddRow(lastIndex);
				props.updateTotal(GetTotal(rows));
			}} onCellEditCommit={(info, event, other) => UpdateRow(info, event.target.value)}  getRowClassName={(params) => `grid--${params.row.status}`} sx={{marginBottom: 10}} />
		</Stack>
	)
})



function GetBackgroundColor(color, mode) {
	return (mode === 'dark') ? darken(color, 0.6) : lighten(color, 0.6);
}

function GetHoverBackgroundColor(color, mode){
	return (mode === 'dark') ? darken(color, 0.5) : lighten(color, 0.5);
}
