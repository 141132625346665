import React from "react";
import { GET, POST } from '../../utils/AxiosRequest'

import { PDFViewer } from "@react-pdf/renderer";
import { FacturePDF } from "./FacturePDF/FacturePdfGenerator";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle'
import { useImperativeHandle } from "react";
import { OpenSnackbar } from "../utils/Snackbar";

export const FactureViewer = React.forwardRef((props, ref) => {
	const [open, setOpen] = React.useState(false);
	const [name, setName] = React.useState("");
	const [factureData, setFactureData] = React.useState({});
	const [save, setSave] = React.useState(false);

	function getFacturesByDevisId(devisId) { return GET("factures/getFacturesByDevisId", { devisId: devisId }) }
	
	useImperativeHandle(ref, () => ({
		handleOpen: (factureId, devis, client, commande, isAbleToSave, dateFacture = null) => {
			generateFactureData(factureId, devis, client, commande, isAbleToSave, dateFacture);
		}
	}));

	const handleClose = () => {
		setOpen(false);
	}

	function generateFactureData(factureId, devis, client, commande, isAbleToSave, dateFacture){
		if(devis === null || client === null) return;

		var nom = "F_" + String(factureId).padStart(6, "0");
		var date = dateFacture ? dateFacture : getShortDate(new Date());
		var echeance = getEcheanceDate(parseInt(client[0].echeance));

		let currentInvoiceTotal = 0;
		let alreadyPaidAmount = 0;
		let existingInvoices = [];

		getFacturesByDevisId(devis[0].id)
		.then((data) => {
			var associatedInvoicesCount = 0;
			var totalAlreadyInvoicedPercentage = 0;
			existingInvoices = data;

			if(existingInvoices){
				associatedInvoicesCount = existingInvoices.length;
				existingInvoices.forEach((invoice) => {
					if(invoice.information){
						alreadyPaidAmount += JSON.parse(invoice.information).amount;
						totalAlreadyInvoicedPercentage += parseFloat(JSON.parse(invoice.information).percentage);
					}
				});
			}

			// No dueDateData defined at devis creation OR balance invoice not defined at devis creation OR total percentage with this invoice makes 100%
			// Note: This condition is copied from the ChangeDevisToPDF file since the devis has been reloaded
			let balanceInvoice = !devis[0].dueDatesData || !JSON.parse(devis[0].dueDatesData)[associatedInvoicesCount] || (totalAlreadyInvoicedPercentage + parseFloat(JSON.parse(devis[0].dueDatesData)[associatedInvoicesCount].percentage) >= 100);

			if(!balanceInvoice){
				currentInvoiceTotal = (devis[0].total * devis[0].dueDate.percentage) / 100;		
			}
			else{
				// Custom line can be used in order to adapt the price of the balance invoice
				if(devis[0].customLine){
					let currentDevisData = JSON.parse(devis[0].devisData)
					currentDevisData.push({
						"designation": devis[0].customDesignation,
						"price": "",
						"quantity": "",
						"reference": 177,
						"total": devis[0].customAmount
					});
					devis[0].devisData = JSON.stringify(currentDevisData) 
				}

				currentInvoiceTotal = devis[0].total;

				existingInvoices.forEach((invoice) => {
					let invoiceInformation = JSON.parse(invoice.information);
					currentInvoiceTotal -= invoiceInformation.amount;
				});
			}	

			existingInvoices.sort(function(a, b) { 
				return a.id - b.id;
			});
			
			var paramsData = {
				id: factureId,
				clientData: client[0],
				devis: devis[0],
				nomFacture: nom,
				date: date,
				echeance: echeance,
				commande: commande,
				balanceInvoice: balanceInvoice,
				existingInvoices: existingInvoices,
				currentInvoiceTotal: currentInvoiceTotal,
				alreadyPaidAmount: alreadyPaidAmount
			}
	
			setFactureData(paramsData);
			setSave(isAbleToSave);
			
			setName("Facture N°" + paramsData.nomFacture + " - " + client[0].nomEntite + ".pdf")
			setOpen(true);
		})

		
    }

	function saveFacture(){
		let information;
		if(factureData.devis.dueDate){
			information = factureData.devis.dueDate;
			information.amount = factureData.currentInvoiceTotal;
			information = JSON.stringify(information);
		}

		var params = {
			id: factureData.id,
            devisId: factureData.devis.id,
            clientId: factureData.clientData.id,
            nom: factureData.nomFacture,
            date: factureData.date,
            echeance: factureData.echeance,
            commande: factureData.commande,
			information: information
		}
		return POST("factures/insertNewFacture", params)
		.then(_ => {
			OpenSnackbar(0, "La facture a bien été sauvegardée", 5000)
			setSave(false);
		});
	}

	return (
		<Dialog fullWidth maxWidth="xl" open={open} onClose={handleClose}>
			<DialogTitle>{ (save) ? "Changer un devis en facture" : factureData.nomFacture}</DialogTitle>
			<DialogContent>
				<Stack spacing={2} direction="column" alignItems='center'>
					<CopyToClipboard text={name} style={{ marginLeft: "10px" }}>
 						<Tooltip title={"Copier le nom du pdf : " + name}>
		 					<div style={{display: 'flex', flexDirection: 'column'}}>
		 						<p>Cliquer ici pour copier le nom du pdf</p>
		 						<IconButton>
		 							<ContentPasteIcon color="primary" fontSize="small" />
		 						</IconButton>
							</div>
	 					</Tooltip>
	 				</CopyToClipboard>
					<PDFViewer style={{minHeight:"750px", maxHeight: '1500px', width: '100%'}}>
						<FacturePDF data={factureData}></FacturePDF>
					</PDFViewer>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => handleClose()}>Fermer</Button>
				{save && <Button variant="contained" color="success" onClick={() => saveFacture()}>Sauvegarder</Button>}
			</DialogActions>
		</Dialog>
		
	)
})


function getShortDate(date){
	const yyyy = date.getFullYear();
	let mm = date.getMonth() + 1; // Months start at 0!
	let dd = date.getDate();

	if (dd < 10) dd = "0" + dd;
	if (mm < 10) mm = "0" + mm;

	return dd + "/" + mm + "/" + yyyy;
};

function getEcheanceDate(id){
	var echeance = new Date();
	switch(id){
		case 2: echeance.setDate(echeance.getDate() + 30);
			break;
		case 3: 
			echeance = new Date(echeance.getFullYear(), echeance.getMonth() + 1, 1);
			echeance.setDate(echeance.getDate() + 30);
			break;
		case 4: 
			echeance = new Date(echeance.getFullYear(), echeance.getMonth() + 1, 1);
			echeance.setDate(echeance.getDate() + 45);
			break;
		case 5: 
			echeance = new Date(echeance.getFullYear(), echeance.getMonth() + 1, 10);
			break;
		case 6: echeance.setDate(echeance.getDate() + 60);
			break;
		default: break;
	}
	return getShortDate(echeance);
}
