/* eslint-disable react-hooks/exhaustive-deps */
import './Css/Reset.css';

//CSS
import 'bootstrap/dist/css/bootstrap.css';
import 'bootswatch/dist/lux/bootstrap.min.css';
import 'devextreme/dist/css/dx.light.css';
import './Css/variables.css'
import './Css/Projects.css';
import './Css/SortableTree.css'
// import "./App.css";
import '@nosferatu500/react-sortable-tree/style.css'; 

import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Session from 'react-session-api'

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';


import Projects from './pages/Projects'
import ProjectInformation from './pages/ProjectInformation';
import Devis from './pages/Devis';
import Login from './pages/Login';
import Facture from './pages/Facture';
import Planning from './pages/Planning';
import { Administration } from './pages/Administration';
import { Accompte } from './pages/Accompte';
import { Stack, AppBar, Toolbar, Typography, styled, Switch, useMediaQuery, IconButton } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import { publish } from './utils/Event';
import { SnackbarFeedback } from './components/utils/Snackbar';
import { Todo } from './pages/CustomTask';
import { Tasks } from './pages/Tasks';
import { CommonTodo } from './pages/CommonTask';
import { Checklist } from './pages/Checklist';
import { Improvement } from './pages/Improvement';


const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

// Application principale
export default function App (){
	const [mode, setMode] = React.useState(Session.get('mode') || 'light');
	const isDesktop = useMediaQuery('(min-width:1065px)');
	const colorMode = React.useMemo(() => ({
		toggleColorMode: () => {
			Session.set('mode', (mode === 'light') ? 'dark' : 'light')
		  	setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
		},
	}),[],);
  
	const theme = React.useMemo(() => createTheme({
		  	palette: { mode },
		}),[mode]
	);

	const [mobileSidebarOpen, setMobileSidebarOpen] = React.useState(true);
	// eslint-disable-next-line no-unused-vars
	const [dimensions, setDimension] = React.useState({
		height: window.innerHeight,
		width: window.innerWidth
	})

	function HandleSidebar(){
		publish('onToggleNavbar', {open: !mobileSidebarOpen})
		setMobileSidebarOpen(!mobileSidebarOpen);
	}

	//#region RESIZE
	React.useEffect(() => {
		function HandleResize(){
			setDimension({
				height: window.innerHeight,
				width: window.innerWidth
			})
		}

		window.addEventListener('resize', HandleResize);

		return _ => {
			window.removeEventListener('resize', HandleResize);
		}
	})
	//#endregion


	return (
		<ColorModeContext.Provider value={colorMode}>
			
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<Stack>
						{<AppBar position='static'>
							<Toolbar>
								{!isDesktop && <IconButton sx={{marginRight: 5}} onClick={() => HandleSidebar()}> <MenuIcon/> </IconButton>}
								<Typography variant={(isDesktop) ? 'h6' : 'body2'} textTransform='uppercase' component="div" sx={{ flexGrow: 1 }}>Inspiration Productions</Typography>
								<MaterialUISwitch checked={(mode === 'dark')} onClick={colorMode.toggleColorMode} />
							</Toolbar>
						</AppBar>}
						<AppContent />
						<SnackbarFeedback/>
					</Stack>
				</ThemeProvider>
			
		</ColorModeContext.Provider>
	);
};

const AppContent = () => {
	Session.config(true, 0);
	window.isOnWeb = window.versions === undefined;
	var authenticated = Session.get('authenticated');
	var pepoleData = JSON.parse(Session.get('pepoleData'));
	var authorisationLevel = (pepoleData === null) ? 0 : pepoleData.authorisationLevel;

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={(authenticated) ?  <Projects /> : <Login/>} />
				<Route path="/projets" element={(authenticated) ? <Projects /> : <Login/>} />
				<Route path="/projetInformation/" element={(authenticated) ? <ProjectInformation/> : <Login/>} />
				<Route path="/customTask" element={(authenticated) ? <Todo common={false} /> : <Login/>} />
				<Route path="/tasks" element={(authenticated) ? <Tasks common={false} /> : <Login/>} />
				<Route path="/commonTask" element={(authenticated) ? <CommonTodo /> : <Login/>} />
				<Route path="/planning" element={(authenticated) ? <Planning /> : <Login/>} />
				<Route path="/devis" element={(authenticated) ? ((authorisationLevel >= 2) ? <Devis /> : <Projects /> ) : <Login/>} />
				<Route path="/facture" element={(authenticated) ? ((authorisationLevel >= 3) ? <Facture /> : <Projects /> ) : <Login/>} />
				<Route path="/administration" element={(authenticated) ? ((authorisationLevel >= 4) ? <Administration /> : <Projects /> ) : <Login/>} />
				<Route path="/accompte" element={(authenticated) ?  <Accompte /> : <Login/>} />
				<Route path="/checklist" element={(authenticated) ?  <Checklist /> : <Login/>} />
				<Route path="/improvement" element={(authenticated) ?  <Improvement /> : <Login/>} />
				{/* path * = fonctionne si jamais l'url ne corresponds à rien */}
				<Route path="*" element={(authenticated) ? <Projects /> : <Login/>} />
			</Routes>
		</BrowserRouter>
	)
}


const MaterialUISwitch = styled(Switch)(({ theme }) => ({
	width: 62,
	height: 34,
	padding: 7,
	'& .MuiSwitch-switchBase': {
	  margin: 1,
	  padding: 0,
	  transform: 'translateX(6px)',
	  '&.Mui-checked': {
		color: '#fff',
		transform: 'translateX(22px)',
		'& .MuiSwitch-thumb:before': {
		  backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
			'#fff',
		  )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
		},
		'& + .MuiSwitch-track': {
		  opacity: 1,
		  backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
		},
	  },
	},
	'& .MuiSwitch-thumb': {
	  backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
	  width: 32,
	  height: 32,
	  '&:before': {
		content: "''",
		position: 'absolute',
		width: '100%',
		height: '100%',
		left: 0,
		top: 0,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
		  '#fff',
		)}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
	  },
	},
	'& .MuiSwitch-track': {
	  opacity: 1,
	  backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
	  borderRadius: 20 / 2,
	},
  }));
  