import React from 'react';
import { GET } from '../../../utils/AxiosRequest'

import classNames from "classnames";
import styles from "./ProjectHome.module.css";

import { FilterBar } from './ProjectList/FilterBar';
import { ProjectList } from './ProjectList/ProjectList';
import { UrgentProject } from './ProjectUrgent/UrgentProject';
import { FilterBarUrgent } from './ProjectUrgent/FilterBarUrgent';
import { Box, Tab, Tabs, useMediaQuery, useTheme, Stack } from '@mui/material';
import { Calendar } from '../../Planning/Calendar/Calendar';

export const ProjectHome = (props) => {
	const isDesktop = useMediaQuery('(min-width:1065px)');
	const theme = useTheme();
	const [pepoleList, setPepoleList] = React.useState([]);
	const [clientList, setClientList] = React.useState([]);
	const [taskTemplate, setTaskTemplate] = React.useState([]);
	const filterBarRef = React.useRef(null);
	const projectListRef = React.useRef(null);
	const urgentProjectRef = React.useRef(null);
	const filterBarUrgentRef = React.useRef(null);
	const [currentView, setCurrentView] = React.useState(0);

	React.useEffect(() => {
		return Promise.all([
			GET("projects/getAllEmployeInfo", {}),
			GET("projects/getClientNom", {}),
			GET("projects/getAllTasksTemplate", {}),
		]).then(data => {
			setPepoleList(data[0]);
			setClientList(data[1]);
			setTaskTemplate(data[2].sort((a, b) => a.id - b.id));
		})
	}, [])

	function ReloadProjectList(){
		projectListRef.current.reload();
	}

	function UpdatePepoleFilter(pepole){
		projectListRef.current.changePepoleFilter(pepole);
	}

	function UpdateClientFilter(client){
		projectListRef.current.changeClientFilter(client);
	}

	function UpdateOrderFilter(order){
		projectListRef.current.changeOrderFilter(order);
	}

	function UpdateSimplified(simplified){
		projectListRef.current.changeSimplified(simplified);
	}

	function UpdateMasked(masked){
		projectListRef.current.changeMasked(masked);
	}

	function UpdateTaskFilter(task){
		urgentProjectRef.current.updateType(task);
	}

	function UpdatePepoleUrgentFilter(task){
		urgentProjectRef.current.updatePepole(task);
	}

	function GetMobileView(){
		if(currentView === 0)
			return (
				<Box sx={{height: '100vh', overflowY: 'scroll'}} marginTop={2}>
					<Stack>
						<FilterBar ref={filterBarRef} pepoleList={pepoleList} updatePepoleFilter={UpdatePepoleFilter} clientList={clientList} updateClientFilter={UpdateClientFilter} updateOrderFilter={UpdateOrderFilter} updateSimplified={UpdateSimplified} updateMasked={UpdateMasked} reload={ReloadProjectList}/>
						<ProjectList ref={projectListRef} pepoleList={pepoleList}/>
					</Stack>
				</Box>
			)
		else if(currentView === 1)
			return (
				<Box sx={{height: '100vh', overflowY: 'scroll'}} marginTop={2}>
					<Stack>
						<FilterBarUrgent ref={filterBarUrgentRef} taskList={taskTemplate} pepoleList={pepoleList} updateTaskFilter={UpdateTaskFilter} updatePepoleFilter={UpdatePepoleUrgentFilter}/>
						<UrgentProject ref={urgentProjectRef} taskTemplate={taskTemplate} pepoleList={pepoleList}/>
					</Stack>
				</Box>
			)
		else 
		return (
			<Calendar/>
		)
	}

	if(isDesktop){
		return (
			<Stack width="100%" sx={{ height: '100%'}} direction="row" bgcolor={theme.palette.background.default}>
				<Stack className={classNames(styles.leftPart)} direction="column">
					<FilterBar ref={filterBarRef} pepoleList={pepoleList} updatePepoleFilter={UpdatePepoleFilter} clientList={clientList} updateClientFilter={UpdateClientFilter} updateOrderFilter={UpdateOrderFilter} updateSimplified={UpdateSimplified} updateMasked={UpdateMasked} reload={ReloadProjectList}/>
					<ProjectList ref={projectListRef} pepoleList={pepoleList}/>
				</Stack>
				<Stack className={classNames(styles.rightPart)} direction="column">
					<FilterBarUrgent ref={filterBarUrgentRef} taskList={taskTemplate} pepoleList={pepoleList} updateTaskFilter={UpdateTaskFilter} updatePepoleFilter={UpdatePepoleUrgentFilter}/>
					<UrgentProject ref={urgentProjectRef} taskTemplate={taskTemplate} pepoleList={pepoleList}/>
				</Stack>
					
			</Stack>
		)
	} else {
		return (
			<Stack width='100%' direction="column" bgcolor={theme.palette.background.default}>
				<Tabs value={currentView} onChange={(event, value) => setCurrentView(value)} centered>
					<Tab label="Projets" />
					<Tab label="Taches" />
					<Tab label="Taches Calendrier" />
				</Tabs>
				{GetMobileView()}
			</Stack>
		)
	}
}