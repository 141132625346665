import React from "react";
import { GET, UPDATE } from '../../../utils/AxiosRequest'

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle'

import { FactureViewer } from "../FactureViewer";
import { useTheme } from "@mui/material";

export const ChangeFactureCommande = (props) => {
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const facture = React.useRef(null);
	const [factureList, setFactureList] = React.useState([]);
	const [factureId, setFactureId] = React.useState(0);
	const [commande, setCommande] = React.useState("");
	const [oldCommande, setOldCommande] = React.useState("");

	function ChangeFactureId(event){
		setFactureId(event.target.value);
		setOldCommande((factureList[event.target.value].commande !== null) ? factureList[event.target.value].commande : "")
	};

	function ChangeCommande(event){
		setCommande(event.target.value);
	}

	function HandleOpen(){
		return GetAllFactures()
		.then(data => {
			setFactureList(data);
			setOpen(true);
			setOldCommande((data.length > 0) ? data[0].commande : "");
		})
	}

	function HandleClose(save){
		var id = (save) ? factureList[factureId].id : 0;
		var newCommande = (save) ? commande : "";
		setOpen(false)
		setCommande('');
		setOldCommande('');
		setFactureId('');
		if(save){
			var devis = [];
			return UpdateCommande(id, newCommande)
			.then(() => {return GetFactureById(id)})
			.then(data => { return GetDevisFromDb(data[0].devisId) })
			.then(data => {
				devis = data;
				return GetClientById(devis[0].idClient);
			}).then(client => facture.current.handleOpen(id, devis, client, commande, false))
		}
	}

	//#region AXIOS REQUESTS
	function GetAllFactures() { return GET("factures/getAllFactures") }
	function GetFactureById(id) { return GET("factures/getFactureById", {id: id}) }
	function UpdateCommande(id, commande) { return UPDATE("factures/updateFactureCommande", {id: id, commande: commande})}
	function GetDevisFromDb(id) { return GET("factures/getDevisById", {id: id}) }
	function GetClientById(id) { return GET("factures/getClientById", {id: id})} 

	//#endregion

	return (
		<div>
			<Button fullWidth variant="contained" onClick={() => HandleOpen()}>Choisir une facture a modifier le numéro de commande</Button>
			<Dialog fullWidth maxWidth="md" open={open} onClose={() => HandleClose(false)}>
				<DialogTitle color={theme.palette.text.primary}>Choisir une facture a modifier le numéro de commande</DialogTitle>
				<DialogContent>
					<Stack spacing={2} direction="column">
						<TextField select label="Facture à changer le numéro de commande" variant="standard" value={factureId} onChange={ChangeFactureId}>
							{factureList.map((facture, i) => (
								<MenuItem value={i} key={facture.id}>{facture.nom + " - " + facture.reference}</MenuItem>
							))}
						</TextField>
						<Stack width="100%" direction='row' spacing={2} justifyContent='space-between'>
							<TextField fullWidth disabled label="Ancien numéro de commande" variant="standard" value={oldCommande} onChange={setOldCommande} />
							<TextField fullWidth label="Nouveau numéro de commande" variant="standard" value={commande} onChange={ChangeCommande} />
						</Stack>
						
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => HandleClose(true)} color="success">Valider</Button>
					<Button onClick={() => HandleClose(false)}>Retour</Button>
				</DialogActions>
			</Dialog>
			<FactureViewer ref={facture}/>
		</div>
	)
}

