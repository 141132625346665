import React from 'react';

//CSS
import '../Css/Projects.css';
import { ProjectHome } from '../components/Projets/ProjectHome/ProjectsHome';
import { Stack } from '@mui/material';
import { Navbar } from '../components/Navbar';

const Projects = () => {
  return (
    <div>
		<Stack minWidth="100%" direction="row">
			<Navbar/>
			<ProjectHome/>
		</Stack>

    </div>

 
  );
};

export default Projects;

