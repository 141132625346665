import React from 'react';

import { Paper, TextField, Typography, Stack, useTheme } from '@mui/material';

export const ProjectCreationBrief = React.forwardRef((props, ref) => {
	const [brief, setBrief] = React.useState('');
	const theme = useTheme();

	React.useImperativeHandle(ref, () => ({
		GetData: () => {
			return { brief }
		},
		Reset: () => {
			setBrief('');
		}
	}))

	function ChangeBrief(event){ setBrief(event.target.value); }

	return (
		<Paper elevation={4} sx={{padding: 5, marginTop: 2}} hidden={props.hidden(0)}>
			<Typography mb={2} textAlign='center' variant="h6" color={theme.palette.text.primary}>Brief</Typography>
			<Stack direction='column' spacing={2}>
				<TextField fullWidth multiline rows={5} variant='standard' label='Contenu du brief' value={brief} onChange={ChangeBrief}/>
			</Stack>
		</Paper>
	)
})

