import React from "react";
import { GET } from '../../../utils/AxiosRequest'

import { DataGrid } from "@mui/x-data-grid";
import { Button, Stack, darken, lighten } from "@mui/material";
import { createSearchParams, useNavigate } from "react-router-dom";

export const CurrentProjects = (props) => {
	const { isProjectEnded } = props;
    const navigate = useNavigate();
    const [projects, setProjects] = React.useState([]);

    React.useEffect(() => {
        let isMounted = true;
		var getProjectPath = (isProjectEnded) ? "projects/getAllFinishedProjects" : "projects/getAllProjects";
		Promise.all([
			GET(getProjectPath),
			GET("projects/getAllDevisWithProject"),
			GET("administration/getAllFactures"),
		])
        .then(data => {
			if(isMounted){
				var array = [];
				data[0].forEach(project => {
					var devis = data[1].filter(element => element.idProjet === project.id);
					var factures = [];
					devis.forEach(element => {
						var factureTmp = data[2].find(item => element.id === item.devisId);
						if(factureTmp !== undefined){
							factures.push(factureTmp)
						}
					})
					project = {
						...project,
						devis,
						factures
					}
					array.push(project);
				})
				setProjects(array);
			}
        })

        return _ => { isMounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isProjectEnded])

    const columns = [
        {field: 'status', headerName:'État', width: 100},
        {field: 'name', headerName:'Nom du projet', width: 200,},
        {field: 'client', headerName:'Client', minWidth: 400, flex: 1},
        {field: 'devis', headerName:'Devis', minWidth: 300, flex: 1, },
        {field: 'commande', headerName:'Numero de Commande', minWidth: 300, flex: 1, },
        {field: 'factures', headerName:'Factures', minWidth: 300, flex: 1, },
        {field: 'action', headerName: 'Action', width:'200', renderCell: (params) => (
            <Button variant="contained" onClick={() => Navigate(params.row.id)}>Voir le projet</Button>
        )},
    ]

    const rows = [];
    projects.forEach(project => {
		var type = GetRowType(project);
        rows.push({
			id: project.id, 
			status: GetRowTypeText(type),
			type,
			name: project.nom, 
			client: project.nomEntite, 
			devis: GetNamesFromArray(project.devis),
			commande: GetCommandeFromArray(project.devis),
			factures: GetNamesFromArray(project.factures),
		})
    })

	function GetRowType(project){
		if(project.devis.length === 0){
			return 0;
		} else if(project.devis.length > project.factures.length){
			return 1;
		} else if(project.devis.length === project.factures.length){
			return 2;
		} else if(project.devis.length < project.factures.length){
			return 3;
		}
		return -1;
	}

	function GetNamesFromArray(devisList){
		return devisList.map(devis => ' ' + devis.nom);
	}

	function GetCommandeFromArray(devisList){
		return devisList.map(devis => {
			if(devis.numeroCommande !== ""){
				return ' ' + devis.numeroCommande;
			} else
				return null;                          
		});
	}

    function Navigate(id){
        var params = {id};
		navigate({
			pathname: `/projetInformation/`,
			search: `?${createSearchParams(params)}`
		});
    }

	function GetRowClasseName(type){ 
		switch (type) {
			case 0: return classes.noDevis;
			case 1: return classes.notAllFacture;
			case 2: return classes.allFacture;
			case 3: return classes.notAllLink;
		
			default: return classes.noDevis;
		}
	}

	function GetRowTypeText(type){
		switch (type) {
			case 0: return "Pas Commencé";
			case 1: return "En Cours";
			case 2: return " Fini";
			case 3: return "En Cours";
		
			default: return "Problème";
		}
	}

    return (
        <Stack justifyContent="center" height="80%" margin={5} sx={styles}>
            <DataGrid columns={columns} rows={rows} rowsPerPageOptions={[20, 50, 100]} pagination initialState={{pagination: { pageSize: 100, }}} getRowClassName={(params) => GetRowClasseName(params.row.type)} />
        </Stack>
    )
}

const PREFIX = 'Inspiration';

const classes = {
    noDevis: `${PREFIX}-no-devis`,
    notAllFacture: `${PREFIX}-not-all-facture`,
    notAllLink: `${PREFIX}-not-all-link`,
    allFacture: `${PREFIX}-all-facture`,

};


const getBackgroundColor = (color, mode) => mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) => mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedBackgroundColor = (color, mode) => mode === 'dark' ? darken(color, 0.3) : lighten(color, 0.3);

const styles = {
	'.MuiDataGrid-cell': {
		border: 'none !important',
	},
	[`& .${classes.noDevis}`]: {
		bgcolor : (theme) => getBackgroundColor(theme.palette.error.main, theme.palette.mode),
		'&:hover': {
			bgcolor: (theme) => getHoverBackgroundColor(theme.palette.error.main, theme.palette.mode) + ' !important',
		},
		'&.Mui-selected': {
			bgcolor: (theme) => getSelectedBackgroundColor(theme.palette.error.main, theme.palette.mode) + ' !important',
		},
	}, 
	[`& .${classes.notAllFacture}`]: {
		bgcolor : (theme) => getBackgroundColor(theme.palette.warning.main, theme.palette.mode),
		'&:hover': {
			bgcolor: (theme) => getHoverBackgroundColor(theme.palette.warning.main, theme.palette.mode) + ' !important',
		},
		'&.Mui-selected': {
			bgcolor: (theme) => getSelectedBackgroundColor(theme.palette.warning.main, theme.palette.mode) + ' !important',
		},
	}, 
	[`& .${classes.notAllLink}`]: {
		bgcolor : (theme) => getBackgroundColor(theme.palette.error.main, theme.palette.mode),
		'&:hover': {
			bgcolor: (theme) => getHoverBackgroundColor(theme.palette.error.main, theme.palette.mode) + ' !important',
		},
		'&.Mui-selected': {
			bgcolor: (theme) => getSelectedBackgroundColor(theme.palette.error.main, theme.palette.mode) + ' !important',
		},
	}, 
	[`& .${classes.allFacture}`]: {
		bgcolor : (theme) => getBackgroundColor(theme.palette.success.main, theme.palette.mode),
		'&:hover': {
			bgcolor: (theme) => getHoverBackgroundColor(theme.palette.success.main, theme.palette.mode) + ' !important',
		},
		'&.Mui-selected': {
			bgcolor: (theme) => getSelectedBackgroundColor(theme.palette.success.main, theme.palette.mode) + ' !important',
		},
	}, 
}