import React from 'react';
import { Navbar } from '../components/Navbar';
import { Stack } from '@mui/material';
import { Improvement as ImprovementComponent } from '../components/Improvement/Improvement';

// Page Devis
export const Improvement = (props) => {
    return (
		<Stack minWidth="100%" direction="row">
			<Navbar />
      		<ImprovementComponent />
		</Stack>
    );
};
