import React  from "react";
import { GET } from '../../../utils/AxiosRequest';

import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle'
import { useTheme } from "@mui/material";

export const SelectClient = (props) => {
	const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [clientList, setClientList] = React.useState([]);
    const [selected, setSelected] = React.useState(0);
    const changeSelected = (event) => setSelected(event.target.value);

    function HandleOpen(){
        
        return GET("projects/getClientNom",{})
        .then(data => {
            setClientList(data);
            setOpen(true);
        })
    }

    function HandleClose(apply){
        if(apply){
            props.updateClient(clientList[selected]);
        }
        setOpen(false);
    }

    return (
        <div>
            <Button fullWidth variant="contained" onClick={() => HandleOpen()} >Choisir Client</Button>
            <Dialog fullWidth maxWidth="md" open={open} onClose={() => HandleClose(false)}>
				<DialogTitle color={theme.palette.text.primary}>Choisir un client</DialogTitle>
				<DialogContent>
					<TextField fullWidth variant="standard" select value={selected} onChange={changeSelected}>
                        {clientList.map((client, i) => (
                            <MenuItem key={i} value={i}>{client.nomEntite}</MenuItem>
                        ))}
                    </TextField>
				</DialogContent>
				<DialogActions>
                    <Button onClick={() => HandleClose(false)}>Retour</Button>
                    <Button onClick={() => HandleClose(true)} color="success">Valider</Button>
				</DialogActions>
			</Dialog>
        </div>
    )
}