import React from 'react';
import { GET } from '../../../utils/AxiosRequest';


import { DataGrid } from '@mui/x-data-grid';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle'

import Button from '@mui/material/Button';


import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTheme } from '@mui/material';

import { FactureViewer } from "../FactureViewer";

export const ShowFactureAndAvoir = () => {
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = React.useState(0);
    const [factures, setFactures] = React.useState([]);
    const [avoirs, setAvoirs] = React.useState([]);
    const changeSelected = (event, tab) => setSelected(tab);

	function HandleOpen(){
		return Promise.all([
			GET("factures/getAllFactures"),
			GET("factures/getAllAvoirs"),
		]).then(data => {
			setFactures(data[0]);
			let avoirs = data[1];
			avoirs.forEach((avoir) => { avoir.nomDevis = data[0].find(element => element.id === avoir.factureId).nomDevis})
			setAvoirs(data[1]);
			setOpen(true);
		})
	}

	function HandleClose(){
		setOpen(false);
	}

	function GetView(){
		switch(selected){
			case 0: return <FacturesGrid data={factures}/>
			case 1: return <AvoirGrid data={avoirs}/>
			default: return <FacturesGrid data={factures}/>
		}
	}

	return (
		<div>
		<Button fullWidth variant='contained' onClick={() => HandleOpen()}>Récapitulatif Factures & Avoirs</Button>
			<Dialog fullWidth maxWidth="xl" open={open} onClose={() => HandleClose(false)}>
				<DialogTitle color={theme.palette.text.primary}>Récapitulatif Factures & Devis</DialogTitle>
				<DialogContent>
					<Tabs value={selected} onChange={changeSelected} centered>
						<Tab label="Factures" />
						<Tab label="Avoirs" />
					</Tabs>
					{GetView()}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => HandleClose()}>Retour</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}


const FacturesGrid = (props) => {
	const { data } = props;
	const facture = React.useRef(null);

	const columns = [
		{field: 'name', headerName: 'Nom', minWidth: 100, flex: 1},
		{field: 'devis', headerName: 'Devis', minWidth: 100, flex: 1},
		{field: 'reference', headerName: 'Référence', minWidth: 400, flex: 1},
		{field: 'client', headerName: 'Client', minWidth: 400, flex: 1},
		{field: 'date', headerName: 'Date', minWidth: 100, flex: 1},
		{field: 'cost', headerName: 'Montant', minWidth: 111, flex: 1},
	]

	function GetFactureById(id) { return GET("factures/getFactureById", {id: id}) }
	function GetDevisFromDb(id) { return GET("factures/getDevisById", {id: id}) }
	function GetClientById(id) { return GET("factures/getClientById", {id: id})}

	const factureClicked = (props) => {
		GetFactureById(props.id).then(factureData => {
			GetDevisFromDb(factureData[0].devisId).then(devisData => { 
				GetClientById(devisData[0].idClient).then(clientData => {
					facture.current.handleOpen(factureData[0].id, devisData, clientData, factureData.commande, false, factureData[0].date);
				})
			})
		})
	}

	const rows = [];
	data.forEach((line, i) => {
		rows.push({id: line.id, name: line.nom, devis: line.nomDevis, reference: line.reference, client: line.nomEntite, date: line.date, cost: parseFloat(line.total).toFixed(2) + ' €'})
	})

	return(
		<div>
			<DataGrid onRowClick={factureClicked} sx={{marginTop: 2, '.MuiDataGrid-cell:focus': { outline: 'none' }, '& .MuiDataGrid-row:hover': {cursor: 'pointer' }}} autoHeight columns={columns} rows={rows}/>
			<FactureViewer ref={facture}/>
		</div>
	)

}

const AvoirGrid = (props) => {
	const { data } = props;

	const columns = [
		{field: 'nom', headerName: 'Nom Avoir', minWidth: 100, flex: 1},
		{field: 'devis', headerName: 'Devis', minWidth: 100, flex: 1},
		{field: 'factureNom', headerName: 'Nom Facture', minWidth: 100, flex: 1},
		{field: 'designation', headerName: 'Désignation', minWidth: 600, flex: 1},
		{field: 'montant', headerName: 'Montant', minWidth: 111, flex: 1},
	]

	const rows = [];
	data.forEach(line => {
		rows.push({id: line.id, nom: line.nom, devis: line.nomDevis, factureNom: line.factureNom, designation: line.designation, montant: parseFloat(line.montant).toFixed(2) + '€'})
	})
	return (
		<DataGrid sx={{marginTop: 2}} autoHeight columns={columns} rows={rows}/>
	)
}