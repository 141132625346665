import React from "react";
import { DELETE, GET, POST } from '../../../utils/AxiosRequest'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, TextField, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { OpenSnackbar } from "../../utils/Snackbar";

export const EmployeView = (props) => {
	const [pepoles, setPepoles] = React.useState([])
	const deleteRef = React.useRef(null);

	React.useEffect( _ => {
		return LoadData();
	} , [])

	function LoadData(){
		GET("administration/getAllEmploye")
		.then(data => {
			setPepoles(data);
		})
	}

	const columns = [
		{field: 'name', headerName:'Prénom & Nom', minWidth: 100, flex: 1},
		{field: 'auth', headerName:'Niveau', minWidth: 100, flex: 1},
		{field: 'action', headerName:'Action', width: 200, renderCell: (params) => (
			<Button variant="contained" color="error" onClick={ _ => deleteRef.current.HandleOpen(params.row)}>Suppprimer</Button>
		)},
	];

	const rows = [];
	pepoles.forEach(item => {
		rows.push({
			id: item.id,
			name: item.prenom + " " + item.nom,
			auth: item.authorisationLevel 
		})
	})


	return (
		<Stack direction='column' spacing={2} justifyContent='center' height='80%' margin={5}>
			<DataGrid columns={columns} rows={rows}/>
			<AddPepoleDialog reload={LoadData}/>
			<DeletePepole ref={deleteRef} reload={LoadData} />
		</Stack>
	)
}

const AddPepoleDialog = (props) => {
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const [nom, setNom] = React.useState("");
	const [prenom, setPrenom] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [level, setLevel] = React.useState(1);

	function HandleClose(save){
		if(save){
			return POST('administration/insertEmploye', {nom, prenom, authorisationLevel: level})
			.then( _ => {
				return GET('administration/getLastInsertedEmploye');
			}).then(data => {
				return POST('administration/insertEmployeConnexion', {identifiant: nom, password, employeId: data[0].id})
			}).then( _ => {
				setOpen(false);
				OpenSnackbar(0, "La personne à bien été ajoutée")
				return props.reload();
			})
		}
		setOpen(false);
	}

	return (
		<div>
			<Button variant="contained" onClick={() => setOpen(true)}>Ajouter une nouvelle personne</Button>
			<Dialog fullWidth  open={open} onClose={() => HandleClose()}>
				<DialogTitle color={theme.palette.text.primary}>Ajouter une nouvelle personne</DialogTitle>
				<DialogContent>
					<Stack direction="column" spacing={2} >
						<TextField variant="standard" label="Nom" value={nom} onChange={(event) => setNom(event.target.value)} />
						<TextField variant="standard" label="Prénom" value={prenom} onChange={(event) => setPrenom(event.target.value)} />
						<TextField variant="standard" label="Mot de passe" value={password} onChange={(event) => setPassword(event.target.value)} />
						<TextField select variant="standard" label="Niveau d'authorisation" value={level} onChange={(event) => setLevel(event.target.value)}>
							<MenuItem value={1}>1</MenuItem>
							<MenuItem value={2}>2</MenuItem>
							<MenuItem value={3}>3</MenuItem>
							<MenuItem value={4}>4</MenuItem>
						</TextField>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button color='success' onClick={() => HandleClose(true)}>Valider</Button>
					<Button onClick={() => HandleClose(false)}>Retour</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

const DeletePepole = React.forwardRef((props, ref) => {
	const theme = useTheme();
	const [open, setOpen] =	React.useState(false)
	const [pepole, setPepole] =	React.useState({})

	React.useImperativeHandle(ref, _ => ({
		HandleOpen : (pepole) => {
			setPepole(pepole);
			setOpen(true)
		}
	}))

	function HandleClose(save){
		if(save){
			Promise.all([
				DELETE("administration/deleteEmploye", {id: pepole.id}),
				DELETE("administration/deleteEmployeConnexion", {employeId: pepole.id}),
			]).then( _ => {
				OpenSnackbar(0, "La personne à bien été supprimée");
				setOpen(false);
				return props.reload();
			})
		} else {
			setOpen(false);
		}
	}

	return (
		<Dialog fullWidth  open={open} onClose={() => HandleClose()}>
				<DialogTitle color={theme.palette.text.primary}>Supprimer une personne</DialogTitle>
				<DialogContent>
					<Stack direction="column" spacing={2} >
						<Typography variant="body1" color={theme.palette.text}>Voulez-vous vraiment supprimer {pepole.name} ?</Typography>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button color='error' onClick={() => HandleClose(true)}>Supprimer</Button>
					<Button onClick={() => HandleClose(false)}>Retour</Button>
				</DialogActions>
			</Dialog>
	)
})