import React  from "react";
import { GET } from '../../../utils/AxiosRequest';

import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import Stack from "@mui/material/Stack";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle'

import Chip from '@mui/material/Chip';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTheme } from "@mui/material";

export const AddPremadeLine = (props) => {
	const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = React.useState(1);
    const [references, setReferences] = React.useState([]);
    const [halfDay, setHalfDay] = React.useState("no");
    const changeSelected = (event) => setSelected(event.target.value);
    const changeHalfDay = (event) => setHalfDay(event.target.value);

    function HandleOpen(){
        return GET("devis/getAllTarifs",{})
        .then(data => {
            setReferences(data);
            setOpen(true);
        })
        
    }

    function HandleClose(apply){
        if(apply){
            var half = (halfDay === "yes");
            var line = (half) ? props.halfDayLines : props.lines;
            var tmp = [
                {id:  Math.random().toString(36).substr(2, 9), designation: (half) ? props.name + " Demi-Journée" : props.name, quantity: "", price: "", dataId: -4},
                {id:  Math.random().toString(36).substr(2, 9), designation: ("Temps passé : "), quantity: selected, price: "", dataId: -4},
            ];
            line.forEach((line) => {
                var item = references.find(e => e.reference === line.name);
                if(item !== undefined) 
                    tmp.push({id:  Math.random().toString(36).substr(2, 9), designation: item.designation, quantity: parseFloat(line.quantity).toFixed(2) * selected , price: item.prix, total: item.prix * parseFloat(line.quantity).toFixed(2) * selected , dataId: item.id})
            })
            props.addPremadeLines(tmp);
            
        }
        setOpen(false);
    }

    return (
        <div>
            <Button fullWidth variant="contained" onClick={() => HandleOpen()}>{props.buttonName ? props.buttonName : props.name}</Button>
            <Dialog fullWidth maxWidth="sm" open={open} onClose={() => HandleClose(false)}>
				<DialogTitle color={theme.palette.text.primary}>Ajouter un {props.buttonName ? props.buttonName : props.name}</DialogTitle>
				<DialogContent>
					<Stack direction='row' spacing={5} alignItems="center" justifyContent="space-between">
                        {props.halfDayLines.length > 0 && 
                            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                                <Chip label="Demi-journée ? "/>
                                <ToggleButtonGroup color="primary" value={halfDay} onChange={changeHalfDay}>
                                    <ToggleButton value="yes">OUI</ToggleButton>
                                    <ToggleButton value="no">NON</ToggleButton>
                                </ToggleButtonGroup>
                            </Stack>
                        }
                        <TextField fullWidth label="Nombre de jour" variant="standard" type="number" value={selected} onChange={changeSelected} />
                    </Stack>
                </DialogContent>
				<DialogActions>
					<Button onClick={() => HandleClose(true)} color="success">Valider</Button>
					<Button onClick={() => HandleClose(false)}>Retour</Button>
				</DialogActions>
			</Dialog>
        </div>
    )
}