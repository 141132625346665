import React from "react";
import { Snackbar, Alert, Slide } from "@mui/material"
import { publish, subscribe, unsubscribe } from "../../utils/Event";

export const SnackbarFeedback = (props) => {
	const [open, setOpen] = React.useState(false);
	const [severity, setSeverity] = React.useState('success');
	const [message, setMessage] = React.useState('');
	const [duration, setDuration] = React.useState(2000);

	React.useEffect(() => {
		subscribe('onSnackbarOpen', (data) => {
			setSeverity(data.detail.severity);
			setMessage(data.detail.message);
			setDuration(data.detail.duration);
			setOpen(true);
		})

		return _ => {
			unsubscribe('onSnackbarOpen', (data) => {});
		}
	}) 

	function HandleClose(){
		setOpen(false);
	}

	return (
		<Snackbar open={open} autoHideDuration={duration} onClose={HandleClose} TransitionComponent={SlideTransition}>
			<Alert onClose={HandleClose} severity={severity}sx={{ width: '100%' }}>
				{message}
			</Alert>
		</Snackbar>
	)
} 

export const OpenSnackbar = (severity, message, duration) => {

	publish('onSnackbarOpen', {severity: GetSeverity(severity), message, duration: (duration) ? duration : 2000});
}

function GetSeverity(id){
	switch (id) {
		case 0: return 'success';
		case 1: return 'error';
		case 2: return 'warning';
		case 3: return 'info';
		default: return 'success';
	}
}

function SlideTransition(props) {
	return <Slide {...props} direction="up" />;
  }
  