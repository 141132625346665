import React from 'react';
import { GET, UPDATE } from '../../../utils/AxiosRequest';

import { Box, Button, MenuItem, Select, Stack, Switch, Typography, useMediaQuery, useTheme } from '@mui/material';
import Session from 'react-session-api';
import { OpenSnackbar } from '../../utils/Snackbar';

export const ChangeAccountFilter = (props) => {
	const theme = useTheme();
    const isDesktop = useMediaQuery('(min-width:1065px)');
    const pepoleData = JSON.parse(Session.get('pepoleData'));
    const [dimensions, setDimensions] = React.useState({ width:0, height: 0 });
    const [pepoleFilter, setPepoleFilter] = React.useState(JSON.parse((pepoleData.pepoleFilter === "") ? "[-1]": pepoleData.pepoleFilter));
    const [employeesTasksFilter, setEmployeesTasksFilter] = React.useState(JSON.parse((!pepoleData.employeesTasksFilter || pepoleData.employeesTasksFilter === "") ? "[-1]" : pepoleData.employeesTasksFilter));
    const [pepolePlanningFilter, setPepolePlanningFilter] = React.useState(JSON.parse((pepoleData.pepolePlanningFilter === "") ? "[-1]" : pepoleData.pepolePlanningFilter));
    const [taskFilter, setTaskFilter] = React.useState(JSON.parse((pepoleData.taskFilter === '') ? "[-1]" : pepoleData.taskFilter));
    const [planningFilter, setPlanningFilter] = React.useState(JSON.parse((pepoleData.planningFilter === '') ? '[-1]' : pepoleData.planningFilter));
    const [pepoleList, setPepoleList] = React.useState([]);
    const [taskList, setTaskList] = React.useState([]);
    const [planningList, setPlanningList] = React.useState([]);
    const [simplified, setSimplified] = React.useState((parseInt(pepoleData.simplified) === 1) ? true : false);
    const [masked, setMasked] = React.useState((parseInt(pepoleData.masked) === 1) ? true : false);
    const [subtaskOnly, setSubtaskOnly] = React.useState((parseInt(pepoleData.subtaskOnly) === 1) ? true : false);
    const [transparency, setTransparency] = React.useState((parseInt(pepoleData.transparency) === 1) ? true : false);
    const targetRef = React.useRef(null);
    
    React.useEffect(() => {
        let isMounted = true;

        Promise.all([
            GET("projects/getAllEmployeInfo", {}),
			GET("projects/getAllTasksTemplate", {}),
        ]).then(data => {
            if(isMounted){
                setPepoleList([
                    {id: -1, nom: "", prenom: "Tout le monde"},
                    {id: -2, nom: "", prenom: "Non assigné"},
                    ...data[0]
                ])
                setTaskList([
                    {id: -1, nom: 'Tous les types'},
                    ...data[1]
                ]);
				setPlanningList([
					{id: -1, nom: 'Tous les types'},
					{ id: -2, nom: "Personnalisées"}, 
					{ id: -3, nom: "Congés / Absences"},
					...data[1]
				]);
            }
        })

        return _ => { isMounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    React.useLayoutEffect(() => {
        if (targetRef.current) {
          setDimensions({
            width: targetRef.current.offsetWidth,
            height: targetRef.current.offsetHeight
          });
        }
      }, []);

    function SaveFilters(){
        var data = pepoleData;
        console.log(JSON.stringify(pepoleFilter));
        data.pepoleFilter = JSON.stringify(pepoleFilter);
        data.employeesTasksFilter = JSON.stringify(employeesTasksFilter);
        data.pepolePlanningFilter = JSON.stringify(pepolePlanningFilter);
        data.taskFilter = JSON.stringify(taskFilter);
        data.planningFilter = JSON.stringify(planningFilter);
        data.simplified = (simplified) ? 1 : 0;
        data.masked = (masked) ? 1 : 0;
        data.subtaskOnly = (subtaskOnly) ? 1 : 0;
        data.transparency = (transparency) ? 1 : 0;
        
        const params = {
            pepoleFilter: JSON.stringify(pepoleFilter), 
            employeesTasksFilter: JSON.stringify(employeesTasksFilter), 
            pepolePlanningFilter: JSON.stringify(pepolePlanningFilter), 
            taskFilter: JSON.stringify(taskFilter), 
            simplified: data.simplified, 
            masked: data.masked, 
            subtaskOnly: data.subtaskOnly, 
            transparency: data.transparency, 
            id: pepoleData.id
        }
        
        return UPDATE("login/updateEmployeFilters", params)
        .then( _ => { 
            OpenSnackbar(0, "Changement des filtres effectués", 2000);
            return Session.set('pepoleData', JSON.stringify(data)); 
        })
        
    }

	function ChangeFilter(data, setter){
		var array = [];
		if(data.length === 0 || data.at(-1) === -1){
			array = [-1];
		} else {
			var allIndex = data.findIndex(element => element === -1)
			if(allIndex !== -1){
				data.splice(allIndex, 1);
			}
			array = data;
		}
		setter(array)
	}

	return (
        <Box ref={targetRef} width='100%' >
            <Stack spacing={4} marginX="auto" width="95%" paddingBottom={10} sx={{height: dimensions.height,  overflow: "none", overflowY: (isDesktop) ? "auto" : "scroll"}}>
                <Typography variant='h5' my={2} textAlign='center' color={theme.palette.text.primary}>Filtres</Typography>
                <Stack spacing={2}>
                <Typography variant='h6' textAlign='center' color={theme.palette.text.primary}>Trie par personne dans les projets</Typography>
                    <Select multiple variant='standard' value={pepoleFilter} onChange={(event) => ChangeFilter(event.target.value, setPepoleFilter)} >
                        {pepoleList.map(pepole => (
                            <MenuItem key={pepole.id} value={pepole.id}>{pepole.prenom + " " + pepole.nom}</MenuItem>
                        ))}
                    </Select>
                </Stack>

                <Stack spacing={2}>
                <Typography variant='h6' textAlign='center' color={theme.palette.text.primary}>Trie par personne dans les tâches</Typography>
                    <Select multiple variant='standard' value={employeesTasksFilter} onChange={(event) => ChangeFilter(event.target.value, setEmployeesTasksFilter)} >
                        {pepoleList.map(pepole => (
                            <MenuItem key={pepole.id} value={pepole.id}>{pepole.prenom + " " + pepole.nom}</MenuItem>
                        ))}
                    </Select>
                </Stack>

                <Stack spacing={2}>
                    <Typography variant='h6' textAlign='center' color={theme.palette.text.primary}>Trie par personne dans le planning</Typography>
                    <Select multiple variant='standard' value={pepolePlanningFilter} onChange={(event) => ChangeFilter(event.target.value, setPepolePlanningFilter)} >
                        {pepoleList.map(pepole => (
                            <MenuItem key={pepole.id} value={pepole.id}>{pepole.prenom + " " + pepole.nom}</MenuItem>
                        ))}
                    </Select>
                </Stack>

                <Stack spacing={2}>
                    <Typography variant='h6' textAlign='center' color={theme.palette.text.primary}>Trie par tâche dans les projets</Typography>
                    <Select multiple variant='standard' value={taskFilter} onChange={(event) => ChangeFilter(event.target.value, setTaskFilter)} >
                        {taskList.map(task => (
                            <MenuItem key={task.id} value={task.id}>{task.nom}</MenuItem>
                        ))}
                    </Select>
                </Stack>
                <Stack spacing={2}>
                    <Typography variant='h6' textAlign='center'>Trie par tâche dans le planning</Typography>
                    <Select multiple variant='standard' value={planningFilter} onChange={(event) => ChangeFilter(event.target.value, setPlanningFilter)} >
                        {planningList.map(task => (
                            <MenuItem key={task.id} value={task.id}>{task.nom}</MenuItem>
                        ))}
                    </Select>
                </Stack>
            
                <Stack direction="row" alignItems="center">
                    <Typography variant='body1' color={theme.palette.text.primary} >Version simplifiée</Typography>
                    <Switch checked={simplified} onChange={(event) => setSimplified(event.target.checked)}/>
                </Stack>
                <Stack direction="row" alignItems="center">
                    <Typography variant='body1' color={theme.palette.text.primary} >Masquer en attente</Typography>
                    <Switch checked={masked} onChange={(event) => setMasked(event.target.checked)}/>
                </Stack>
                <Stack direction="row" alignItems="center">
                    <Typography variant='body1' color={theme.palette.text.primary} >Masquer les tâches</Typography>
                    <Switch checked={subtaskOnly} onChange={(event) => setSubtaskOnly(event.target.checked)}/>
                </Stack>
                <Stack direction="row" alignItems="center">
                    <Typography variant='body1' color={theme.palette.text.primary} >Transparence (planning)</Typography>
                    <Switch checked={transparency} onChange={(event) => setTransparency(event.target.checked)}/>
                </Stack>
                <Button variant='contained' onClick={() => SaveFilters()}>Sauvegarder mes filtres</Button>
            </Stack>
        </Box>
	)
}