import React from 'react';

//CSS
import { Stack } from '@mui/material';
import { Navbar } from '../components/Navbar';
import { Accompte as Home } from '../components/Accompte/Accompte';

export const Accompte = () => {
  return (
	<Stack minWidth="100%" direction="row">
		<Navbar/>
		<Home/>
	</Stack>
  );
};


