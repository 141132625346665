import React  from "react";
import { GET } from '../../../utils/AxiosRequest';

import { Button ,Typography } from '@mui/material';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle'
import { useTheme } from "@mui/material";

import { OpenSnackbar } from "../../utils/Snackbar";

export const LoadDevis = (props) => {
	const theme = useTheme();
	
    const [open, setOpen] = React.useState(false);
    const [devisList, setDevisList] = React.useState([]);
    const [clientList, setClientList] = React.useState([]);
    const [selected, setSelected] = React.useState(0);
    const changeSelected = (event) => setSelected(event.target.value);

    function HandleOpen(){
        return Promise.all([
            GET("devis/getAllDevis",{}),
            GET("projects/getClientNom",{})
        ]).then(data => {
            setDevisList(data[0]);
            setClientList(data[1]);
            setOpen(true);
        })
    }

    function HandleClose(apply){
        if(apply){
            props.loadDevis(devisList[selected], props.isDevis);
            if(props.isDevis) OpenSnackbar(1, '/!\\ Ne pas modifier si déjà envoyé au client', 7000)
        }
        setOpen(false);
    }

    return (
        <div>
            <Button fullWidth variant="contained" onClick={() => HandleOpen()}>{(props.isDevis) ? "Charger Devis" : "Charger Template" }</Button>
            <Dialog fullWidth maxWidth="md" open={open} onClose={() => HandleClose(false)}>
				<DialogTitle color={theme.palette.text.primary}>{(props.isDevis) ? "Choisir un Devis à charger" : "Choisir un Template à charger"}</DialogTitle>
				{(props.isDevis) && <DialogContent  style={{paddingBottom:"0px"}}>
                    <Typography sx={{typography: 'subtitle2'}} >{"ATTENTION: Ne pas modifier un devis déjà envoyé à un client"}</Typography>
                    <Typography sx={{typography: 'subtitle2'}} >{"S'il a déjà été envoyé, il est important d'utiliser CHARGER TEMPLATE au lieu de CHARGER DEVIS."}</Typography>
                </DialogContent>}
                <DialogContent>
					<TextField fullWidth select value={selected} onChange={changeSelected}>
                        {devisList.map((devis, i) => {
                            var client = clientList.find((client) => client.id === devis.idClient);
                            return <MenuItem key={i} value={i}>{(client !== undefined) ? devis.nom + " - " + client.nomEntite : devis.nom }</MenuItem>
                        })}
                    </TextField>
				</DialogContent>
				<DialogActions>
                    <Button onClick={() => HandleClose(false)}>Retour</Button>
					<Button onClick={() => HandleClose(true)} color="success">Valider</Button>
				</DialogActions>
			</Dialog>
        </div>
    )
}