import React from 'react';
import {Facture as FactureForm} from '../components/Factures/FactureComponent'
import { Navbar } from '../components/Navbar';
import { Stack } from '@mui/material';

// Page Facture
const Facture = () => {

    return (
        <Stack minWidth="100%" direction="row">
            <Navbar />
            <FactureForm></FactureForm>
        </Stack>
    );
};

export default Facture;