import React from 'react'
import { StyleSheet, View, Text } from '@react-pdf/renderer'

const styles = StyleSheet.create({
    container: {
        width: "100%",
    },
    droit: {
        paddingBottom: '5px',
        borderBottom: '1px solid black',
        marginTop: '20px',
    },
    text: {
        fontSize: '8.5pt',
    },
    reglement: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    modalite: {
        width: '35%',
        paddingTop: '30px',
    },
    label: {
        width: '40%',
        paddingTop: '15px',
        textAlign: 'right',
    },
    prix: {
        width: '20%',
        paddingTop: '15px',
        textAlign: 'right',
    },
    textPrix: {
        marginBottom: '5px',
    },
    inline: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    textDueDateTitle: {
        fontSize: '8.5pt',
        textDecoration:'underline',
        marginBottom: '7px',
    },
    textDueDateTitleSpace: {
        fontSize: '8.5pt',
        marginBottom: '7px',
    },
    textDueDate: {
        fontSize: '7.5pt',
        marginBottom: '5px',
    },
    bonAccord:{
        border: '1px solid black',
        width: '30%',
        marginTop: '20px',
        marginLeft: '68%',
        padding: '1%',
    },
    bonTitre:{
        textAlign: 'center',
        marginBottom: '10px',
    },
    bonText:{
        fontSize: '8.5pt',
        textAlign: 'left',
    },
    bonFoot:{
        fontSize: '7pt',
        fontStyle: 'oblique',
        textAlign: 'center',
        marginTop: '20px',

    },

})

const Total = props => {
    var totalHT = parseFloat(props.total);
    var tva = parseFloat(totalHT * (parseFloat(props.tva) / 100));
    var totalTTC = totalHT + tva;
    var dueDates = [];
    props.dueDatesData.forEach((dueDate, i) => {
        dueDates.push({
            information: dueDate.information,
            percentage: dueDate.percentage,
            term: dueDate.term
        })
    }) 
    
    return (
        <View style={styles.container}>
            <View style={styles.droit}>
                <Text style={styles.text}>Droit du réalisateur et du photographe :</Text>
                <Text style={styles.text}>Le budget de production inclus :</Text>
                <Text style={styles.text}>- La cession des droits de propriété intellectuelle (reproduction/ représentation/ adaptation) sur tout support; pour la durée de la protection de la propriété intellectuelle, pour le Monde entier.</Text>
                <Text style={styles.text}>- La cession de tous les documents supports de la Création (photographie, rushs, maquettes, négatifs, masters...)</Text>
                <Text style={styles.text}>- La cession des droits de propriété intellectuelle (reproduction/ représentation/ adaptation) pour les captures d'écran, pour les utilisations suivantes : PR, POS/Duratrans, Petites éditions, Multimédia* et CRM**, pour le Monde entier.</Text>
            </View>
            <View style={styles.reglement}>
                <View style={styles.modalite}>
                    {(!dueDates || dueDates.length === 0) && <Text style={styles.text}>Modalité de règlement :</Text>}
                    {(!dueDates || dueDates.length === 0) && <Text style={styles.text}>Pour les clients en compte, se référer aux accords négociés</Text>}
                </View>
                <View style={styles.label}>
                    <Text style={styles.textPrix}>Total HT</Text>
                    <Text style={styles.textPrix}>TVA à 20%</Text>
                    <Text style={styles.textPrix}>Total TTC</Text>
                    {(dueDates.length > 0) && <View style={styles.inline}><Text style={styles.textDueDateTitle}>Conditions de règlement</Text><Text style={styles.textDueDateTitleSpace}> :</Text></View>}
                    {dueDates.map((dueDate) => (
                        <Text style={styles.textDueDate}>{dueDate.information} {dueDate.percentage}% - {dueDate.term}</Text>
                    )) }
                    
                </View>
                <View style={styles.prix}>
                    <Text style={styles.textPrix}>{(totalHT > 0) ? parseFloat(totalHT).toFixed(2) : "0.00"}€</Text>
                    <Text style={styles.textPrix}>{(tva > 0) ? parseFloat(tva).toFixed(2) : "0.00"}€</Text>
                    <Text style={styles.textPrix}>{(totalTTC > 0) ? parseFloat(totalTTC).toFixed(2) : "0.00"}€</Text>
                    {(dueDates.length > 0) && <Text style={styles.textDueDateTitleSpace}> </Text>}
                    {dueDates.map((dueDate) => (
                        <Text style={styles.textDueDate}>{parseFloat(totalHT * (dueDate.percentage / 100)).toFixed(2)}€ HT</Text>
                    )) }
                </View>
            </View>
            <View style={styles.bonAccord}>
                    <Text style={styles.bonTitre}>Bon pour accord</Text>
                    <Text style={styles.bonText}>Date :</Text>
                    <Text style={styles.bonText}>Signature</Text>
                    <Text style={styles.bonFoot}>Nom, qualité et signature ou cachet du client</Text>
                </View>
        </View>
    )
}

export {Total}