import React from 'react'
import { StyleSheet, View, Text } from '@react-pdf/renderer'

const styles = StyleSheet.create({
    container: {
        width: "100%",
    },
    droit: {
        paddingBottom: '5px',
        borderBottom: '1px solid black',
        marginTop: '20px',
    },
    bon: {
        paddingBottom: '5px',
        marginTop: '10px',
    },
    text: {
        fontSize: '8.5pt',
    },
    reglement: {
        marginTop: '20px',
        padding: "5px",
        paddingBottom: '100px',
        width: '100%',
        display: 'flex',
        border: '1px solid black',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    modalite: {
        width: '35%',
        paddingTop: '20px',
    },
    label: {
        width: '40%',
        paddingTop: '15px',
        textAlign: 'right',
    },
    prix: {
        width: '20%',
        paddingTop: '15px',
        textAlign: 'right',
    },
    textPrix: {
        marginBottom: '5px',
    },
    bonAccord:{
        width: '60%',
        marginTop: '20px',
    },
    bonTitre:{
        marginTop: '10px',
        fontSize: '11pt',
    },
    bonText:{
        fontSize: '10pt',
        textAlign: 'left',
    },
    bonFoot:{
        fontSize: '7pt',
        fontStyle: 'oblique',
        textAlign: 'center',
        marginTop: '20px',

    },
    textRegle: {
        fontSize: '10pt',
        marginBottom: '5px'
    }

})

const Total = props => {
    return (
        <View style={styles.container}>
            <View style={styles.droit}>
                <Text style={styles.text}>Droit du réalisateur et du photographe :</Text>
                <Text style={styles.text}>Le budget de production inclus :</Text>
                <Text style={styles.text}>- La cession des droits de propriété intellectuelle (reproduction/ représentation/ adaptation) sur tout support; pour la durée de la protection de la propriété intellectuelle, pour le Monde entier.</Text>
                <Text style={styles.text}>- La cession de tous les documents supports de la Création (photographie, rushs, maquettes, négatifs, masters...)</Text>
                <Text style={styles.text}>- La cession des droits de propriété intellectuelle (reproduction/ représentation/ adaptation) pour les captures d'écran, pour les utilisations suivantes : PR, POS/Duratrans, Petites éditions, Multimédia* et CRM**, pour le Monde entier.</Text>
            </View>

            <View style={styles.bon}>
                <Text style={styles.text}>Le client confirme avoir procéder à la vérification de la conformité des prestations commandées.</Text>
                <Text style={styles.text}>Les biens livrés restent la propriété d’Inspiration productions jusqu’au paiement intégral du prix convenu.</Text>
                <Text style={styles.text}>En cas de réserves éventuelles, le Client dispose d’un délai de 5 jours pour remontrer l’information.</Text>
            </View>
            
            <View style={styles.reglement}>
                <Text style={styles.textRegle}>Visa du client </Text>
                <Text style={styles.textRegle}>Reçu le : </Text>
                <Text style={styles.textRegle}>Signature : </Text>
            </View>
            {/* <View style={styles.bonAccord}>
                    <Text style={styles.bonText}>Pas d'escompte en cas de paiement anticipé.</Text>
                    <Text style={styles.bonText}>En cas de retard de paiement, il sera appliqué une pénalité de 1,5 %</Text>
                    <Text style={styles.bonText}>par mois de retard (loi du 31/12/92 et du 29/01/93)</Text>
                    <Text style={styles.bonTitre}>Facture établie selon le devis {props.data.bonData.nom} - Échéance : {props.data.echeance}</Text>
                </View> */}
        </View>
    )
}

export {Total}