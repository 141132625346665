import React from "react";

import { Stack } from "@mui/material";
import { AdministrationButtons } from "./ButtonsSelectors";
import { ClientView } from "./Views/ClientView";
import { TurnoverView } from "./Views/Turnover/TurnoverView";
import { CurrentProjects } from "./Views/CurrentProjectsView";
import { ValidateDevisAdmin } from "./Views/ValidateDevisView";
import { FacturePerProject } from "./Views/FacturePerProject";

import { Scrollbars } from 'react-custom-scrollbars-2';
import { EmployeView } from "./Views/EmployeView";
import { ValidateFactureAdmin } from "./Views/ValidateFacture";

export const Administration = (props) => {
    const [currentView, setCurrentView] = React.useState(1);
    const changeCurrentView = (id) => setCurrentView(id);

    function GetView(){
        switch(currentView){
            case 0: return <ClientView/>
            case 1: return <TurnoverView/>
            case 2: return <CurrentProjects isProjectEnded={true}/>
            case 3: return <CurrentProjects isProjectEnded={false}/>
            case 4: return <ValidateDevisAdmin/>
            case 5: return <FacturePerProject/>
            case 6: return <EmployeView/>
            case 7: return <ValidateFactureAdmin/>
            default : return <ClientView/>
        }
    }

    return (
		<Stack direction='row' width="100%" >
			<AdministrationButtons updateView={changeCurrentView} currentView={currentView}/>
			<Scrollbars>
				{GetView()}
			</Scrollbars>
			
		</Stack>
    )
}