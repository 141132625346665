import React from 'react';
import { Document, StyleSheet, Page } from '@react-pdf/renderer'

import { Header } from './DevisHeader';
import { Body } from './DevisBody';
import { Footer } from './DevisFooter'
import { Total } from './DevisTotal';



const DevisPDF = props => {
    return(
        <Document title='Devis'>
                <Page size='A4' style={styles.page} >
                    <Header data={props.data} fixed/>
                    <Body style={styles.body} data={props.data}/>
                    <Footer style={styles.footer} fixed/>
                </Page>
                <Page size='A4' style={styles.page} >
                    <Header data={props.data} fixed/>
                    <Total total={props.data.header.total} accompte={props.data.header.accompte} dueDatesData={props.data.header.dueDatesData} tva={20}/>
                    <Footer style={styles.footer} fixed/>
                </Page>
            </Document>
        
    )
}

const styles = StyleSheet.create({
    page: {
      paddingTop: 15,
      paddingBottom: "10%",
      paddingHorizontal: 15,
      fontSize: '10pt',
    },
    header: {
    },
    body: {
        paddingVertical: "15px",
        paddingBottom: 50,
        backgroundColor: 'cadetblue',
    },
    footer: {
    }
    
  });

export {DevisPDF}

// style={{display: "flex", flexDirection: "row"}}