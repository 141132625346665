import React from "react";
import { POST, GET } from "../../utils/AxiosRequest";

import Session from "react-session-api";

import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { OpenSnackbar } from "../utils/Snackbar";

const styles = {
  boxBackground: {
    // backgroundImage: `url(${Image})`,
    // backgroundSize: "cover",
    background: "rgb(15,35,85)",
    // eslint-disable-next-line no-dupe-keys
    background:
      "linear-gradient(131deg, rgba(15,35,85,1) 0%, rgba(81,21,23,1) 100%)",
  },
  loginBox: {
    backdropFilter: "blur(5px)",
    background: "rgba(255,255,255,0.1)",
    color: "white",
  },
};

export const LoginComponent = (props) => {
  const [login, setLogin] = React.useState("");
  const [password, setPassword] = React.useState("");

  React.useEffect((_) => {
    document.addEventListener("keypress", HandleKeyPress);

    return (_) => {
      document.removeEventListener("keypress", HandleKeyPress);
    };
  });

  function HandleKeyPress(event) {
    if (event.key === "Enter") {
      console.log(event.key);
      RequestLogin();
    }
  }

  function RequestLogin() {
    POST("login/login", { login, password })
      .then((data) => {
        GET("login/getPepoleData")
          .then((res) => {
            if (res.length === 0) {
              OpenSnackbar(1, "Erreur lors de la récupération des données");
              return;
            }
            console.log(res[0]);
            Session.set("pepoleData", JSON.stringify(res[0]));
            Session.set("authenticated", true);
            window.open(window.location.href, "_self");
          }) 
          .catch((err) => {
            console.log(err);
            OpenSnackbar(1, "Erreur lors de la récupération des données");
          });
        OpenSnackbar(0, "Connexion réussie");
      })
      .catch((err) => {
        console.log(err);
        OpenSnackbar(1, "Identifiant ou mot de passe incorrect", 5000);
      });
  }

  return (
    <Box width="100%" height="100vh" style={styles.boxBackground}>
      <Paper
        sx={{ width: "300px", marginX: "auto", marginTop: 5 }}
        style={styles.loginBox}
      >
        <Stack
          direction="column"
          alignItems="center"
          spacing={5}
          paddingY={10}
          paddingX={5}
          width="100%"
        >
          <Typography
            variant="h5"
            component="div"
            textTransform="uppercase"
            fontWeight="bold"
          >
            Connexion
          </Typography>
          <Stack spacing={2}>
            <TextField
              variant="standard"
              label="Identifiant"
              value={login}
              onChange={(e) => setLogin(e.target.value)}
              sx={{ input: { color: "white" } }}
              InputLabelProps={{ style: { color: "#dadada" } }}
            />
            <TextField
              type="password"
              variant="standard"
              label="Mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ input: { color: "white" } }}
              InputLabelProps={{ style: { color: "#dadada" } }}
            />
          </Stack>
          <Button
            variant="contained"
            sx={{ marginTop: "100px" }}
            onClick={() => RequestLogin()}
          >
            Connexion
          </Button>
        </Stack>
      </Paper>
    </Box>
  );
};

// sx={{backgroundImage: `url("../../public/loginFond.jpg")`}}

// rgb(15, 35, 85)
// rgb(81, 21, 23)
