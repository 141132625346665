import React  from "react";
import { GET } from '../../../utils/AxiosRequest';

import Button from '@mui/material/Button';

import { DataGrid } from '@mui/x-data-grid';

import { Stack, Tooltip, darken, lighten } from "@mui/material";

import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import { createSearchParams, useNavigate } from "react-router-dom";

export const FacturePerProject = (props) => {
	const navigate = useNavigate();
	const [projects, setProjects] = React.useState([]);


	React.useEffect(() => {
		let isMounted = true;

		Promise.all([
			GET("administration/getAllDevisWithProject"),
			GET("administration/getAllFactures"),
		])
		.then(data => {
			if(isMounted){
				var devisList = []
				data[0].forEach(devis => {
					var factures = data[1].find(element => element.devisId === devis.id);
					var indexInDevisList = devisList.findIndex(element => devis.idProjet === element.id);
					if(indexInDevisList !== -1) {
						devisList[indexInDevisList].devis.push(devis.nom);
						if(factures !== undefined){
							devisList[indexInDevisList].factures.push(factures);
							devisList[indexInDevisList].total.push(factures.total + " € HT");
						}
					} else {
						devisList.push({
							id: devis.idProjet,
							name: devis.nomProjet,
							devis: [devis.nom],
							factures: (factures !== undefined) ? [factures] : [],
							total: (factures !== undefined) ? [factures.total + " € HT"] : [""],
						})
					}
				})
				setProjects(devisList)
			}
		})

		return _ =>  {
			isMounted = false;
		}
	}, [])

	const columns = [
		{field: 'status', headerName:'État', width: 100},
		{ field: 'name', headerName: 'Nom Projet', minWidth: 500, flex: 1 },
		{ field: 'devis', headerName: 'Nom Devis', minWidth: 400, flex: 1 },
		{ field: 'factures', headerName: 'Factures', width: 75, renderCell: (params) => (
			<Stack alignItems="center" justifyContent="center" width="100%">
				<Tooltip title={GetFactureTooltip(params)} placement="right">
					{GetFactureIcon(params)}
				</Tooltip>
				
			</Stack>
		)},
		{ field: 'factureNames', headerName: 'Nom Factures', minWidth: 400, flex: 1 },
		{ field: 'factureDates', headerName: 'Échéance Factures', minWidth: 400, flex: 1 },
		{ field: 'factureTotals', headerName: 'Facture Totaux', minWidth: 400, flex: 1 },
		

		{ field: 'aciton', headerName: 'Action', width: 150, renderCell: (params) => {
			return <Button variant="contained" color="info" onClick={() => Navigate(params.row.id)}>Voir le projet</Button>
		}},
	]

	const rows = [];
	projects.forEach(project => {
		var type = GetRowType(project);
		rows.push({
			id: project.id,
			status: GetRowTypeText(type),
			type,
			name: project.name,
			factureNames: GetFactureListNames(project),
			factureDates: GetFactureListDates(project),
			factureTotals: GetFactureTotals(project),
			devis: project.devis.map(devis => ' ' + devis),
		})
	})

	function GetRowType(project){
		if(project.devis.length === 0){
			return 0;
		} else if(project.devis.length > project.factures.length){
			return 1;
		} else if(project.devis.length === project.factures.length){
			return 2;
		} else if(project.devis.length < project.factures.length){
			return 3;
		}
		return -1;
	}

	function GetRowTypeText(type){
		switch (type) {
			case 0: return "Pas Commencé";
			case 1: return "En Cours";
			case 2: return " Fini";
			case 3: return "En Cours";
		
			default: return "Problème";
		}
	}

	function GetFactureIcon(params){
		switch (params.row.type) {
			case 0: return <ErrorTwoToneIcon color="error" />;
			case 1: return <WarningTwoToneIcon color="warning"/>;
			case 2: return <CheckCircleTwoToneIcon color="success"/>;
			case 3: return <WarningTwoToneIcon color="warning"/>;
		
			default: return <ErrorTwoToneIcon color="error"/>;
		}
	}

	function GetFactureTooltip(params){
		switch (params.row.type) {
			case 0: return "Il n'y a de devis pour ce projet";
			case 1: return "Tous les devis n'ont pas été facturés";
			case 2: return "Tous les devis ont été facturés";
			case 3: return "Tous les devis ne sont pas liée au projet";
		
			default: return "Il y a un problème";
		}
	}

	function GetFactureListNames(project){
		if(project.factures.length === 0) return "Pas de facture";
		return project.factures.map(facture => ' ' + facture.nom)
	}

	function GetFactureListDates(project){
		return project.factures.map(facture => ' ' + facture.echeance)
	}
	function GetFactureTotals(project){
		return project.total.map(total => ' ' + total);
	}

	function GetRowClasseName(type){ 
		switch (type) {
			case 0: return classes.noDevis;
			case 1: return classes.notAllFacture;
			case 2: return classes.allFacture;
			case 3: return classes.notAllLink;
		
			default: return classes.noDevis;
		}
	}

	function Navigate(id){
        var params = {id};
		navigate({
			pathname: `/projetInformation/`,
			search: `?${createSearchParams(params)}`
		});
    }

    return (
		<Stack justifyContent="center" height="80%" margin={5} spacing={2} sx={styles}>
			<DataGrid columns={columns} rows={rows} rowsPerPageOptions={[20, 50, 100]} pagination initialState={{pagination: { pageSize: 100, }}} getRowClassName={(params) => GetRowClasseName(params.row.type)} />
		</Stack>
        
					
    )
}

const PREFIX = 'Inspiration';

const classes = {
    noDevis: `${PREFIX}-no-devis`,
    notAllFacture: `${PREFIX}-not-all-facture`,
    notAllLink: `${PREFIX}-not-all-link`,
    allFacture: `${PREFIX}-all-facture`,

};


const getBackgroundColor = (color, mode) => mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) => mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedBackgroundColor = (color, mode) => mode === 'dark' ? darken(color, 0.3) : lighten(color, 0.3);

const styles = {
	'.MuiDataGrid-cell': {
		border: 'none !important',

	},
	[`& .${classes.noDevis}`]: {
		bgcolor : (theme) => getBackgroundColor(theme.palette.error.main, theme.palette.mode),
		'&:hover': {
			bgcolor: (theme) => getHoverBackgroundColor(theme.palette.error.main, theme.palette.mode) + ' !important',
		},
		'&.Mui-selected': {
			bgcolor: (theme) => getSelectedBackgroundColor(theme.palette.error.main, theme.palette.mode) + ' !important',
		},
	}, 
	[`& .${classes.notAllFacture}`]: {
		bgcolor : (theme) => getBackgroundColor(theme.palette.warning.main, theme.palette.mode),
		'&:hover': {
			bgcolor: (theme) => getHoverBackgroundColor(theme.palette.warning.main, theme.palette.mode) + ' !important',
		},
		'&.Mui-selected': {
			bgcolor: (theme) => getSelectedBackgroundColor(theme.palette.warning.main, theme.palette.mode) + ' !important',
		},
	}, 
	[`& .${classes.notAllLink}`]: {
		bgcolor : (theme) => getBackgroundColor(theme.palette.error.main, theme.palette.mode),
		'&:hover': {
			bgcolor: (theme) => getHoverBackgroundColor(theme.palette.error.main, theme.palette.mode) + ' !important',
		},
		'&.Mui-selected': {
			bgcolor: (theme) => getSelectedBackgroundColor(theme.palette.error.main, theme.palette.mode) + ' !important',
		},
	}, 
	[`& .${classes.allFacture}`]: {
		bgcolor : (theme) => getBackgroundColor(theme.palette.success.main, theme.palette.mode),
		'&:hover': {
			bgcolor: (theme) => getHoverBackgroundColor(theme.palette.success.main, theme.palette.mode) + ' !important',
		},
		'&.Mui-selected': {
			bgcolor: (theme) => getSelectedBackgroundColor(theme.palette.success.main, theme.palette.mode) + ' !important',
		},
	}, 
}