import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { OpenSnackbar } from "../../utils/Snackbar";
import { POST, UPDATE } from "../../../utils/AxiosRequest";
import Session from "react-session-api";

const MIN_PWD_LENGHT = 8;

export const ChangePassword = (props) => {
  const theme = useTheme();
  const pepoleData = JSON.parse(Session.get("pepoleData"));
  const [old, setOld] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verif, setVerif] = React.useState("");

  const ChangeOld = (event) => setOld(event.target.value);
  const ChangeNew = (event) => setPassword(event.target.value);
  const ChangeVerif = (event) => setVerif(event.target.value);

  const passwordHasLowercaseLetter = /[a-z]/.test(password);
  const passwordHasUppercaseLetter = /[A-Z]/.test(password);
  const passwordHasSpecialCharacter =
    /[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?]/.test(password);
  const passwordHasNumber = /[0-9]/.test(password);
  const passwordHasValidLength = password.length >= MIN_PWD_LENGHT;

  function ChangePasswordInDb() {
    if (
      password === verif &&
      passwordHasLowercaseLetter &&
      passwordHasNumber &&
      passwordHasSpecialCharacter &&
      passwordHasUppercaseLetter &&
      passwordHasValidLength
    ) {
      POST("login/login", { login: pepoleData.prenom, password: old })
        .then((data) => {
          UPDATE("login/updatePassword", {
            password,
          }).then((_) => {
            OpenSnackbar(0, "Le mot de pass à bien été changé");
          });
        })
        .catch((err) => {
          OpenSnackbar(1, "L'ancien mot de passe n'est pas valide");
        });
    } else {
      OpenSnackbar(1, "Les mots de passes rentrés ne sont pas valides");
    }
  }

  return (
    <Box width="100%">
      <Stack
        direction="column"
        spacing={4}
        width="100%"
        alignItems="center"
        marginTop={10}
      >
        <Typography variant="h6" color={theme.palette.text.primary}>
          Changer de mot de passe
        </Typography>
        <Stack width="50%" spacing={2}>
          <TextField
            fullWidth
            variant="standard"
            label="Ancien mot de passe"
            value={old}
            onChange={ChangeOld}
          ></TextField>
          <TextField
            fullWidth
            variant="standard"
            label="Nouveau mot de passe"
            value={password}
            onChange={ChangeNew}
          ></TextField>
          <TextField
            fullWidth
            variant="standard"
            label="Vérification nouveau Mot de passe"
            value={verif}
            onChange={ChangeVerif}
          ></TextField>
        </Stack>
        <Button variant="contained" onClick={() => ChangePasswordInDb()}>
          Changer le mot de passe
        </Button>
        <Stack width="50%" spacing={2} alignItems="center">
          <Typography variant="h6" color={theme.palette.text.primary}>
            Indication
          </Typography>
          <Typography color={passwordHasLowercaseLetter ? "green" : "error"}>
            Le mot de passe doit avoir une lettre minuscule
          </Typography>
          <Typography color={passwordHasUppercaseLetter ? "green" : "error"}>
            Le mot de passe doit avoir une lettre majuscule
          </Typography>
          <Typography color={passwordHasNumber ? "green" : "error"}>
            Le mot de passe doit avoir un chiffre
          </Typography>
          <Typography color={passwordHasSpecialCharacter ? "green" : "error"}>
            Le mot de passe doit avoir un caratère spécial
          </Typography>
          <Typography color={passwordHasValidLength ? "green" : "error"}>
            Le mot de passe doit avoir au moins {MIN_PWD_LENGHT} caractères
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
