import React  from "react";

import { GET } from '../../../utils/AxiosRequest';

import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone';

import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography, IconButton, Autocomplete, useTheme } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

export const Accompte = (props) => {
	const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [dueDates, setDueDates] = React.useState([]);

    const [existingInformations, setExistingInformations] = React.useState([]);
    const [existingTerms, setExistingTerms] = React.useState([]);

    function HandleOpen(){
        let initialDueDates = props.getDataForPDF().header.dueDatesData;
        setDueDates(initialDueDates);

        Promise.all([
            GET("devis/getExistingDueDatesData",{})
        ]).then(data => {
            let existInfos = [];
            let existTerms = [];
            data[0].forEach(dueDateDataInformation => {
                JSON.parse(dueDateDataInformation.dueDatesData).forEach(dueDateData=> {
                    let info = dueDateData.information
                    let term = dueDateData.term
                    if(!existInfos.includes(info)) existInfos.push(info);
                    if(!existTerms.includes(term)) existTerms.push(term);
                });
            });
            setExistingInformations(existInfos);
            setExistingTerms(existTerms);

        })

        setOpen(true);
    }

    React.useEffect(() => {
        // eslint-disable-next-line
    }, [dueDates]); 

    // Add this function to update dueDates in Accompte component
    function updateDueDate(index, updatedDueDate) {
        setDueDates(prevDueDates => {
            const newDueDates = [...prevDueDates];
            newDueDates[index] = updatedDueDate;
            return newDueDates;
        });
    }
    
    function HandleClose(apply){
        if(apply){
            props.updateAccompte(dueDates);
        }
        setDueDates([]);
        setOpen(false);
    }

    function addDueDate(){
        let newDueDate = {
            information: "",
            percentage: 0,
            term: "",
            id : uuidv4()
        }
        setDueDates(dueDates => [...dueDates, newDueDate]);
    }

    const deleteDueDate = (index) => {
        const updatedDueDates = [...dueDates.slice(0, index), ...dueDates.slice(index + 1)];
        setDueDates(updatedDueDates);
    };

    return (
        <div>
            <Button fullWidth variant="contained" onClick={() => HandleOpen()}>Acomptes</Button>
            <Dialog maxWidth="sm" open={open} onClose={() => HandleClose(false)}>
				<DialogTitle color={theme.palette.text.primary}>Définition de l'échancier</DialogTitle>
                <Stack direction="column" spacing={2}>
                    {dueDates.map((dueDate, i) => (
                        <DueDate key={dueDate.id} index={i} data={dueDate} onUpdate={(updatedDueDate) => updateDueDate(i, updatedDueDate)} deleteDueDate={deleteDueDate} existingInformations={existingInformations} existingTerms={existingTerms}/>
                    ))}
                    <Button color='success' onClick={() => addDueDate()}>Ajouter une échéance</Button>
                </Stack>
				<DialogActions>
					<Button onClick={() => HandleClose(false)}>Retour</Button>
                    <Button onClick={() => HandleClose(true)} color="success">Valider</Button>
				</DialogActions>
			</Dialog>
        </div>
    )
}

const DueDate = (props) => {
    const [information, setInformation] = React.useState(props.data.information);
    const [percentage, setPercentage] = React.useState(props.data.percentage);
    const [term, setTerm] = React.useState(props.data.term);
    
    const handleInformationChange = (event, newValue) => {
        setInformation(newValue);
        props.onUpdate({ ...props.data, information: newValue });
    };

    const handlePercentageChange = (event) => {
        setPercentage(event.target.value);
        props.onUpdate({ ...props.data, percentage: event.target.value });
    };

    const handleTermChange = (event, newValue) => {
        setTerm(newValue);
        props.onUpdate({ ...props.data, term: newValue });
    };

    return (<DialogContent sx={{ width: '1200px', maxWidth: '100%', padding:0.3}} >
                <Stack direction="column" spacing={1} style={{ textAlign: "center" }}>
                    <Stack direction="row" spacing={1}>                     
                        <Autocomplete
                            fullWidth
                            freeSolo
                            id="free-solo-2-demo"
                            disableClearable
                            options={props.existingInformations}
                            style={{ fontSize: '10px' }}
                            inputValue={information}
                            onInputChange={handleInformationChange}
                            renderInput={(params) => (
                                <TextField {...params} variant="standard" inputProps={{...params.inputProps, style: {fontSize: 14}}} label="Information" fullWidth />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} style={{ fontSize: '12px' }}>
                                {option}
                                </li>
                            )}
                        />
                        <TextField variant="standard" inputProps={{min: 0, style: { textAlign: 'right', fontSize: 14 }}} label="Montant %" value={percentage} onChange={handlePercentageChange} />
                        <Autocomplete
                            fullWidth
                            freeSolo
                            id="free-solo-2-demo"
                            disableClearable
                            options={props.existingTerms}
                            style={{ fontSize: '10px' }}
                            inputValue={term}
                            onInputChange={handleTermChange}
                            renderInput={(params) => (
                                <TextField {...params} variant="standard" inputProps={{...params.inputProps, style: {fontSize: 14}}} label="Terme" fullWidth />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} style={{ fontSize: '12px' }}>
                                {option}
                                </li>
                            )}
                        />
                        <IconButton sx={{padding:0}} color='error' onClick={() => props.deleteDueDate(props.index)} >
                            <ClearTwoToneIcon/>
                        </IconButton>
                    </Stack>
                    <Typography style={{fontWeight: 'bold', fontStyle: 'italic'}} variant="body2">{information} {percentage}% - {term}</Typography>
                </Stack>
            </DialogContent>
    )
}