import React from 'react';
import { Navbar } from '../components/Navbar';
import { Stack } from '@mui/material';
import { TaskList } from '../components/Planning/Todo/TaskList';

// Page Devis
export const Todo = (props) => {
    return (
		<Stack minWidth="100%" direction="row">
			<Navbar />
      		<TaskList common={false}/>
		</Stack>
    );
};
