import React from 'react';
import { Document, StyleSheet, Page } from '@react-pdf/renderer'

import { Header } from './AvoirHeader';
import { Body } from './AvoirBody';
import { Footer } from './AvoirFooter'
import { Total } from './AvoirTotal';



export const AvoirPDF = props => {
    return(
        <Document title='test'>
			<Page size='A4' style={styles.page} >
				<Header data={props.data} fixed/>
				<Body style={styles.body} data={props.data}/>
				<Total data={props.data} accompte={props.data.accompte} tva={20}/>
				<Footer style={styles.footer} fixed/>
			</Page>
		</Document>
    )
}

const styles = StyleSheet.create({
    page: {
      paddingTop: 15,
      paddingBottom: "10%",
      paddingHorizontal: 15,
      fontSize: '10pt',
    },
    header: {
    },
    body: {
        paddingVertical: "15px",
        paddingBottom: 50,
        backgroundColor: 'cadetblue',
    },
    footer: {
    }
    
});