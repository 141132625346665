
import React from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Stack, TextField } from "@mui/material";

import { Chart, PieSeries, Title, Legend, Tooltip, } from '@devexpress/dx-react-chart-material-ui';
import dayjs from "dayjs";
import { EventTracker, HoverState } from "@devexpress/dx-react-chart";

import { format } from 'd3-format';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@emotion/react";

require('dayjs/locale/fr');
dayjs.locale('fr');

export const TurnoverByClientGroups = (props) => {
	const { data, clients } = props;
	const [sortedData, setSortedData] = React.useState([]);
	const [targetItem, setTargetItem] = React.useState(undefined);
	const [hover, setHover] = React.useState(undefined);
	const [date, setDate] = React.useState(dayjs());
	const pieDetailsRef = React.useRef(null);

	React.useEffect(() => {
		SortData(data);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props, date])

	function SortData(){		
        var lastYear = [];
        for(var i = 0; i < 12; i++){
            lastYear.push(GetDateFromDayJs(date.subtract(i, 'month')));
        }

		var tmp = [];
		var total = 0.0;
        data.forEach(facture => {
            if(lastYear.includes(GetDateFromDayJs(dayjs(GetCorrectDate(facture.date))))){
                var index = tmp.findIndex(element => element.argument === facture.groupe);
                if(index !== -1){
                    tmp[index].value += facture.total;
					tmp[index].factures.push(facture)
                } else {
                    tmp.push({
                        argument: facture.groupe,
                        value: facture.total,
						name: facture.groupe,
						factures: [facture],
                    })
                }
				total += facture.total;
            }
        })
		tmp = tmp.sort((a, b) => a.name > b.name);
		tmp.forEach(element => {
			element.percent = parseFloat((element.value / total) * 100).toFixed(2);
		})
		
		setSortedData([...tmp]);
	}

	function OpenDialog(event){
		pieDetailsRef.current.handleOpen(sortedData[event.targets[0].point])
	}

	return (
		<Paper sx={{width: '100%'}}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DatePicker label="Mois pour le graphique" value={date} onChange={setDate} inputFormat="MM-YYYY"
					renderInput={(params) => <TextField variant="standard" {...params} sx={{margin: 2}}/>}
				/>
			</LocalizationProvider>
			<Chart data={sortedData}>
				<Title text="Chiffre d'affaire sur les 12 mois par groupes de clients" />
				<PieSeries valueField="value" argumentField="argument" name={sortedData}/>
				<EventTracker onClick={OpenDialog}/>
				<Tooltip targetItem={targetItem} onTargetItemChange={setTargetItem} contentComponent={TooltipContent}/>
				<HoverState hover={hover} onHoverChange={setHover} />
                <Legend/>
			</Chart>
			<PieDetails ref={pieDetailsRef} clients={clients}/>
		</Paper>
	)
}

function GetDateFromDayJs(date = dayjs()){
	return date.format("MMMM YYYY")
}

function GetCorrectDate(date){
	var str = date.split('/')
	return str[2] + '-' + str[1] + '-' + str[0];
}


const formatTooltip = format('.2f');

const tooltipContentTitleStyle = {
	fontWeight: 'bold',
	paddingBottom: 0,
  };
  const tooltipContentBodyStyle = {
	paddingTop: 0,
  };

const TooltipContent = (props) => {
	const { targetItem, text, ...restProps } = props;
	return (
		<div>
			<div>
				<Tooltip.Content
				{...restProps}
				style={tooltipContentTitleStyle}
				text={targetItem.series[targetItem.point].argument + " : " + targetItem.series[targetItem.point].percent + "%"}
				/>
			</div>
			<div>
				<Tooltip.Content
				{...restProps}
				style={tooltipContentBodyStyle}
				text={formatTooltip(parseFloat(text)) + " € HT"}
				/>
			</div>
		</div>
	);
  };

  const PieDetails = React.forwardRef((props, ref) => {
	const { clients } = props;
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const [data, setData] = React.useState([]);
	const [groupe, setGroupe] = React.useState("");


	React.useImperativeHandle(ref, _ => ({
		handleOpen: (data) => {
			setData(data.factures);
			setGroupe(data.argument);
			setOpen(true);
		}
	}))

	function HandleClose(){
		setData([]);
		setOpen(false);
	}

	const columns = [
		{ field: 'name', headerName: 'Nom', width: 100},
		{ field: 'total', headerName: 'Montant', maxWidth: 100, flex: 1},
		{ field: 'groupe', headerName: 'Groupe', minWidth: 200, flex: 1},
		{ field: 'reference', headerName: 'Référence', minWidth: 200, flex: 1},
		{ field: 'client', headerName: 'Client', minWidth: 400, flex: 1},
		{ field: 'date', headerName: 'Date', maxWidth: 150, flex: 1},
		{ field: 'dateEcheance', headerName: 'Échéance', maxWidth: 150, flex: 1},
	]

	const rows = [];
	data.forEach(element => {
		var clientIndex = element.clientId;
		var client = clients.find(item => item.id === clientIndex)
		rows.push({
			id: element.id,
			name: element.nom,
			groupe: element.groupe,
			reference: element.reference,
			client: (client) ? client.nomEntite : "Pas de client",
			total: parseFloat(element.total).toFixed(2) + " € HT",
			date: element.date,
			dateEcheance: element.echeance,
		})
	})

	return (
		<Dialog fullWidth maxWidth="xl" open={open} onClose={() => HandleClose()}>
			<DialogTitle color={theme.palette.text.primary}>{"Voir les factures du mois " + groupe }</DialogTitle>
			<DialogContent>
				<Stack direction="column" spacing={2} height="700px">
					<DataGrid columns={columns} rows={rows} />
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => HandleClose(false)}>Retour</Button>
			</DialogActions>
		</Dialog>
	)
})