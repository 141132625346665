import React from "react";

import { Button, Paper, Stack } from "@mui/material";
// import { SEND_NOTIFICATION_TO_USER } from "../../utils/SlackNotification";

export const AccompteButtons = (props) => {

	
    return (
        <Paper elevation={4} sx={{width: '20%'}}>
            <Stack direction='column' spacing={2} marginX={5} marginY={5}>
                <Button fullWidth variant="contained" onClick={() => props.updateView(0)}>Changer mes filtres</Button>
                <Button fullWidth variant="contained" onClick={() => props.updateView(1)}>Changer mon mot de passe</Button>
            </Stack>
        </Paper>
        
    )
}