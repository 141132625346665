/* eslint-disable array-callback-return */
import React from 'react'
import { StyleSheet, View, Text } from '@react-pdf/renderer'

const styles = StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: 'column',
        
    },
    tableHeader: {
        width: "100%",
        display: "flex",
        flexDirection: 'row',
        justifyContent: "space-between",
        borderBottom: "1px black solid",
        paddingBottom: "10px"

    },
    tableEnd: {
        width: "100%",
        position: 'absolute',
        top: '440',
        display: "flex",
        flexDirection: 'row',
        justifyContent: "space-between",
        borderTop: "1px dashed black",
        paddingTop: "10px",
        marginTop: "10px"

    },
    tableBody: {
        width: "100%",
        display: "flex",
        flexDirection: 'column',
        justifyContent: "space-between",
         
    },
    designation: {
        width: '40%',
    },
    quantite: {
        width: '15%',
        textAlign: "right",
    },
    prix: {
        width: '20%',
        textAlign: "right",
    },
    total: {
        width: '20%',
        textAlign: "right",
    },
    
    line: {
        width: "100%",
        display: "flex",
        flexDirection: 'row',
        justifyContent: "space-between",
        paddingVertical: "3px"

    },
    lineST: {
        width: "100%",
        display: "flex",
        flexDirection: 'row',
        justifyContent: "space-between",
        borderTop: "1px dashed black",
        paddingVertical: '5px',
    },
    space: {
        padding: "10px",

    }
  });

const Body = props => {
    var designation = 'Avoir sur la facture ' +  props.data.facture.nom + ' du ' + props.data.facture.date + '\n' + props.data.designation;
   

    return (
        <View >
            <View fixed style={styles.tableHeader}>
                <Text style={styles.designation}>Désignation</Text>
                <Text style={styles.quantite}>Quantité</Text>
                <Text style={styles.prix}>Prix Unitaire</Text>
                <Text style={styles.total}>Total</Text>
            </View>  
            <View style={styles.tableBody}>
                <View style={styles.line}>
                    <Text style={styles.designation}>{designation}</Text>
                    <Text style={styles.quantite}>1.0</Text>
                    <Text style={styles.prix}>{parseFloat(props.data.montant).toFixed(2)}</Text>
                    <Text style={styles.total}>{parseFloat(props.data.montant).toFixed(2)}</Text>
                </View>
            </View>
        </View>
    );
}

export {Body}