import React from 'react';
import { GET, POST, DELETE, UPDATE } from '../../../../utils/AxiosRequest';

import { styled } from '@mui/material/styles';

import { DataGrid, useGridApiContext } from '@mui/x-data-grid';


import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { Badge, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, TextField, Typography, useTheme } from '@mui/material';
import { Accordion, AccordionDetails, AccordionSummary } from '../../../utils/Accordion';

require('dayjs/locale/fr');
dayjs.locale('fr');

export const DatesManager = React.forwardRef((props, ref) => {
	const { projectId } = props;
	const theme = useTheme();
	const [tasks, setTasks] = React.useState([]);
	const [dates, setDates] = React.useState([]);

	const [expanded, setExpanded] = React.useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
	  setExpanded(isExpanded ? panel : false);
	};


	// eslint-disable-next-line react-hooks/exhaustive-deps
	React.useEffect(() => {
		let isMounted = true;
		var obj = {};
		GetAllTaskAndSubTaskFromBd()
		.then(data => {
			obj.tasks = data;
			return GetDatesFromDb(data)
		}).then(data => {
			if(isMounted){
				setTasks(obj.tasks);
				setDates(data);
			}
		})


		return _ => { isMounted = false; };
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useImperativeHandle(ref, () => ({
		ReloadTasks : () => {
			ReloadDates();
		}
	}))

	function ReloadDates(){
		var obj = {};
		return GetAllTaskAndSubTaskFromBd()
		.then(data => {
			obj.tasks = data;
			return GetDatesFromDb(data)
		}).then(data => {
			setTasks(obj.tasks);
			setDates(data);
		})
	}

	//#region AXIOS REQUEST
	function GetAllTaskAndSubTaskFromBd(){
		var tmp = [];
		return GET("projects/getAllTasks", {projectID: projectId})
		.then((data) => {
			var promises = []
			data.forEach((task) => {
				promises.push(
					GET("projects/getAllSubTasksFromSpecificTask", {taskID: task.id})
					.then((data) => {
						var obj = {
							id: task.id,
							taskId: task.idTache,
							nom: task.nom,
							subtask: data
						}
						tmp.push(obj);
					})
				)
			});
			return Promise.all(promises)
			.then(() => {
				return tmp.sort((a, b) => a.taskId - b.taskId);
			})
		})
	}

	function GetDatesFromDb(array) {
		return GET("dates/getAllPlanning", {idProjet: projectId})
		.then((data) => {
			var tmp = [];
			if(array !== undefined) {
				array.forEach((task) => {
					tmp.push(data.filter(element => element.idTache === task.id));
				})
				return tmp;
			}
		})
	}

	function AddDateInDB(task, subtask, start, end) {
		var params = {
			idProjet: projectId,
			idTache: task,
			idSousTache: subtask,
			dateDebut: start,
			dateFin: end
		}
		return POST("dates/insertNewPlanning", params)
		.then( _ => { return ReloadDates(); });
	}

	function DeleteDates(id) {
		return DELETE("dates/deletePlanningData", {planningId: id})
		.then( _ => { return ReloadDates(); });
	}
	//#endregion

	function GetHidden(panel){
		return expanded !== panel;
	}

	return (
		<Stack direction='column' margin="2em auto 2em 2.5%" width="95%" spacing={3} hidden={props.hidden(2)}>
			{tasks.map((task, i) => (
				<div key={i}>
					<Accordion expanded={expanded === task.nom} onChange={handleChange(task.nom)}>
						<AccordionSummary sx={StyleAccordion}>
							<Stack direction='row' spacing='2'>
								<Typography variant='body1' color={theme.palette.text.primary}>{task.nom}</Typography>
								{dates.length > 0 && i < dates.length && <StyledBadge badgeContent={dates[i].length} color="primary" />}
							</Stack>
						</AccordionSummary>
						<AccordionDetails>
							<DatesInformation add={AddDateInDB} data={task} dates={(dates[i] !== undefined) ? dates[i] : []} delete={DeleteDates} hidden={GetHidden}/>
						</AccordionDetails>
					</Accordion>
				</div>
			))}
		</Stack>
	)
});

const StyleAccordion = {
	"& .MuiAccordionSummary-content p": {
		fontSize: "1.1rem",
		padding: 0,
		margin: 0
	},
}

const StyledBadge = styled(Badge)(({ theme }) => ({
	'& .MuiBadge-badge': {
	  right: -25,
	  top: 13,
	  border: `2px solid ${theme.palette.background.paper}`,
	  padding: '0 10px',
	  alignSelf: 'center'
	},
}));

//#region DATES INFO
const DatesInformation = (props) => {
	const [openAdd, setOpenAdd] = React.useState(false);
	const [rows, setRows] = React.useState([]);

	const handleClickOpen = (open) => {
		setOpenAdd(open);
	}

	const columns = [
		{ field: 'id', headerName: 'ID', width: 70 },
		// { field: 'task', headerName: 'Tâche', minWidth: 200, flex: 1 },
		{ field: 'subtask', headerName: 'Sous-Tâche', minWidth: 200, flex: 1, editable: true },
		{ field: 'start', headerName: 'Début', minWidth: 200, flex: 1, editable: true, renderEditCell: RenderEditDate },
		{ field: 'end', headerName: 'Fin', minWidth: 200, flex: 1, editable: true, renderEditCell: RenderEditDate},
		{ field: 'action', headerName: 'Action', width: 150, renderCell: (params) => (<Button variant="outlined" color="error" size="small" onClick={() => props.delete(params.row.idDb)}>Supprimer</Button>) },
	];

	React.useEffect( _ => {
		setRows( _ => {
			return props.dates.map((date, i) => {
				var sub = props.data.subtask.find(element => element.id === date.idSousTache);
				var name = (sub !== undefined) ? sub.nom : "";
				return {
					id: i + 1, 
					subtask: (name === "Autre") ? sub.texte : name ,
					start: GetStringFromDate(date.dateDebut), 
					end: GetStringFromDate(date.dateFin),  
					idDb: date.id,
				}
			})
		})
	}, [props])

	if(props.hidden(props.data.nom)){
		return <div></div>
	}


	
	function ProcessRowUpdate(newRow, oldRow){
		const updatedRow = { ...newRow, isNew: false };
		if(newRow === oldRow) return updatedRow;

		return Promise.all([
			UPDATE("scheduler/updatePlanning", {id: updatedRow.idDb, dateDebut: GetStringForDB(updatedRow.start), dateFin: GetStringForDB(updatedRow.end)}),
			UPDATE("projects/updateSubtaskName", {id: updatedRow.idDb, texte: updatedRow.subtask})
		]).then( _ => {
			return updatedRow;
		})
	};

	return (
		<div>
			<div style={{height: "500px"}}> 
				<DataGrid columns={columns} rows={rows} pageSize={5} rowsPerPageOptions={[5]} 
					experimentalFeatures={{ newEditingApi: true }}
					editMode='row'
					processRowUpdate={ProcessRowUpdate}
				/>
			</div>
			<Stack marginTop={2} direction="row" spacing={2}>
				<Button variant="contained"  color="primary" onClick={() => handleClickOpen(true)}>Ajouter une date</Button>
			</Stack>
			<AddDatesInfo open={openAdd} onClose={handleClickOpen} add={props.add} task={props.data}/>
		</div>
	)
}

const RenderEditDate = (params) => {
	return <EditDate {...params}/>
}

function EditDate(props){
	const {id, value, field} = props;
	const apiRef = useGridApiContext();
	const [date, setDate] = React.useState(dayjs(GetStringForDB(value)));

	function HandleChange(event){
		setDate(event)
		apiRef.current.setEditCellValue({ id, field, value: GetStringFromDate(GetStrFromDaysJS(event)) });
	}

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DateTimePicker label="Date & Heure" value={date} onChange={(event) => HandleChange(event)} ampm={false} inputFormat="DD-MM-YYYY  HH:mm" 
				shouldDisableTime={(timeValue, clockType) => {
					if (clockType === 'minutes' && timeValue % 5) {
						return true;
					}
					return false;
					}}
				renderInput={(params) => <TextField fullWidth variant="standard" {...params} sx={{margin: 2}}/>} 
			/>
		</LocalizationProvider>
	)
}

const AddDatesInfo = (props) => {
	const { open, onClose } = props;
	const theme = useTheme();
	const [subtask, setSubtask] = React.useState(0);
	const [start, setStart] = React.useState(dayjs());
	const [end, setEnd] = React.useState(dayjs(start.hour(start.hour() +1)));
	const changeSubtask = (event) => setSubtask(event.target.value);
	const changeStart = (event) => {
			setEnd(event.add('1', 'hour'));
			setStart(event);
	}
	const changeEnd = (event) => setEnd(event);

	function HandleClose(save){
		if(save && props.task.subtask.length > 0)
			props.add(props.task.id, props.task.subtask[subtask].id, GetStrFromDaysJS(start), GetStrFromDaysJS(end));
		setSubtask(0);
		onClose(false);
	}

	return (
		<Dialog maxWidth="md" open={open} onClose={() => HandleClose(false)}>
				<DialogTitle color={theme.palette.text.primary}>Ajouter une date</DialogTitle>
				<DialogContent>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<Stack marginTop={2} spacing={2} direction="column">
							<TextField select label="Select" variant="standard" value={subtask} onChange={changeSubtask}>
								{props.task.subtask.map((sub, i) => (
									<MenuItem value={i} key={i}>{(sub.nom === "Autre") ? sub.texte : sub.nom}</MenuItem>
								))}
							</TextField>
							<DateTimePicker label="Date & Heure de début" value={start} onChange={changeStart} ampm={false} inputFormat="DD-MM-YYYY  HH:mm" 
								shouldDisableTime={(timeValue, clockType) => {
									if (clockType === 'minutes' && timeValue % 5) {
									  return true;
									}
									return false;
								  }}
								renderInput={(params) => <TextField variant="standard" {...params} />} 
							/>
							<DateTimePicker label="Date & Heure de fin" minDateTime={start} value={end} onChange={changeEnd} ampm={false} inputFormat="DD-MM-YYYY  HH:mm"
								shouldDisableTime={(timeValue, clockType) => {
									if (clockType === 'minutes' && timeValue % 5) {
									  return true;
									}
									return false;
								  }}
								renderInput={(params) => <TextField variant="standard" {...params} />} 
							/>
						</Stack>
					</LocalizationProvider>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => HandleClose(true)} color="success">Ajouter</Button>
					<Button onClick={() => HandleClose(false)}>Retour</Button>
				</DialogActions>
			</Dialog>
	)
}

function GetStringForDB(date){
	var tmp = date.split(" / ");
	var day = tmp[0].split("-");
	var hours = tmp[1].split(":");
	return day[2] + '-' + day[1] + '-' + day[0] + "T" + hours[0] + ":" + hours[1] + ':00';
}

function GetStrFromDaysJS(date){
	return date.$y + "-" + GetDigits(date.$M + 1) + "-" + GetDigits(date.$D) + "T" + GetDigits(date.$H) + ':' + GetDigits(date.$m) + ":" + GetDigits(date.$s);
}

function GetDigits(value){
	var str ='' + value;
	return str.padStart(2, '0');
}

function GetStringFromDate(date){
	var array = date.split("T");
	var time = array[1].split(':');
	var str = array[0].split("-")[2] + "-" + GetDigits(parseInt(array[0].split("-")[1])) + "-" + array[0].split("-")[0] + " / " + time[0] + ":" + time[1];
	return str;
}
//#endregion