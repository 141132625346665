import React from 'react';
import { GET, POST, DELETE, UPDATE } from '../../../../utils/AxiosRequest';

import { styled } from '@mui/material/styles';

import { DataGrid, useGridApiContext } from '@mui/x-data-grid';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Badge, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import { Accordion, AccordionDetails, AccordionSummary } from '../../../utils/Accordion';

import Session from 'react-session-api';

require('dayjs/locale/fr');
dayjs.locale('fr');

export const BriefManager = React.forwardRef((props, ref) => {
	const { projectId, pepoleList } = props;
	const pepoleData = JSON.parse(Session.get('pepoleData'));
	const [briefTemplate, setBriefTemplate] = React.useState([]);
	// eslint-disable-next-line no-unused-vars
	const [briefData, setBriefData] = React.useState([]);
	const [expanded, setExpanded] = React.useState(false);

	const handleExpanded = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	React.useEffect(() => {
		let isMounted = true;

		var promises = [
			GET("briefs/getAllBriefTemplate", {}),
			GET("briefs/getBriefByProjectID", {projectID: projectId}),
		];
		Promise.all(promises)
		.then(data => {
			var template = data[0];
			var brief = data[1];
			template.forEach(element => {
				var data = [];
				brief.forEach((e, i) => {
					if(element.id === e.idType)
						data.push(e);
				});
				element.data = data;
			})
			if(isMounted){
				setBriefTemplate(template);
				setBriefData(brief);
			}	
		})

		return _ => { isMounted = false; };
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function ReloadBrief(){
		return GET("briefs/getBriefByProjectID", {projectID: projectId})
		.then(data => {
			var brief = data;
			briefTemplate.forEach(element => {
				var data = [];
				brief.forEach((e, i) => {
					if(element.id === e.idType)
						data.push(e);
				});
				element.data = data;
			})
			setBriefData(brief);
		})
	}
	//#region AXIOS REQUEST 
	function AddBriefInDb(idType, text, mail, telephone, adresse, dateTournage) {
		var params = { idType, text, mail, telephone, adresse, date: GetTime(), dateTournage, idProjet: projectId, pepoleId: (pepoleData.id) ? pepoleData.id : -1}
		return POST("briefs/insertNewBriefInfo", params)
		.then( _ => { return ReloadBrief(); });
	}

	function UpdateBriefInDb(idType, text, mail, telephone, adresse, dateTournage, id) {
		var params = { idType, text, mail, telephone, adresse, date: GetTime(), dateTournage, id}
		return UPDATE("briefs/updateBriefInfo", params)
		.then( _ => { return ReloadBrief(); });
	}


	function DeleteBrief(id) {
		return DELETE("briefs/deleteBriefInfo", {briefId: id})
		.then( _ => { return ReloadBrief(); });
	}
	//#endregion 

	//#region UTILS


	function GetBriefByType(type, data, nom){
		switch(type){
			case 0 : return <ClientInformation data={data} nom={nom} add={AddBriefInDb} update={UpdateBriefInDb} delete={DeleteBrief} hidden={GetHidden} findPepole={GetPepoleName}/>
			case 1 : return <BriefInformation data={data} nom={nom} add={AddBriefInDb} update={UpdateBriefInDb} delete={DeleteBrief} hidden={GetHidden} findPepole={GetPepoleName}/>
			case 2 : return <TournageInformation data={data} nom={nom} add={AddBriefInDb} update={UpdateBriefInDb} delete={DeleteBrief} hidden={GetHidden} findPepole={GetPepoleName}/>
			case 3 : return <TroubleInformation data={data} nom={nom} add={AddBriefInDb} update={UpdateBriefInDb} delete={DeleteBrief} hidden={GetHidden} findPepole={GetPepoleName}/>
			case 4 : return <RetourInformation data={data} nom={nom} add={AddBriefInDb} update={UpdateBriefInDb} delete={DeleteBrief} hidden={GetHidden} findPepole={GetPepoleName}/>
			case 5 : return <LinkInformation data={data} nom={nom} add={AddBriefInDb} update={UpdateBriefInDb} delete={DeleteBrief} hidden={GetHidden} findPepole={GetPepoleName}/>
			case 6 : return <SyntheseInformation data={data} nom={nom} add={AddBriefInDb} update={UpdateBriefInDb} delete={DeleteBrief} hidden={GetHidden} findPepole={GetPepoleName}/>
			case 7 : return <CheckInformation data={data} nom={nom} add={AddBriefInDb} update={UpdateBriefInDb} delete={DeleteBrief} hidden={GetHidden} findPepole={GetPepoleName}/>
			default : return <ClientInformation data={data} nom={nom} add={AddBriefInDb} update={UpdateBriefInDb} delete={DeleteBrief} hidden={GetHidden} findPepole={GetPepoleName}/>
		}
	}

	function GetHidden(panel){
		return expanded !== panel
	}
	
	function GetTime(){
		var date = new Date();
		var yyyy = date.getFullYear();
		var mm = date.getMonth() + 1;
		var dd = date.getDay();
		var hh = date.getHours();
		var min = date.getMinutes();
		var ss = date.getSeconds();

		if (dd < 10) dd = '0' + dd;
		if (mm < 10) mm = '0' + mm;
		if (hh < 10) hh = '0' + hh;
		if (min < 10) min = '0' + min;
		if (ss < 10) ss = '0' + ss;

		return yyyy + '-' + mm + '-' + dd + 'T' + hh + ':' + min + ':' + ss;
	}

	function GetPepoleName(id){
		var str = '';
		var index = pepoleList.findIndex(element => element.id === id);
		if(index > -1){
			str = pepoleList[index].prenom + " " + pepoleList[index].nom;
		} else {
			str = "Inconnu"
		}
		return str;
	}
	//#endregion

	

	return (
		<Stack direction='column' margin="2em auto 2em 2.5%" width="95%" spacing={3} hidden={props.hidden(1)}>
			{briefTemplate.map((type, i) => {
				return (
					<div key={i}>
						<Accordion expanded={expanded === type.nom} onChange={handleExpanded(type.nom)}> 
							<AccordionSummary expandIcon={<ExpandMoreIcon />} sx={StyleAccordion}>
								<p>{type.nom}</p>
								{type.data.length > 0 && <StyledBadge badgeContent={type.data.length} color="primary" />}
							</AccordionSummary>
							<AccordionDetails>
								{GetBriefByType(type.id, type.data, type.nom)}
							</AccordionDetails>
						</Accordion>
					</div>
				)
			})}
		</Stack>
	)
});

const StyleAccordion = {
	"& .MuiAccordionSummary-content p": {
		fontSize: "1.1rem",
		padding: 0,
		margin: 0
	},
}



const StyledBadge = styled(Badge)(({ theme }) => ({
	'& .MuiBadge-badge': {
	  right: -25,
	  top: 13,
	  border: `2px solid ${theme.palette.background.paper}`,
	  padding: '0 10px',
	  alignSelf: 'center'
	},
  }));

//#region CLIENT INFO
const ClientInformation = (props) => {
	const [openAdd, setOpenAdd] = React.useState(false);

	function HandleClickOpen(open){
		setOpenAdd(open);
	}

	const columns = [
		{ field: 'id', headerName: 'ID', width: 70 },
		{ field: 'who', headerName: 'Par', minWidth: 200, flex: 0.5 },
		{ field: 'nom', headerName: 'Nom', minWidth: 130, flex: 1, editable: true },
		{ field: 'mail', headerName: 'Mail', minWidth: 130, flex: 1, editable: true },
		{ field: 'telephone', headerName: 'Téléphone', minWidth: 130, flex: 1, editable: true },
		{ field: 'action', headerName: 'Action', width: 250, renderCell: (params) => {
			return (
			  <Stack direction="row" spacing={2}>
				<CopyToClipboard text={params.row.mail} style={{marginLeft: "10px"}}>
					<Button variant="outlined" color="primary" size="small">Copier</Button>
				</CopyToClipboard>
				
				<Button variant="outlined" color="error" size="small" onClick={async () => await props.delete(params.row.idDb)}>Supprimer</Button>
			  </Stack>
			);
		}, },
	];
	var rows = [];

	props.data.forEach((data, i) => {
		rows.push({ id: data.id, who: props.findPepole(data.pepoleId), nom: data.text, mail: data.mail, telephone: data.telephone, idDb: data.id });
	})

	const copyMails = () => {
		var str = '';
		props.data.forEach((data) => str += data.mail + ";");
		return str;
	}

	function ProcessRowUpdate(newRow, oldRow){
		const updatedRow = { ...newRow, isNew: false };
		if(newRow === oldRow) return updatedRow;
		return props.update(0, updatedRow.name, updatedRow.mail, updatedRow.telephone, "", "", updatedRow.id)
		.then( _  => {
			return updatedRow;
		})
	}

	if(props.hidden(props.nom)){
		return <div></div>
	}

	return (
		<div >
			<div style={{height: "500px"}}>
				<DataGrid columns={columns} rows={rows} pageSize={50} experimentalFeatures={{ newEditingApi: true }}
					editMode='row'
					processRowUpdate={ProcessRowUpdate} />
			</div>
			<Stack marginTop={2} direction="row" spacing={2}>
				<Button variant="contained"  color="primary" onClick={() => HandleClickOpen(true)}>Ajouter une personne</Button>
				<CopyToClipboard text={copyMails()}>
					<Button variant="contained"  color="primary">Copier tous les mails</Button>
				</CopyToClipboard>
			</Stack>
			<AddInformation open={openAdd} onClose={HandleClickOpen} add={props.add}/>
		</div>
	)
}

const AddInformation = (props) => {
	const theme = useTheme();
	const { open, onClose } = props;
	const [name, setName] = React.useState("");
	const [mail, setMail] = React.useState("");
	const [telephone, setTelephone] = React.useState("");

	const changeName = (event) => setName(event.target.value);
	const changeMail = (event) => setMail(event.target.value);
	const changeTelephone = (event) => setTelephone(event.target.value);

	const handleClose = (save) => {
		if(save)
			props.add(0, name, mail, telephone, "", "");
		setName('');
		setMail('');
		setTelephone('');
		onClose(false);
	}
	return (
		<Dialog maxWidth="sm" open={open} onClose={() => handleClose(false)}>
			<DialogTitle color={theme.palette.text.primary}>Ajouter une personne</DialogTitle>
			<DialogContent>
				<Stack spacing={2} direction="column">
					<TextField label="Nom" variant="standard" value={name} onChange={changeName} />
					<TextField label="Mail" variant="standard" value={mail} onChange={changeMail} />
					<TextField label="Téléphone" variant="standard" value={telephone} onChange={changeTelephone} />
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => handleClose(true)} color="success">Ajouter</Button>
				<Button onClick={() => handleClose(false)}>Retour</Button>
			</DialogActions>
		</Dialog>
	)
}
//#endregion

//#region BRIEF INFO
const BriefInformation = (props) => {
	const theme = useTheme();
	const [openAdd, setOpenAdd] = React.useState(false);
	const [showEditDialog, setShowEditDialog] = React.useState(false);
	const [editText, setEditText] = React.useState("");
	const [rowInEdition, setRowInEdition] = React.useState();

	const changeText = (event) => setEditText(event.target.value);
	
	function HandleClickOpen(open){
		setOpenAdd(open);
	}

	const columns = [
		{ field: 'id', headerName: 'ID', width: 70 },
		{ field: 'who', headerName: 'Par', minWidth: 50, flex: 0.3 },
		{ field: 'date', headerName: 'Date', width: 100, },
		{ field: 'contenu', headerName: 'Contenu', editable: false, minWidth: 130, flex: 1, renderCell: (params) =>(<TextField
			fullWidth
			value={params.row.contenu}
			variant="standard"
			multiline
		  />) },
		{ field: 'action', headerName: 'Action', width: 100, renderCell: (params) => (<Button variant="outlined" color="error" size="small" onClick={async () => await props.delete(params.row.idDb)}>Supprimer</Button>) },
	];
	var rows = [];
	props.data.forEach((data, i) => {
		rows.push({ id: data.id, who: props.findPepole(data.pepoleId), date: GetStringFromDate(data.date), contenu: data.text, idDb: data.id });
	})

	function ProcessRowUpdate(newRow, oldRow){
		const updatedRow = { ...newRow, isNew: false };
		if(newRow === oldRow) return updatedRow;
		return props.update(1, updatedRow.contenu, "", "", "", "", updatedRow.id)
		.then( _  => {
			return updatedRow;
		})
	}

	function handleEdit(event) {
		setEditText(event.row.contenu);
		setRowInEdition(event.row);
		setShowEditDialog(true)
	}

	function handleClose(save) {
		if(save){
			var editedRowId = rowInEdition.id;
			setRowInEdition();
			setShowEditDialog(false)
			return props.update(1, editText, "", "", "", "", editedRowId);
		}
		setRowInEdition(-1);
		setShowEditDialog(false)
	}

	if(props.hidden(props.nom)){
		return <div></div>
	}

	return (
		<div>
			<div style={{height: "500px"}}>
				<DataGrid columns={columns} rows={rows} pageSize={50}  getRowHeight={() => 'auto'}
				experimentalFeatures={{ newEditingApi: true }}
				editMode='row'
				processRowUpdate={ProcessRowUpdate} 
				onRowDoubleClick={handleEdit}   />

			</div>
			<Stack marginTop={2} direction="row" spacing={2}>
				<Button variant="contained"  color="primary" onClick={() => HandleClickOpen(true)}>Ajouter un brief</Button>
			</Stack>
			<AddBriefInfo open={openAdd} onClose={HandleClickOpen} add={props.add}/>
			<Dialog fullWidth={true} maxWidth="md" open={showEditDialog} onClose={() => handleClose(false)}>
				<DialogTitle color={theme.palette.text.primary}>Modifier un brief</DialogTitle>
				<DialogContent>
					<Stack marginTop={2} spacing={2} direction="column">
						<TextField variant="standard" value={editText} onChange={changeText} multiline />
					</Stack>
				</DialogContent>
				<DialogActions>
					
					<Button onClick={() => handleClose(false)}>Retour</Button>
					<Button onClick={() => handleClose(true)} color="success">Enregistrer</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

const AddBriefInfo = (props) => {
	const theme = useTheme();
	const { open, onClose } = props;
	const [text, setText] = React.useState("");
	const changeText = (event) => setText(event.target.value);

	const handleClose = (save) => {
		if(save)
			props.add(1, text, "", "", "", "");
		setText('');
		onClose(false);
	}

	return (
		<Dialog fullWidth={true} maxWidth="md" open={open} onClose={() => handleClose(false)}>
				<DialogTitle color={theme.palette.text.primary}>Ajouter un brief</DialogTitle>
				<DialogContent>
					<Stack marginTop={2} spacing={2} direction="column">
						<TextField label="Brief" variant="standard" value={text} onChange={changeText} multiline rows={5} />
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose(true)} color="success">Ajouter</Button>
					<Button onClick={() => handleClose(false)}>Retour</Button>
				</DialogActions>
			</Dialog>
	)
}
//#endregion

//#region TOURNAGE INFO
const TournageInformation = (props) => {
	const [openAdd, setOpenAdd] = React.useState(false);

	function HandleClickOpen(open){
		setOpenAdd(open);
	}

	const columns = [
		{ field: 'id', headerName: 'ID', width: 70 },
		{ field: 'who', headerName: 'Par', minWidth: 200, flex: 0.5 },
		{ field: 'date', headerName: 'Date Tournage', minWidth: 200, flex: 1, editable: true, renderEditCell: RenderEditDate},
		{ field: 'address', headerName: 'Adresse', minWidth: 200, flex: 1 , editable: true, },
		{ field: 'action', headerName: 'Action', width: 150, renderCell: (params) => (<Button variant="outlined" color="error" size="small" onClick={async () => await props.delete(params.row.idDb)}>Supprimer</Button>) },
	];
	var rows = [];
	props.data.forEach((data, i) => {
		rows.push({ id: data.id, who: props.findPepole(data.pepoleId), date: GetStringFromDate(data.dateTournage), address: data.adresse, idDb: data.id });
	})

	function ProcessRowUpdate(newRow, oldRow){
		const updatedRow = { ...newRow, isNew: false };
		if(newRow === oldRow) return updatedRow;
		
		return props.update(2, "", "", "", updatedRow.address, GetStringForDB(updatedRow.date), updatedRow.id)
		.then( _  => {
			return updatedRow;
		})
	}

	if(props.hidden(props.nom)){
		return <div></div>
	}

	return (
		<div>
			<div style={{height: "500px"}}>
				<DataGrid columns={columns} rows={rows} pageSize={5} rowsPerPageOptions={[5]}
				experimentalFeatures={{ newEditingApi: true }}
				editMode='row'
				processRowUpdate={ProcessRowUpdate} />
			</div>
			<Stack marginTop={2} direction="row" spacing={2}>
				<Button variant="contained"  color="primary" onClick={() => HandleClickOpen(true)}>Ajouter une addresse de tournage</Button>
			</Stack>
			<AddTournageInfo open={openAdd} onClose={HandleClickOpen} add={props.add}/>
		</div>
	)
}

const RenderEditDate = (params) => {
	return <EditDate {...params}/>
}

function EditDate(props){
	const {id, value, field} = props;
	const apiRef = useGridApiContext();
	const [date, setDate] = React.useState(dayjs(GetStringForDB(value)));

	function HandleChange(event){
		setDate(event)
		apiRef.current.setEditCellValue({ id, field, value: GetStringFromDate(GetStrFromDaysJS(event)) });
	}

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DateTimePicker label="Date & Heure" value={date} onChange={(event) => HandleChange(event)} ampm={false} inputFormat="DD-MM-YYYY  HH:mm" 
				shouldDisableTime={(timeValue, clockType) => {
					if (clockType === 'minutes' && timeValue % 5) {
						return true;
					}
					return false;
					}}
				renderInput={(params) => <TextField fullWidth variant="standard" {...params} sx={{margin: 2}}/>} 
			/>
		</LocalizationProvider>
	)
}

function GetStringForDB(date){
	var tmp = date.split(" / ");
	var day = tmp[0].split("-");
	var hours = tmp[1].split(":");
	return day[0] + '-' + day[1] + '-' + day[2] + "T" + hours[0] + ":" + hours[1] + ':00';
}

function GetStrFromDaysJS(date){
	return date.$y + "-" + GetDigits(date.$M + 1) + "-" + GetDigits(date.$D) + "T" + GetDigits(date.$H) + ':' + GetDigits(date.$m) + ":" + GetDigits(date.$s);
}


const AddTournageInfo = (props) => {
	const theme = useTheme();
	const { open, onClose } = props;
	const [date, setDate] = React.useState(dayjs());
	const [text, setText] = React.useState("");
	const changeDate = (event) => setDate(event);
	const changeText = (event) => setText(event.target.value);

	const handleClose = async (save) => {
		if(save)
			props.add(2, "", "", "", text, GetDigits(date.$D) + "-" + GetDigits(date.$M + 1) + "-" + GetDigits(date.$y) + "T" + GetDigits(date.$H) + ':' + GetDigits(date.$m) + ":" + GetDigits(date.$s));
		setDate(dayjs());
		setText('');
		onClose(false);
	}
	return (
		<Dialog fullWidth={true} maxWidth="md" open={open} onClose={() => handleClose(false)}>
				<DialogTitle color={theme.palette.text.primary}>Ajouter une addresse de tournage</DialogTitle>
				<DialogContent>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<Stack marginTop={2} spacing={2} direction="column">
							<DateTimePicker label="Date & Heure de tournage" value={date} onChange={changeDate} ampm={false} inputFormat="DD-MM-YYYY  HH:mm" renderInput={(params) => <TextField variant="standard" {...params} />} />
							<TextField label="Addresse" variant="standard" value={text} onChange={changeText} />
						</Stack>
					</LocalizationProvider>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose(true)} color="success">Ajouter</Button>
					<Button onClick={() => handleClose(false)}>Retour</Button>
				</DialogActions>
				
			</Dialog>
	)
}
//#endregion

//#region TROUBLE INFO
const TroubleInformation = (props) => {
	const theme = useTheme();
	const [openAdd, setOpenAdd] = React.useState(false);

	function HandleClickOpen(open){
		setOpenAdd(open);
	}

	const columns = [
		{ field: 'id', headerName: 'ID', width: 70 },
		{ field: 'who', headerName: 'Par', minWidth: 200, flex: 0.5 },
		{ field: 'date', headerName: 'Date', width: 200, },
		{ field: 'contenu', headerName: 'Problème', minWidth: 130, flex: 1, editable: true, renderCell: (params) => (<Typography component='pre' display='block' variant='body1' color={theme.palette.text.primary}>{params.row.contenu}</Typography>)},
		{ field: 'action', headerName: 'Action', width: 150, renderCell: (params) => (<Button variant="outlined" color="error" size="small" onClick={async () => await props.delete(params.row.idDb)}>Supprimer</Button>) },
	];
	var rows = [];
	props.data.forEach((data, i) => {
		rows.push({ id: data.id, who: props.findPepole(data.pepoleId), date: GetStringFromDate(data.date), contenu: data.text, idDb: data.id });
	})

	function ProcessRowUpdate(newRow, oldRow){
		const updatedRow = { ...newRow, isNew: false };
		if(newRow === oldRow) return updatedRow;
		
		return props.update(3, updatedRow.contenu, "", "", "", "", updatedRow.id)
		.then( _  => {
			return updatedRow;
		})
	}

	if(props.hidden(props.nom)){
		return <div></div>
	}

	return (
		<div>
			<div style={{height: "500px"}}>
				<DataGrid columns={columns} rows={rows} pageSize={5} rowsPerPageOptions={[5]} getRowHeight={() => 'auto'}
				experimentalFeatures={{ newEditingApi: true }}
				editMode='row'
				processRowUpdate={ProcessRowUpdate} />
			</div>
			<Stack marginTop={2} direction="row" spacing={2}>
				<Button variant="contained"  color="primary" onClick={() => HandleClickOpen(true)}>Ajouter un problème</Button>
			</Stack>
			<AddTroubleInfo open={openAdd} onClose={HandleClickOpen} add={props.add}/>
		</div>
	)
}

const AddTroubleInfo = (props) => {
	const theme = useTheme();
	const { open, onClose } = props;
	const [text, setText] = React.useState("");
	const changeText = (event) => setText(event.target.value);

	const handleClose = async (save) => {
		if(save)
			await props.add(3, text, "", "", "", "");
		setText('');
		onClose(false);
	}

	return (
		<Dialog fullWidth={true} maxWidth="md" open={open} onClose={() => handleClose(false)}>
				<DialogTitle color={theme.palette.text.primary}>Ajouter un problème</DialogTitle>
				<DialogContent>
					<Stack marginTop={2} spacing={2} direction="column">
						<TextField label="Problème" variant="standard" value={text} onChange={changeText} multiline rows={5} />
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose(true)} color="success">Ajouter</Button>
					<Button onClick={() => handleClose(false)}>Retour</Button>
				</DialogActions>
			</Dialog>
	)
}
//#endregion

//#region RETOUR INFO
const RetourInformation = (props) => {
	const [openAdd, setOpenAdd] = React.useState(false);

	function HandleClickOpen(open){
		setOpenAdd(open);
	}

	const columns = [
		{ field: 'id', headerName: 'ID', width: 70 },
		{ field: 'who', headerName: 'Par', minWidth: 200, flex: 0.5 },
		{ field: 'date', headerName: 'Date', width: 200, },
		{ field: 'contenu', headerName: 'Contenu', minWidth: 130, flex: 1, editable: true },
		{ field: 'action', headerName: 'Action', width: 150, renderCell: (params) => (<Button variant="outlined" color="error" size="small" onClick={async () => await props.delete(params.row.idDb)}>Supprimer</Button>) },
	];
	var rows = [];
	props.data.forEach((data, i) => {
		rows.push({ id: data.id, who: props.findPepole(data.pepoleId), date: GetStringFromDate(data.date), contenu: data.text, idDb: data.id });
	})

	function ProcessRowUpdate(newRow, oldRow){
		const updatedRow = { ...newRow, isNew: false };
		if(newRow === oldRow) return updatedRow;
		
		return props.update(4, updatedRow.contenu, "", "", "", "", updatedRow.id)
		.then( _  => {
			return updatedRow;
		})
	}

	if(props.hidden(props.nom)){
		return <div></div>
	}

	return (
		<div>
			<div style={{height: "500px"}}>
				<DataGrid columns={columns} rows={rows} pageSize={5} rowsPerPageOptions={[5]}
				experimentalFeatures={{ newEditingApi: true }}
				editMode='row'
				processRowUpdate={ProcessRowUpdate}/>
			</div>
			<Stack marginTop={2} direction="row" spacing={2}>
				<Button variant="contained"  color="primary" onClick={() => HandleClickOpen(true)}>Ajouter un retour</Button>
			</Stack>
			<AddRetourInfo open={openAdd} onClose={HandleClickOpen} add={props.add}/>
		</div>
	)
}

const AddRetourInfo = (props) => {
	const theme = useTheme();
	const { open, onClose } = props;
	const [text, setText] = React.useState("");
	const changeText = (event) => setText(event.target.value);

	const handleClose = (save) => {
		if(save)
			props.add(4, text, "", "", "", "");
		setText('');
		onClose(false);
	}

	return (
		<Dialog fullWidth={true} maxWidth="md" open={open} onClose={() => handleClose(false)}>
				<DialogTitle color={theme.palette.text.primary}>Ajouter un retour</DialogTitle>
				<DialogContent>
					<Stack marginTop={2} spacing={2} direction="column">
						<TextField label="Retour" variant="standard" value={text} onChange={changeText} multiline rows={5} />
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose(true)} color="success">Ajouter</Button>
					<Button onClick={() => handleClose(false)}>Retour</Button>
				</DialogActions>
			</Dialog>
	)
}
//#endregion


//#region Link INFO
const LinkInformation = (props) => {
	const theme = useTheme();
	const [openAdd, setOpenAdd] = React.useState(false);

	function HandleClickOpen(open){
		setOpenAdd(open);
	}

	async function OpenExternal(link){
		await window.web.openInBrowser(link);
	}



	const columns = [
		{ field: 'id', headerName: 'ID', width: 70 },
		{ field: 'who', headerName: 'Par', minWidth: 200, flex: 0.5 },
		{ field: 'date', headerName: 'Date', width: 200, },
		{ field: 'contenu', headerName: 'Liens', minWidth: 130, flex: 1, editable: true, renderCell: (params) =>{
			if(parseInt(params.row.mail) === 1){
				if(!window.isOnWeb){
					return (
						<span style={{cursor: 'pointer', textDecoration: 'underline', fontWeight: 'bolder', fontSize: '1em', color: theme.palette.text.primary}} onClick={() => OpenExternal(params.row.contenu)}>{params.row.contenu}</span>
					)
				} else {
					return <Typography variant='body1'>Pas accessible</Typography>
				}
			}
			if(window.isOnWeb){
				return (<a href={params.row.contenu} target='_blank' rel="noopener noreferrer" style={{color: theme.palette.text.primary}}>{params.row.contenu}</a>)
			} else {
				return (<span style={{cursor: 'pointer', textDecoration: 'underline', fontWeight: 'bolder', fontSize: '1em', color: theme.palette.text.primary}} onClick={() => OpenExternal(params.row.contenu)}>{params.row.contenu}</span>)
			}
		} },
		{ field: 'action', headerName: 'Action', width: 150, renderCell: (params) => (<Button variant="outlined" color="error" size="small" onClick={() => props.delete(params.row.idDb)}>Supprimer</Button>) },
	];

	var rows = [];
	props.data.forEach((data, i) => {
		rows.push({ id: data.id, who: props.findPepole(data.pepoleId), date: GetStringFromDate(data.date), contenu: data.text, idDb: data.id, mail: data.mail });
	})

	function ProcessRowUpdate(newRow, oldRow){
		const updatedRow = { ...newRow, isNew: false };
		if(newRow === oldRow) return updatedRow;
		
		return props.update(5, updatedRow.contenu, String(updatedRow.mail), "", "", "", updatedRow.id)
		.then( _  => {
			return updatedRow;
		})
	}

	if(props.hidden(props.nom)){
		return <div></div>
	}

	return (
		<div>
			<div style={{height: "500px"}}>
				<DataGrid columns={columns} rows={rows} pageSize={50} 
				experimentalFeatures={{ newEditingApi: true }}
				editMode='row'
				processRowUpdate={ProcessRowUpdate}/>
			</div>
			<Stack marginTop={2} direction="row" spacing={2}>
				<Button variant="contained"  color="primary" onClick={() => HandleClickOpen(true)}>Ajouter un lien</Button>
			</Stack>
			<AddLinkInfo open={openAdd} onClose={HandleClickOpen} add={props.add}/>
		</div>
	)
}

const AddLinkInfo = (props) => {
	const theme = useTheme();
	const { open, onClose } = props;
	const [text, setText] = React.useState("");
	const [folder, setFolder] = React.useState("");
	const [link, setLink] = React.useState(0);
	const changeText = (event) => setText(event.target.value);
	const changeFolder = (event) => setFolder(event.target.value);

	const handleClose = (save) => {
		if(save)
			props.add(5, (link === 0) ? text : folder, String(link), "", "", "");
		setText('');
		onClose(false);
	}

	function HandleLink(event, value){
		if(value !== null){
			setLink(value);
		}
	}
	async function SelectFolderPath(){
		var res = await window.folder.selectFolder();
		setFolder((res.length > 0) ? res[0] + "\\" : "");
	}

	return (
		<Dialog fullWidth={true} maxWidth="md" open={open} onClose={() => handleClose(false)}>
				<DialogTitle color={theme.palette.text.primary}>Ajouter un lien</DialogTitle>
				<DialogContent>
					<Stack marginTop={2} spacing={2} direction="column">
						<Stack marginX="auto" alignItems="center">
							<Typography variant='body1'>Utiliser un lien internet ?</Typography>
							<ToggleButtonGroup value={link} label="Lien internet" color="primary" onChange={HandleLink} exclusive >
								<ToggleButton value={0}>OUI</ToggleButton>
								<ToggleButton value={1} >NON</ToggleButton>
							</ToggleButtonGroup>
						</Stack>
						{link === 0 && <TextField label="Lien" variant="standard" value={text} onChange={changeText} />}
						{!window.isOnWeb && link === 1 && <Stack direction="row" spacing={2}>
							<TextField fullWidth disabled label="Lien du Dossier sur le serveur" variant='standard' value={folder} onChange={changeFolder}/>
							<Button variant='outlined' onClick={async () => await SelectFolderPath()}>Choisir un dossier</Button>
						</Stack>}
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose(true)} color="success">Ajouter</Button>
					<Button onClick={() => handleClose(false)}>Retour</Button>
				</DialogActions>
			</Dialog>
	)
}
//#endregion


//#region Synthèse INFO
const SyntheseInformation = (props) => {
	const theme = useTheme();
	const [openAdd, setOpenAdd] = React.useState(false);

	function HandleClickOpen(open){
		setOpenAdd(open);
	}

	const columns = [
		{ field: 'id', headerName: 'ID', width: 70 },
		{ field: 'who', headerName: 'Par', minWidth: 200, flex: 0.5 },
		{ field: 'date', headerName: 'Date', width: 200, },
		{ field: 'contenu', headerName: 'Contenu', minWidth: 130, flex: 1, editable: true, renderCell: (params) =>(<Typography component='pre' display='block' variant='body1' color={theme.palette.text.primary}>{params.row.contenu}</Typography>) },
		{ field: 'action', headerName: 'Action', width: 150, renderCell: (params) => (<Button variant="outlined" color="error" size="small" onClick={async () => await props.delete(params.row.idDb)}>Supprimer</Button>) },
	];
	var rows = [];
	props.data.forEach((data, i) => {
		rows.push({ id: data.id, who: props.findPepole(data.pepoleId), date: GetStringFromDate(data.date), contenu: data.text, idDb: data.id });
	})

	function ProcessRowUpdate(newRow, oldRow){
		const updatedRow = { ...newRow, isNew: false };
		if(newRow === oldRow) return updatedRow;
		return props.update(6, updatedRow.contenu, "", "", "", "", updatedRow.id)
		.then( _  => {
			return updatedRow;
		})
	}

	if(props.hidden(props.nom)){
		return <div></div>
	}

	return (
		<div>
			<div style={{height: "500px"}}>
				<DataGrid columns={columns} rows={rows} pageSize={5} rowsPerPageOptions={[5]}getRowHeight={() => 'auto'}  
				experimentalFeatures={{ newEditingApi: true }}
				editMode='row'
				processRowUpdate={ProcessRowUpdate}/>
			</div>
			<Stack marginTop={2} direction="row" spacing={2}>
				<Button variant="contained"  color="primary" onClick={() => HandleClickOpen(true)}>Ajouter une synthèse</Button>
			</Stack>
			<AddSynthesInfo open={openAdd} onClose={HandleClickOpen} add={props.add}/>
		</div>
	)
}

const AddSynthesInfo = (props) => {
	const theme = useTheme();
	const { open, onClose } = props;
	const [text, setText] = React.useState("");
	const changeText = (event) => setText(event.target.value);

	const handleClose = (save) => {
		if(save)
			props.add(6, text, "", "", "", "");
		setText('');
		onClose(false);
	}

	return (
		<Dialog fullWidth={true} maxWidth="md" open={open} onClose={() => handleClose(false)}>
				<DialogTitle color={theme.palette.text.primary}>Ajouter une synthèse</DialogTitle>
				<DialogContent>
					<Stack marginTop={2} spacing={2} direction="column">
						<TextField label="Synthèse" variant="standard" value={text} onChange={changeText} multiline rows={5} />
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose(true)} color="success">Ajouter</Button>
					<Button onClick={() => handleClose(false)}>Retour</Button>
				</DialogActions>
			</Dialog>
	)
}
//#endregion



//#region Checl INFO
const CheckInformation = (props) => {
	const [openAdd, setOpenAdd] = React.useState(false);

	function HandleClickOpen(open){
		setOpenAdd(open);
	}

	const columns = [
		{ field: 'id', headerName: 'ID', width: 70 },
		{ field: 'who', headerName: 'Par', minWidth: 200, flex: 0.5 },
		{ field: 'date', headerName: 'Date', width: 200, },
		{ field: 'contenu', headerName: 'Contenu', minWidth: 1000, flex: 1, editable: true},
		{ field: 'action', headerName: 'Action', width: 150, renderCell: (params) => (<Button variant="outlined" color="error" size="small" onClick={() => props.delete(params.row.idDb)}>Supprimer</Button>) },
	];
	var rows = [];

	props.data.forEach((data, i) => {
		rows.push({ id: data.id, who: props.findPepole(data.pepoleId), date: GetStringFromDate(data.date), contenu: data.text, idDb: data.id });
	})

	function ProcessRowUpdate(newRow, oldRow){
		const updatedRow = { ...newRow, isNew: false };
		if(newRow === oldRow) return updatedRow;
		return props.update(7, updatedRow.contenu, "", "", "", "", updatedRow.id)
		.then( _  => {
			return updatedRow;
		})
	}	

	if(props.hidden(props.nom)){
		return <div></div>
	}

	return (
		<div>
			<div style={{height: "500px"}}>
				<DataGrid columns={columns} rows={rows} pageSize={50} 
				experimentalFeatures={{ newEditingApi: true }}
				editMode='row'
				processRowUpdate={ProcessRowUpdate}/>
			</div>
			<Stack marginTop={2} direction="row" spacing={2}>
				<Button variant="contained"  color="primary" onClick={() => HandleClickOpen(true)}>Ajouter un check</Button>
			</Stack>
			<AddCheckInfo open={openAdd} onClose={HandleClickOpen} add={props.add}/>
		</div>
	)
}

const AddCheckInfo = (props) => {
	const theme = useTheme();
	const { open, onClose } = props;
	const [text, setText] = React.useState("");
	const changeText = (event) => setText(event.target.value);

	const handleClose = (save) => {
		if(save)
			props.add(7, text, "", "", "", "");
		setText('');
		onClose(false);
	}

	return (
		<Dialog fullWidth={true} maxWidth="md" open={open} onClose={() => handleClose(false)}>
				<DialogTitle color={theme.palette.text.primary}>Ajouter un check</DialogTitle>
				<DialogContent>
					<Stack marginTop={2} spacing={2} direction="column">
						<TextField label="A checker" variant="standard" value={text} onChange={changeText} />
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose(true)} color="success">Ajouter</Button>
					<Button onClick={() => handleClose(false)}>Retour</Button>
				</DialogActions>
			</Dialog>
	)
}
//#endregion


function GetStringFromDate(date){
	var array = date.split("T");
	var time = array[1].split(':');
	var str = array[0].split("-")[2] + "-" + GetDigits(parseInt(array[0].split("-")[1])) + "-" + array[0].split("-")[0] + " / " + time[0] + ":" + time[1];
	return str;
}

function GetDigits(value){
	var str ='' + value;
	return str.padStart(2, '0');
}

