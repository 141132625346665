import React from 'react';

import { alpha, Checkbox, MenuItem, Paper, Select, Stack, TextField, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

require('dayjs/locale/fr');
dayjs.locale('fr');

export const ProjectCreationTasks = React.forwardRef((props, ref) => {
	const theme = useTheme();
	const { pepoleList, taskTemplate, subtaskTemplate } = props;
	const [sortedSubtaskTemplate, setSortedSubtaskTemplate] = React.useState([]);
	const taskRef = React.useRef([]);
	React.useImperativeHandle(ref, () => ({
		GetData: () => {
			var array = []
			taskRef.current.forEach(task => {
				var tmp = task.getSelectedRows();
				if(tmp.subtasks.length > 0)
					array.push(tmp);
			})
			return array;
		},
		Reset: () => {
			taskRef.current = [];
		}
	}))

	React.useEffect(() => {
		var sorted = [];
		taskTemplate.forEach(task => {
			sorted.push({...task, subtasks: subtaskTemplate.filter(subtask => task.id === subtask.idTache)});
		})
		setSortedSubtaskTemplate(sorted);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Paper elevation={4} sx={{padding: 5, marginTop: 2}} hidden={props.hidden(2)}>
			<Typography mb={2} textAlign='center' variant="h6" color={theme.palette.text.primary}>Tâches</Typography>
			
			{sortedSubtaskTemplate && sortedSubtaskTemplate.map((task, i) => {
				task.subtasks = task.subtasks.sort((a, b) => ((a.nom === "Autre") ? 1 : -1) - ((b.nom === "Autre") ? 1 : -1) )
				return <TaskRow key={task.id} ref={(element) => taskRef.current[i] = element} task={task} pepoleList={pepoleList}/>
			})}
		</Paper>
	)
})



const TaskRow = React.forwardRef((props, ref) => {
	const theme = useTheme();
	const { pepoleList } = props;
	const [open, setOpen] = React.useState(false);
	const [task, setTask] = React.useState(props.task);

	const rowsRef = React.useRef([]);

	React.useImperativeHandle(ref, () => ({
		getSelectedRows: () => {
			var selectedRows = [];
			rowsRef.current.forEach(row => {
				if(row.isSelected()){
					selectedRows.push(row.getRowData());
				}
			})
			return {task, subtasks: selectedRows};
		}
	}))

	function AddNewOther(other){
		setTask({subtasks: task.subtasks.push(other),...task});
	}

	

	return (
		<Accordion expanded={open} onChange={(e) => setOpen(!open)} sx={{width: '100%'}} elevation={2}>
			<AccordionSummary>
				<Typography variant='body1' color={theme.palette.text.primary}>{task.nom}</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Stack direction="column" spacing={0}>
					{task.subtasks && task.subtasks.map((subtask, i) => (
						<SubtaskRow key={i} ref={(element) => rowsRef.current[i] = element} subtask={subtask} pepoleList={pepoleList} addNewOther={AddNewOther} isLast={i === task.subtasks.length - 1} isDeadline={task.nom.includes("Deadline")}/>
					))}
				</Stack>
			</AccordionDetails>

		</Accordion>
	)
});

const SubtaskRow = React.forwardRef((props, ref) => {
	const theme = useTheme();
	const { subtask, pepoleList } = props;
	const [name, setName] = React.useState('');
	const [selected, setSelected] = React.useState(false);
	const [pepoles, setPepoles] = React.useState([]);
	const [date, setDate] = React.useState(dayjs())

	function ChangeName(event){ setName(event.target.value); }
	function ChangeSelected(event){ 
		if(subtask.nom === 'Autre' && props.isLast){
			props.addNewOther(subtask);
		}
		setSelected(event.target.checked); 

	}
	function ChangePepoles(event){ setPepoles(event.target.value); }

	React.useImperativeHandle(ref, () => ({
		isSelected: () => { return selected; },
		getRowData: () => { return {id: subtask.id, name, pepoles, subtask, date}; }
	}))

	return (
		<Stack direction='row' spacing={2} sx={{ paddingBottom: 2, backgroundColor: (selected) ? alpha(theme.palette.primary.light, 0.1) : ""}}>
			<Checkbox checked={selected} onChange={ChangeSelected} />
			{props.isDeadline === false &&
		 		<TextField fullWidth variant='standard' label={subtask.nom} disabled={subtask.nom !== 'Autre'} value={name} onChange={ChangeName}/>
		 	}
		 	{props.isDeadline === true &&
		 		<Stack direction='row' spacing={2} width='100%' textAlign='left' alignItems='center'>
					<Typography variant='body1' color={theme.palette.text.primary} sx={{width: '100%'}}>{subtask.nom}</Typography>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker value={date} disabled={!selected} onChange={setDate} ampm={false} inputFormat="DD-MM-YYYY" renderInput={(params) => <TextField fullWidth label={subtask.nom} variant="standard" {...params} />} />
					</LocalizationProvider>
				</Stack>
				
		 	}
			<Select fullWidth multiple variant='standard' disabled={!selected} value={pepoles} onChange={ChangePepoles} sx={{"& .MuiSelect-select": {overflow: "hidden", textOverflow: "ellipsis", whiteSpace: 'nowrap'}}}>
				{pepoleList && pepoleList.map(pepole => (				
					<MenuItem key={pepole.id} value={pepole.id}>{pepole.prenom + ' ' + pepole.nom}
				</MenuItem>
 			))}
	 		</Select> 

		</Stack>
	)
});


const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
	  borderBottom: 0,
	},
	'&:before': {
	  display: 'none',
	},
  }));
  
  const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
	  expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
	  {...props}
	/>
  ))(({ theme }) => ({
	backgroundColor:
	  theme.palette.mode === 'dark'
		? 'rgba(255, 255, 255, .05)'
		: 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
	  transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
	  marginLeft: theme.spacing(1),
	},
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
