import React from 'react';

import { Button, MenuItem, Paper, Stack, TextField, Typography, Switch, useTheme } from '@mui/material';
import Session from 'react-session-api';

export const ProjectCreationInformation = React.forwardRef((props, ref) => {
	const pepoleData = JSON.parse(Session.get('pepoleData'));
	const { clientList, devisList, pepoleList } = props;
	const [name, setName] = React.useState('');
	const [vimeo, setViemo] = React.useState('https://vimeo.com/');
	const [clientName, setClientName] = React.useState('');
	const [clientMail, setClientMail] = React.useState('');
	const [client, setClient] = React.useState('');
	const [devis, setDevis] = React.useState('');
	const [folder, setFolder] = React.useState('');
	const [projectLeader, setProjectLeader] = React.useState(pepoleData.id);
	const [transverseProject, setTransverseProject] = React.useState(false);

	const theme = useTheme();

	function ChangeName(event){ setName(event.target.value); }
	function ChangeVimeo(event){ setViemo(event.target.value); }
	function ChangeClientName(event){ setClientName(event.target.value); }
	function ChangeClientMail(event){ setClientMail(event.target.value); }
	function ChangeClient(event){ setClient(event.target.value); }
	function ChangeDevis(event){ setDevis(event.target.value); }
	function ChangeFolder(event){ setFolder(event.target.value); }
	function ChangeProjectLeader(event){ setProjectLeader(event.target.value); }
	function ChangeTransverseProject(value){ setTransverseProject(value); }

	React.useImperativeHandle(ref, () => ({
		GetData: () => {
			return { name, vimeo, clientName, clientMail, client: (client === '') ? -1 : client, devis, folder, projectLeader, transverseProject }
		},
		Reset: () => {
			setName('');
			setViemo('');
			setClientName('');
			setClientMail('');
			setClient('');
			setDevis('');
			setFolder('');
			setProjectLeader('');
		}, 
		SetDataFromDevis: (data) => {
			setName(data.reference);
			setClient(data.idClient);
			setClientName(data.contactClient);
			setClientMail(data.mailClient);
			setDevis(data.id);
		},
	}))

	async function SelectFolderPathOnServer(){
		var res = await window.folder.selectFolder();
		setFolder(res[0] + "\\");
	} 

	return (
		<Paper elevation={4} sx={{padding: 5, marginTop: 2}} hidden={props.hidden(1)}>
			<Typography mb={2} textAlign='center' variant="h6" color={theme.palette.text.primary}>Information du projet</Typography>
			<Stack direction='column' spacing={2}>
				<Stack direction='row' spacing={2}>
					<TextField fullWidth variant='standard' label='Nom du projet' value={name} onChange={ChangeName}/>
					<TextField fullWidth variant='standard' label='Lien viméo du projet' value={vimeo} onChange={ChangeVimeo}/>
				</Stack>
				<Stack direction='row' spacing={2}>
					<TextField fullWidth variant='standard' label='Nom du client' value={clientName} onChange={ChangeClientName}/>
					<TextField fullWidth variant='standard' label='Mail du client' value={clientMail} onChange={ChangeClientMail}/>
				</Stack>
				<Stack direction='row' spacing={2}>
					<TextField fullWidth select variant='standard' label='Entreprise du client' value={client} onChange={ChangeClient}>
						{clientList && clientList.map(client => (
							<MenuItem key={client.id} value={client.id}>{client.nomEntite}</MenuItem>
						))}
					</TextField>
					<TextField fullWidth select variant='standard' label='Devis du projet' value={devis} onChange={ChangeDevis}>
						{devisList && devisList.map(devis => (
							<MenuItem key={devis.id} value={devis.id}>{devis.nom + " - " + devis.nomEntite + ' - ' + devis.contactClient}</MenuItem>
						))}
					</TextField>
				</Stack>
				{window.isOnWeb === false && 
					<Stack direction='row' spacing={2}>
						<TextField fullWidth disabled variant='standard' label="Chemin du dossier sur le serveur" value={folder} onChange={ChangeFolder}/>
						<Button variant='outlined' onClick={() => SelectFolderPathOnServer()}>Choisir un dossier</Button>
					</Stack>
				}
				<TextField fullWidth select variant='standard' label='Chef de projet' value={projectLeader} onChange={ChangeProjectLeader}>
					{pepoleList && pepoleList.map(pepole => (
						<MenuItem key={pepole.id} value={pepole.id}>{pepole.prenom + " " + pepole.nom}</MenuItem>
					))}
				</TextField>
				<Stack spacing={2} direction="row">
					<Typography style={{whiteSpace: "nowrap", marginTop: "7px"}} variant='body1' >Projet transverse</Typography>
					<Switch checked={transverseProject} onChange={(event) => ChangeTransverseProject(!transverseProject)}/>
				</Stack>
			</Stack>
		</Paper>
	)
})