import React from 'react';

import { Stack, useMediaQuery } from '@mui/material';

import { AccompteButtons } from './AccompteButtons';
import { ChangeAccountFilter } from './Views/ChangeAccountFilter';
import { ChangePassword } from './Views/ChangePassword';

export const Accompte = (props) => {
	const isDesktop = useMediaQuery('(min-width:1065px)');
	const [currentView, setCurrentView] = React.useState(0);
	const changeCurrentView = (id) => setCurrentView(id);

	function GetView(){
		switch(currentView){
			case 0: return <ChangeAccountFilter/>
			case 1: return <ChangePassword/>
			default: return <ChangeAccountFilter/>
		}
	}

	return (
		<Stack direction='row' width="100%" >
			{isDesktop && <AccompteButtons updateView={changeCurrentView}/>}
			{GetView()}
		</Stack>
	)
}