import React from "react";
import { GET } from "../../../../utils/AxiosRequest";

import { Stack } from "@mui/material";

import { TurnoverByMounthChart } from "./TurnoverCharts/TurnoverByMonth";
import { TurnoverByClientGroups } from "./TurnoverCharts/TurnoverByClientGroup";


export const TurnoverView = (props) => {
	const [factures, setFactures] = React.useState([]);
	const [devisValides, setDevisValide] = React.useState([]);
	const [clients, setClients] = React.useState([]);

	React.useEffect( _ => {
		let isMounted = true;
		Promise.all([
			GET("administration/getAllFactures", {}),
			GET("administration/getAllClient", {}),
			GET("administration/getAllDevisValide", {}),
			// GET("administration/getAllDevis", {}),
		])
		.then(data => {
			if(isMounted){
				setFactures([...data[0]]);
				setClients([...data[1]]);
				setDevisValide([...data[2]])
			}
		})

		return _ => { isMounted = false; }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Stack direction='row' justifyContent="center" width='100%' marginBottom={50}>
			<Stack direction="column" spacing={4} marginTop={5} width="95%" >
				<TurnoverByMounthChart factures={factures} devisValides={devisValides} clients={clients}/>
				<Stack direction='row' spacing={4} width="50%">
					<TurnoverByClientGroups data={factures} clients={clients}/>
				</Stack>
			</Stack>
		</Stack>
	)
}
