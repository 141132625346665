import React from 'react';
import {Planning as PlanningComponent} from '../components/Planning/PlanningComponent';
import { Navbar } from '../components/Navbar';
import { Stack } from '@mui/material';

// Page Devis
const Planning = () => {
    return (
		<Stack minWidth="100%" direction="row">
			<Navbar />
            <PlanningComponent/>
		</Stack>
    );
};

export default Planning;