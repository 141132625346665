import React  from "react";
import { GET, POST, DELETE, UPDATE } from '../../../utils/AxiosRequest';

import Button from '@mui/material/Button';

import { DataGrid, GridToolbarContainer, GridRowModes, GridActionsCellItem } from '@mui/x-data-grid';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle'
import { useTheme } from "@mui/material";

export const ShowTarifs = (props) => {
	const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [rows, setRows] = React.useState([]);
	const [rowModesModel, setRowModesModel] = React.useState({});

	

    const columns = [
        {field: 'reference', headerName: 'Référence', flex: 1, editable: true},
        {field: 'designation', headerName: 'Désignation', flex: 1, editable: true},
        {field: 'price', headerName: 'Prix', flex: 0.5, editable: true, renderCell: (params) => (
			parseFloat(params.row.price).toFixed(2) + " € HT"
		)},
        {field: 'action', headerName: 'Action', type: 'actions', width: 100, sortable:false, editable: false, getActions: ({ id }) => {
			const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
			if(isInEditMode){
				return [
					<GridActionsCellItem icon={<SaveTwoToneIcon color="success"/>} label='test' onClick={HandleSaveClick(id)}/>,
					<GridActionsCellItem icon={<CancelTwoToneIcon color="warning"/>} label='test' onClick={HandleCancelClick(id)}/>,
				];
			} else {
				return [
					<GridActionsCellItem icon={<EditTwoToneIcon color="info"/>} label='test' onClick={HandleEditClick(id)}/>,
					<GridActionsCellItem icon={<DeleteForeverTwoToneIcon color="error"/>} label='test' onClick={HandleDeleteClick(id)}/>,
				];
			}
		} },
    ];

    function HandleOpen(){
        
        return GET("devis/getAllTarifs",{})
        .then(data => {
            setRows(() => {
                return data.map((tarif) => {
                    return {
                        id: tarif.id,
                        reference: tarif.reference,
                        designation: tarif.designation,
                        price: parseFloat(tarif.prix),
						isNew: false,
                    }
                })
            });
            setOpen(true);
        })
    }

    function HandleClose(){
		props.reloadTarifs()
		setOpen(false);
    }


	//#region HANDLERS
	function HandleRowEditStart(params, event){
		event.defaultMuiPrevented = true;
	};
	
	function HandleRowEditStop(params, event){
		event.defaultMuiPrevented = true;
	};

	const HandleEditClick = (id) => () => {
		setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
	}

	const HandleSaveClick = (id) => () => {
		setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
	}

	const HandleDeleteClick = (id) => () => {
		return DELETE("devis/deleteTarifById", {id: id})
		.then(() => {
			setRows(rows.filter((row) => row.id !== id));
		})
	}

	const HandleCancelClick = (id) => () => {
		setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View, ignoreModifications: true }});
		const editedRow = rows.find((row) => row.id === id);
		if (editedRow.isNew) {
			setRows(rows.filter((row) => row.id !== id));
		  }
	}

	function ProcessRowUpdate(newRow){
		const updatedRow = { ...newRow, isNew: false };
		var promises = [
			(newRow.isNew) ? POST("devis/insertNewReference", {reference: newRow.reference, designation: newRow.designation, prix: newRow.price})
			: UPDATE("devis/updateTarif", {reference: newRow.reference, designation: newRow.designation, prix: newRow.price, id: newRow.id}),
		]
		return Promise.all(promises)
		.then(() => {
			setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
			return updatedRow;
		})
		
	  };
	//#endregion

    return (
        <div>
            <Button fullWidth variant="contained" onClick={() => HandleOpen()}>Consulter les tarifs</Button>
            <Dialog fullWidth maxWidth="xl" open={open} onClose={() => HandleClose()}>
				<DialogTitle color={theme.palette.text.primary}>Consulter les tarifs</DialogTitle>
				<DialogContent>
                    <DataGrid sx={{height: 800}} rowsPerPageOptions={[20, 50, 100]} columns={columns} pagination rows={rows} initialState={{pagination: { pageSize: 50, }}} editMode='row' components={{Toolbar: EditToolbar }} componentsProps={{toolbar: {rows, setRows, setRowModesModel}}} experimentalFeatures={{ newEditingApi: true }}
						rowModesModel={rowModesModel}
						onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
						onRowEditStart={HandleRowEditStart}
        				onRowEditStop={HandleRowEditStop}
						processRowUpdate={ProcessRowUpdate}
					/> 
				</DialogContent>
				<DialogActions>
					<Button onClick={() => HandleClose()}>Retour</Button>
				</DialogActions>
			</Dialog>
        </div>
    )
}

function EditToolbar(props) {
	const { rows, setRows, setRowModesModel } = props;
  
	const handleClick = () => {
		var id = -100;
		rows.forEach((row) => id = (row.id > id) ? row.id + 1 : id);
		setRows((oldRows) => [{ id, reference: 'Réference à remplacer', designation: 'Désignation à remplacer', price: 0, isNew: true }, ...oldRows]);
		setRowModesModel((oldModel) => ({
			...oldModel,
			[id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
		}));
	};
  
	return (
	  <GridToolbarContainer>
		<Button color="primary" startIcon={<AddCircleTwoToneIcon />} onClick={handleClick}>Ajouter une référence</Button>
	  </GridToolbarContainer>
	);
  }