import * as React from "react";
import { GET, UPDATE } from "../../utils/AxiosRequest";

import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";


export function Improvement(props){
	const theme = useTheme();
	// eslint-disable-next-line no-unused-vars
	const [pepoles, setPepoles] = React.useState([]);
	const [rows, setRows] = React.useState([]);

	const columns = [
		{ field: 'who', headerName: 'Par', width: 200, },
		{ field: 'date', headerName: 'Date', width: 200, },
		{ field: 'name', headerName: 'Nom', width: 200 },
		{ field: 'contenu', headerName: 'Problème', minWidth: 250, flex: 1, renderCell: (params) => (
			<Typography component='pre' display='block' variant='body1' color={theme.palette.text.primary} sx={{whiteSpace: 'pre-line'}} >{params.row.contenu}</Typography>
		)},
		{ field: 'action', headerName: 'Action', width: 150, renderCell: (params) => (
			<Button variant="outlined" color="warning" size="small" onClick={ _ => MaskBrief(params.row.idDb)}>Masquer</Button>) },
	];

	React.useEffect( _ => {
		let isMounted = true;

		Promise.all([
			GET("briefs/getBriefByType", {type: 3}),
			GET("projects/getAllEmployeInfo")
		])
		.then(data => {
			if(isMounted){
				setPepoles(data[1]);
				var tmp = [];
				data[0].forEach(element => {
					tmp.push({
						id: element.id, 
						who: GetPepoleName(element.pepoleId, data[1]), 
						name: element.nom, 
						date: GetStringFromDate(element.date),
						contenu: element.text,
						idDb: element.id
					})
				});
				setRows(tmp)
			}
		})

		return _ => {
			isMounted = false;
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	function MaskBrief(id){
		return UPDATE("briefs/updateBriefImprovement", {id})
		.then( _ => {
			var tmp = [...rows];
			var index = tmp.findIndex(element => element.id === id)
			if(index !== -1){
				tmp.splice(index, 1);
				setRows([...tmp]);
			}
		})
	}

	function GetPepoleName(id, array){
		var str = '';
		var index = array.findIndex(element => element.id === id);
		if(index > -1){
			str = array[index].prenom + " " + array[index].nom;
		} else {
			str = "Inconnu"
		}
		return str;
	}

	return (
		<Box width="100%" paddingX={4} marginY={2}>
			<DataGrid sx={{height: "80%"}}  columns={columns} rows={rows} getRowHeight={() => 'auto'}  pageSize={100} rowsPerPageOptions={[20, 50, 100]} initialState={{pagination: { pageSize: 50, }}}/>
		</Box>
	)
}


function GetStringFromDate(date){
	var array = date.split("T");
	var time = array[1].split(':');
	var str = array[0].split("-")[2] + "-" + GetDigits(parseInt(array[0].split("-")[1])) + "-" + array[0].split("-")[0] + " / " + time[0] + ":" + time[1];
	return str;
}

function GetDigits(value){
	var str ='' + value;
	return str.padStart(2, '0');
}

