import React from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Stack, TextField, useTheme, } from "@mui/material";
import dayjs from "dayjs";
import { ArgumentAxis, BarSeries, Chart, Legend, Title, Tooltip, ValueAxis } from "@devexpress/dx-react-chart-material-ui";
import { EventTracker, HoverState, Stack as StackDX,  } from "@devexpress/dx-react-chart";
import { DataGrid } from "@mui/x-data-grid";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


require('dayjs/locale/fr');
dayjs.locale('fr');

export const TurnoverByMounthChart = (props) => {
	const { factures, devisValides, clients } = props;
	const [sortedData, setSortedData] = React.useState([]);
	const [targetItem, setTargetItem] = React.useState(null);
	const [hoverItem, setHoverItem] = React.useState(null);
	const [date, setDate] = React.useState(dayjs());

	const barDetailsRef = React.useRef(null);

	React.useEffect( _ => {
		SortData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	React.useEffect( _ => {
		SortData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [factures, devisValides, date])

	function SortData(){
		var array = [];
		for(var i = 0; i < 12; i++){
			var obj = {
				argument: GetDateFromDayJs(date.subtract(i, 'month')),
				facturesAmount: 0,
				factures: [],
				devisValidesAmount: 0,
				devis: [],
				
			}
			array.push(obj);
		}
		array.reverse();

		factures.forEach(item => {
			var date = GetDateFromDayJs(dayjs(GetCorrectDate(item.date)))
			var index = array.findIndex(element => element.argument === date)
			if(index !== -1){
				array[index].facturesAmount += parseFloat(item.total); 
				array[index].factures.push(item);
			}
		})
		devisValides.forEach(item => {
			var date = GetDateFromDayJs(dayjs(GetCorrectDate(item.date)))
			var index = array.findIndex(element => element.argument === date)
			if(index !== -1){
				
				array[index].devisValidesAmount += parseFloat(item.total); 
				array[index].devis.push(item);
			}
		})
		setSortedData([...array]);
	}	

	function OpenDialog(event){
		barDetailsRef.current.handleOpen(sortedData[event.targets[0].point], event.targets[0].series === "Devis Validés")
		// console.log(sortedData[event.targets[0].point]);
	}


	return (
		<Paper sx={{width: '100%'}}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DatePicker label="Mois pour le graphique" value={date} onChange={setDate} inputFormat="MM-YYYY"
					renderInput={(params) => <TextField variant="standard" {...params} sx={{margin: 2}}/>}
				/>
			</LocalizationProvider>
			<Chart data={sortedData}>
				<Title text="Chiffre d'affaire sur les 12 dernier mois" />
				<ArgumentAxis />
				<ValueAxis showGrid />
				<Legend position="right" />
				<BarSeries
					name="Factures"
					valueField="facturesAmount"
					argumentField="argument"
				/>
				<BarSeries
					name="Projets avec deadline"
					valueField="devisValidesAmount"
					argumentField="argument"
				/>
				<EventTracker onClick={(event) => OpenDialog(event)}/>
				<HoverState hover={hoverItem} onHoverChange={(hover) => setHoverItem(hover)} />
				<Tooltip targetItem={targetItem} onTargetItemChange={(target) => setTargetItem(target)} contentComponent={TooltipContent}  />
				<StackDX />
			</Chart>
			<BarDetails ref={barDetailsRef} clients={clients}/>
		</Paper>
	)

}
// contentComponent={tooltip}
//#region Dates
function GetDateFromDayJs(date = dayjs()){
	return date.format("MMMM YYYY")
}

function GetCorrectDate(date){
	var str = date.split('/')
	return str[2] + '-' + str[1] + '-' + str[0];
}
//#endregion

const tooltipContentTitleStyle = {
	fontWeight: 'bold',
	paddingBottom: 0,
  };

const TooltipContent = (props) => {
	const { targetItem, text, ...restProps } = props;
	return (
		<div>
			<div>
				<Tooltip.Content
				{...restProps}
				style={tooltipContentTitleStyle}
				text={targetItem.series + ": " + parseFloat(text).toFixed(2) + " € HT"}
				/>
			</div>
		</div>
	);
};

const BarDetails = React.forwardRef((props, ref) => {
	const { clients } = props;
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const [isDevis, setIsDevis] = React.useState(false);
	const [data, setData] = React.useState([]);
	const [date, setDate] = React.useState("");

	React.useImperativeHandle(ref, _ => ({
		handleOpen: (data, isDevis) => {
			setDate(data.argument);
			setIsDevis(isDevis);
			setData((isDevis) ? data.devis : data.factures);
			setOpen(true);
		}
	}))

	function HandleClose(){
		setIsDevis(false);
		setData([]);
		setOpen(false);
	}

	const columns = [
		{ field: 'name', headerName: 'Nom', width: 100},
		{ field: 'total', headerName: 'Montant', maxWidth: 200, flex: 1},
		{ field: 'reference', headerName: 'Référence', minWidth: 200, flex: 1},
		{ field: 'client', headerName: 'Client', minWidth: 400, flex: 1},
		{ field: 'date', headerName: 'Date', maxWidth: 200, flex: 1},
	]

	if(!isDevis && data.length > 0){
		columns.push(
			{ field: 'dateEcheance', headerName: 'Échéance', maxWidth: 200, flex: 1},
		)
	}

	const rows = [];
	data.forEach(element => {
		var clientIndex = (isDevis) ? element.idClient : element.clientId;
		var client = clients.find(item => item.id === clientIndex)
		
		rows.push({
			id: element.id,
			name: element.nom,
			reference: element.reference,
			client: (client) ? client.nomEntite : "Pas de client",
			total: parseFloat(element.total).toFixed(2) + " € HT",
			date: element.date,
			dateEcheance: (isDevis) ? '' : element.echeance,
		})
	})

	return (
		<Dialog fullWidth maxWidth="xl" open={open} onClose={() => HandleClose()}>
			<DialogTitle color={theme.palette.text.primary}>{(isDevis) ? "Voir les projets validés avec deadline du mois " + date : "Voir les factures du mois " + date }</DialogTitle>
			<DialogContent>
				<Stack direction="column" spacing={2} height="700px">
					<DataGrid columns={columns} rows={rows} />
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => HandleClose(false)}>Retour</Button>
			</DialogActions>
		</Dialog>
	)
})