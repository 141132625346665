import React from 'react';
import { Document, StyleSheet, Page } from '@react-pdf/renderer'

import { Header } from './BonLivraisonHeader';
import { Body } from './BonLivraisonBody';
import { Footer } from './BonLivraisonFooter'
import { Total } from './BonLivraisonTotal';



const BonLivraisonPDF = props => {
    return(
        <Document title='test'>
                <Page size='A4' style={styles.page} >
                    <Header data={props.data} fixed/>
                    <Body style={styles.body} data={props.data}/>
                    <Footer style={styles.footer} fixed/>
                </Page>
                <Page size='A4' style={styles.page} >
                    <Header data={props.data} fixed/>
                    <Total data={props.data} total={props.data.bonData.total} accompte={props.data.bonData.accompte} tva={20}/>
                    <Footer style={styles.footer} fixed/>
                </Page>
            </Document>
        
    )
}

const styles = StyleSheet.create({
    page: {
      paddingTop: 15,
      paddingBottom: "10%",
      paddingHorizontal: 15,
      fontSize: '10pt',
    },
    header: {
    },
    body: {
        paddingVertical: "15px",
        paddingBottom: 50,
        backgroundColor: 'cadetblue',
    },
    footer: {
    }
    
  });

export {BonLivraisonPDF}
