import React from 'react';
import { GET, POST, DELETE, UPDATE } from '../../../utils/AxiosRequest';

import {closestCenter, DndContext, PointerSensor, TouchSensor, useSensor, useSensors} from '@dnd-kit/core';
import {arrayMove, SortableContext, useSortable, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {CSS} from "@dnd-kit/utilities";
import { restrictToVerticalAxis, restrictToWindowEdges, } from '@dnd-kit/modifiers';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Paper, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';

import { Accordion, AccordionDetails, AccordionSummary } from '../../utils/Accordion';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import DragIndicatorTwoToneIcon from '@mui/icons-material/DragIndicatorTwoTone';
import { Scrollbars } from 'react-custom-scrollbars-2';

import Session from 'react-session-api';
import { OpenSnackbar } from '../../utils/Snackbar';
import { SEND_NOTIFICATION_SLACK } from '../../../utils/Slack';

export const TaskList = (props) => {
    const pepoleData = JSON.parse(Session.get('pepoleData'));
	const common = props.common;
    const [customTasks, setCustomTask] = React.useState([]);
    const refs = React.useRef([]);
    React.useEffect(() => {
        let isMounted = true;
        GetDataFromDB()
        .then(data => {
            if(isMounted){
                var tmp = []
                data.forEach(group => {
                    tmp.push({...group, data: (typeof(JSON.parse(group.data)) !== "string") ? JSON.parse(group.data) : []});
                })
				
                setCustomTask(tmp);
                refs.current = tmp;
            }
        })

        return _ => { 
            isMounted = false;
            return SaveTaskList();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

	function SaveTaskList(){
		var data = GetAllData();
		var params = [];
		data.forEach(group => {
			params.push({
				id: group.id,
				title: group.title,
				data: JSON.stringify(group.data),
			})
		})
		return POST("scheduler/updateCustomTask", params)
		.then( _ => OpenSnackbar(0, "La liste de tâches à bien été sauvegardée.", 2000))
	}
	
    function ReloadData(){
        return GetDataFromDB()
        .then(data => {
            var tmp = []
			data.forEach(group => {
				tmp.push({...group, data: (typeof(JSON.parse(group.data)) !== "string") ? JSON.parse(group.data) : []});
			})
			setCustomTask(tmp);
        })
    }

	function GetDataFromDB(){
		if(common){
			return GET("scheduler/getAllCustomTodoByPepoleId", {id: -1})
		} else {
			return GET("scheduler/getAllCustomTodoByPepoleId", {id: pepoleData.id})
		}
	}

    function GetAllData(){
        var data = [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
        refs.current.forEach(element => {
            data.push(element);
        })
        return data;  
    }

    function UpdateData(index, data){
        var group = refs.current.findIndex(element => data.id === element.id)
        if(group !== -1){
            refs.current[group] = data;
        }
        
    }

    function DeleteGroup(index){
        var data = refs.current;
        data.splice(index, 1);
        setCustomTask([...data]);
        refs.current = data;
    }

    return (
        <Stack direction='column' alignItems='center' sx={{width: '100%'}} spacing={3}>
			<Button variant='contained' color='success' sx={{marginTop: 3, paddingX: 10}} onClick={() => SaveTaskList()}>Sauvegarder</Button>
			<Scrollbars>
				<Grid gridAutoFlow container spacing={2} paddingX={4} paddingBottom={2} marginBottom={2}>
				{customTasks && customTasks.map((template, i) => (
					<TaskGroup key={template.id} group={template} update={UpdateData} delete={DeleteGroup} index={i}/>
				))}
				</Grid>
				<Stack direction="row" spacing={2} marginBottom={15} paddingX={4}>
					<AddNewGroup owner={(common) ? -1 : pepoleData.id} reload={ReloadData}/>
					{!common && <AddTaskToPepole owner={(common) ? -1 : pepoleData.id} reload={ReloadData}/>}
				</Stack>
			</Scrollbars>
			
        </Stack>
    )


}

//#region TREE
const TaskGroup = React.forwardRef((props, ref) => {
    const { group, index } = props;
    const [items, setItems] = React.useState((group.data) ? group.data : []);
    // eslint-disable-next-line no-unused-vars
    const [keys, setKeys] = React.useState([]);
    const [text, setText] = React.useState(group.title);
    const [expanded, setExpanded] = React.useState(true);
    const refs = React.useRef([]);

    React.useEffect(() => {
        UpdateKeys(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useImperativeHandle(ref, () => ({
        getItems : () => items,
    }))

    const sensors = useSensors(
        useSensor(PointerSensor, {
          activationConstraint: {
            distance: 8,
          },
        }),
        useSensor(TouchSensor)
    )

    function DeleteGroup(){
        return DELETE("scheduler/deleteCustomTask", {id: group.id})
        .then( _ => props.delete(index))
    }

    function UpdateKeys(array){
        var tmp = []
        array.forEach(item => {
            while(tmp.includes(item.id)){
                item.id++;
            }
            tmp.push(item.id);
        })
        setKeys(tmp);
        setItems(array)
    }

    return (
		<Grid item xs={12} sm={12} md={12} lg={12}>
			
			<Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} >
				<AccordionSummary sx={{height: '50px'}}>
					<Stack direction='row' spacing='2' width="100%" alignItems='center' justifyContent='space-between'>
						{group.editable === 0 && <Typography variant='body1'>{group.title}</Typography>}
						{group.editable === 1 && <TextField variant='standard' fullWidth value={text} onChange={(event) => UpdateText(event.target.value)}/> }
						
						{group.editable === 1 && <IconButton color='error' onClick={() => DeleteGroup()}>
							<DeleteTwoToneIcon/>
						</IconButton>}
					</Stack>
				</AccordionSummary>
				<AccordionDetails>
					<DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={HandleDragEnd} onDragStart={HandleDragStart} modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}>
						<SortableContext items={items} strategy={verticalListSortingStrategy} >
							{items && items.map((item, i) => (
								<SortableItem key={item.id} ref={(element) => refs.current[i] = element} item={item} editable={group.editable} update={UpdateItem} delete={DeleteItem}/>
							))}
						</SortableContext>
					</DndContext>
					{group.editable === 1 && <Stack direction="row" >
						<Button variant='contained' sx={{marginTop: 2}} onClick={() => AddLine()}>Ajouter une tâche</Button>
					</Stack>}
				</AccordionDetails>
			</Accordion>
		</Grid>
        
    )

    function AddLine(){
        var array = [...items, {id: items.length + 1, title: ""}];
        props.update(index, {...group, data: array})
        UpdateKeys(array)
    }

    function HandleDragStart(event){
        const { active } = event;
        var index = FindIndexOfElement(active.id);
        refs.current[index].startDrag();
    }

    function HandleDragEnd(event){
        const {active, over} = event;
        var index = FindIndexOfElement(active.id);
        refs.current[index].endDrag();

        if(active.id !== over.id){
            const activeIndex = items.findIndex(element => element.id === active.id);
            const overIndex = items.findIndex(element => element.id === over.id);
            var array = arrayMove(items, activeIndex, overIndex);
            setItems(array)
            props.update(index, {...group, data: array})
        }
    }

    function FindIndexOfElement(index){
        return refs.current.findIndex(element => element.getIndex() === index );
    }

    function UpdateItem(index, title){
        const i = FindIndexOfElement(index);
        var data = items;
        data[i].title = title;
        props.update(index, {...group, data});
    }

    function UpdateText(title){
        setText(title);
        props.update(index, {...group, title});
    }

    function DeleteItem(index){
        const i = FindIndexOfElement(index);
        var data = items;
        data.splice(i, 1);
        setItems([...data]);
        props.update(index, {...group, data});
    }
})

const SortableItem = React.forwardRef((props, ref) => {
    const { item } = props;
    const isDesktop = useMediaQuery('(min-width:1065px)');
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({id: item.id});
    const [grab, setGrab] = React.useState(false);
    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }
    const [text, setText] = React.useState(item.title);

    React.useImperativeHandle(ref, () => ({
        getIndex: () => { return item.id; },
        startDrag: () => setGrab(true),
        endDrag: () => setGrab(false),
    }))

    function ChangeText(event){
        setText(event.target.value);
        props.update(item.id, event.target.value);
    }
    
    return (
        <Paper elevation={(grab) ? 6 : 1} ref={setNodeRef} style={style} {...attributes} {...listeners} sx={{ paddingY: 0, marginY: 1, zIndex: (grab) ? 100000 : 0, touchAction: "none", marginX: (isDesktop) ? 0 : 2 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
				<DragIndicatorTwoToneIcon sx={{marginX: 3}}/>
				
                {props.editable === 1 && <TextField variant='standard' fullWidth value={text} onChange={ChangeText} InputProps={{classes: { input: {fontSize: '50em'}}}}/>}
                {props.editable === 0 && <Typography variant='body1' textAlign="left" width='100%'>{item.title}</Typography>}
                <IconButton color='error' onClick={() => props.delete(item.id)}>
                    <DeleteTwoToneIcon/>
                </IconButton>
            </Stack>
        </Paper>
    )
});
//#endregion

const AddNewGroup = (props) => {
    const theme = useTheme()
    const [open , setOpen] = React.useState(false);
    const [text, setText] = React.useState("");

    function HandleClose(save){
        if(save){
            return POST("scheduler/insertNewCustomTodo", {title: text, owner: props.owner, data: JSON.stringify([]), editable: 1})
            .then( _ => { return props.reload()})
            .then( _ => {
                setText("");
                setOpen(false);
            })
        }
        setText("");
        setOpen(false);
    }
    return (
        <div>
            <Button variant='contained' onClick={() => setOpen(true)}>Ajouter un nouveau groupe</Button>
            <Dialog maxWidth="md" open={open} onClose={() => HandleClose(false)}>
				<DialogTitle>
					<Typography fontSize='1.2em' color={theme.palette.text.primary}>Ajouter un nouveau groupe</Typography>
				</DialogTitle>
				<DialogContent>
					<Stack marginTop={2} spacing={2} direction="column">
                        <TextField variant='standard' label="Nom du groupe" value={text} sx={{fontSize: '2px'}}  onChange={(event) => setText(event.target.value)}/>  
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => HandleClose(true)} color="success">Ajouter</Button>
					<Button onClick={() => HandleClose(false)}>Retour</Button>
				</DialogActions>
			</Dialog>
        </div>
    )
}

const AddTaskToPepole = (props) => {
    const theme = useTheme()
	const pepoleData = JSON.parse(Session.get('pepoleData'));
    const [open , setOpen] = React.useState(false);
    const [text, setText] = React.useState("");
    const [selected, setSelected] = React.useState(0);
    const [pepoles, setPepoles] = React.useState([]);
    const [todos, setTodos] = React.useState([]);

    function HandleOpen(){
        Promise.all([
            GET("scheduler/getAllCustomTodoUneditable"),
            GET("scheduler/getAllEmployeData"),
        ]).then(data => {
            setTodos(data[0]);
            setPepoles(data[1]);
            setSelected(data[1][0].id);
            setOpen(true);
        })
    }

    function HandleClose(save){
        if(save){
            var todo = todos.find(element => element.owner === selected);
            if(todo){
                var data = JSON.parse(todo.data);
                data.push({id: data.length + 1, title: text});
                return UPDATE("scheduler/updateCustomTaskUnEditable", {id: todo.id, data: JSON.stringify(data)})
                .then( _ => { return props.reload})
                .then( _ => {
					OpenSnackbar(0, "La tâche '" + text + "' à bien été ajoutée", 2000);
                    setText("");
                    setOpen(false);
                })
				.then( _ => {
					var receiver = pepoles.find(element => element.id === todo.owner)
					var message = "(TaskList:364) <sender> t'a assigné un todo.";
					SEND_NOTIFICATION_SLACK(String(pepoleData.prenom).toLowerCase(), String(receiver.prenom).toLowerCase(), message, window.location.href)
				})
            } else {
                return POST("scheduler/insertNewCustomTodo", {title: "Tâche Donnée", owner: selected, data: JSON.stringify([{id: 1, title: text}]), editable: 0})
                .then( _ => { return props.reload})
                .then( _ => {
					OpenSnackbar(0, "La tâche '" + text + "' à bien été ajoutée", 2000);
                    setText("");
                    setOpen(false);
                })
				.then( _ => {
					var receiver = pepoles.find(element => element.id === todo.owner)
					var message = "(TaskList:377) <sender> t'a assigné un todo.";
					SEND_NOTIFICATION_SLACK(String(pepoleData.prenom).toLowerCase(), String(receiver.prenom).toLowerCase(), message, window.location.href)
				})
            }
        }
        setText("");
        setOpen(false);
    }

    return (
        <div>
            <Button variant='contained' onClick={() => HandleOpen()}>Ajouter une tâche à quelqu'un</Button>
            <Dialog maxWidth="md" open={open} onClose={() => HandleClose(false)}>
				<DialogTitle>
					<Typography fontSize='1.2em' color={theme.palette.text.primary}>Ajouter une tâche à quelqu'un</Typography>
				</DialogTitle>
				<DialogContent>
					<Stack marginTop={2} spacing={2} direction="column">
                        <TextField variant='standard' label="Nom de la tâche" value={text} onChange={(event) => setText(event.target.value)}/>  
                        <TextField variant='standard' select label="Personne" value={selected} onChange={(event) => setSelected(event.target.value)}>
                            {pepoles && pepoles.map(pepole => (
                                <MenuItem key={pepole.id} value={pepole.id}>{pepole.prenom + " " + pepole.nom}</MenuItem>
                            ))}
                        </TextField>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => HandleClose(true)} color="success">Ajouter</Button>
					<Button onClick={() => HandleClose(false)}>Retour</Button>
				</DialogActions>
			</Dialog>
        </div>
    )
}