import React from 'react';
import {Devis as DevisForm} from '../components/Devis/DevisComponent'
import { Navbar } from '../components/Navbar';
import { Stack } from '@mui/material';

// Page Devis
const Devis = () => {

    return (
        <Stack minWidth="100%" direction="row">
            <Navbar />
            <DevisForm></DevisForm>
        </Stack>
    );
};

export default Devis;