import React, {useImperativeHandle} from 'react';

import { GET } from '../../../utils/AxiosRequest';

import dayjs from 'dayjs';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Paper, useTheme } from '@mui/material';

require('dayjs/locale/fr');
dayjs.locale('fr');

export const DevisHeader = React.forwardRef((props, ref) => {
	const theme = useTheme();
	const [dateDevis, setDateDevis] = React.useState(dayjs());
	const [fournisseur, setFournisseur] = React.useState("");
	const [centreCout, setCentreCout] = React.useState("");
	const [contact, setContact] = React.useState("");
	const [reference, setReference] = React.useState("");
	const [mail, setMail] = React.useState("");
	const [client, setClient] = React.useState({});
	const [devisId, setDevisId] = React.useState();
	const [total, setTotal] = React.useState(0);
	const [dueDatesData, setDueDatesData] = React.useState([]);

	const changeFournisseur = (event) => setFournisseur(event.target.value);
	const changeCentreCout = (event) => setCentreCout(event.target.value);
	const changeContact = (event) => setContact(event.target.value);
	const changeReference = (event) => setReference(event.target.value);
	const changeMail = (event) => setMail(event.target.value);
	const changeTotal = (event) => setTotal(parseFloat(event.target.value.split(" ")[0]));

	useImperativeHandle(ref, () => ({
		updateClient: (client) => setClient(client),
		updateDueDatesData: (dueDatesData) => setDueDatesData(dueDatesData),
		updateDevisInfo: (info) => {
			setDevisId((info.id));
			setCentreCout((info.centreCout === null) ? '' : info.centreCout);
			setFournisseur((info.fournisseur === null) ? '' : info.fournisseur);
			setContact((info.contactClient === null) ? '' : info.contactClient);
			setMail((info.mailClient === null) ? '' : info.mailClient);
			setReference((info.reference === null) ? '' : info.reference);
			setDueDatesData((info.dueDatesData === null) ? [] : JSON.parse(info.dueDatesData));
		},
		updateDueDatesInfo: (info) => {
			setDueDatesData((info.dueDatesData === null) ? [] : JSON.parse(info.dueDatesData));
		},
		assignNewDevisId: () => {
			GET("devis/getNewDevisId")
			.then((data) => setDevisId(data[0].devisId + 1))
		},
		clearDevisInfo: () => {
			setCentreCout('');
			setFournisseur('');
			setContact('' );
			setMail('');
			setReference('');
			setClient({});
			setDueDatesData([]);
		},
		updateTotal: (total) => setTotal(parseFloat(total)),
		isClientSelected: () => client.id !== undefined,
		getHeaderData: () => {
			return { devisId, devisNom: "D_" + ("" + devisId).padStart(6,0), devisDate: ("" + dateDevis.$D).padStart(2,0) + '/' + ("" + (dateDevis.$M + 1)).padStart(2,0) + '/' + dateDevis.$y, clientId: client.id, clientInfo: client, fournisseur, centreCout, contact, reference, mailClient: mail, total, dueDatesData }
		}
	}))
	
	React.useEffect(() => {
		return GET("devis/getNewDevisId")
		.then((data) => setDevisId(data[0].devisId + 1))
	},[])

	return(
		<Paper elevation={2} sx={{width: "20%", height: "100%",overflow:"scroll", borderRadius: 0}}>
			<Stack paddingX={1} paddingTop={2} direction="column" spacing={1} height="100vh">
				<Card sx={{minHeight: 133}}>
					<CardContent sx={{paddingY: "1px"}}>
						<Typography variant='h6' color={theme.palette.text.primary}>INSPIRATION PRODUCTIONS</Typography>
						<Typography variant='body2' color={theme.palette.text.primary}>23 rue Arago</Typography>
						<Typography variant='body2' color={theme.palette.text.primary}>93400 SAINT-OUEN</Typography>
						<Typography variant='body2' color={theme.palette.text.primary}>01 80 89 30 50</Typography>
					</CardContent>
				</Card>
				<Card sx={{minHeight: 175}}>
					<CardContent sx={{paddingY: "1px"}}>
						<Typography sx={{marginBottom: 1}} variant='h6' color={theme.palette.text.primary}>DEVIS N° {("" + devisId).padStart(6,0)}</Typography>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker value={dateDevis} onChange={setDateDevis} renderInput={(params) => <TextField variant='standard' fullWidth {...params}/>} />	
						</LocalizationProvider>
						<TextField fullWidth label="Numéro fournisseur" variant="standard" value={fournisseur} onChange={changeFournisseur} />
						<TextField fullWidth label="Centre coût" variant="standard" value={centreCout} onChange={changeCentreCout} />
					</CardContent>
				</Card>
				<Card sx={{minHeight: 160}}>
					<CardContent sx={{paddingY: "1px"}}>
						{ client.nomEntite === undefined && <Typography variant='h6' color={theme.palette.text.primary}>Pas de client sélectionné</Typography> }
						{client.nomEntite !== undefined && 
							<div>
								<Typography variant='h6' color={theme.palette.text.primary}>{client.nomEntite}</Typography>
								<Typography variant='body2' color={theme.palette.text.primary}>{client.adresse1}</Typography>
								<Typography variant='body2' color={theme.palette.text.primary}>{client.adresse2}</Typography>
								<Typography variant='body2' color={theme.palette.text.primary}>{client.adresse3}</Typography>
							</div>
						}
					</CardContent>
				</Card>
				<Card sx={{minHeight: 190}}>
					<CardContent sx={{paddingY: "1px"}}>
						<Typography variant='body2' color={theme.palette.text.primary}>Votre interlocuteur : Aymeric GARDEY</Typography>
						<TextField fullWidth label="Contact Client" variant="standard" value={contact} onChange={changeContact} />
						<TextField fullWidth label="Mail Client" variant="standard" value={mail} onChange={changeMail} />
						<TextField fullWidth label="Référence" variant="standard" value={reference} onChange={changeReference} />
					</CardContent>
				</Card>
				<Card sx={{minHeight: 210}}>
					<CardContent sx={{paddingY: "1px"}}>
						<Typography variant='h6' color={theme.palette.text.primary}>Total</Typography>
						<TextField fullWidth  disabled InputProps={{endAdornment: <InputAdornment position="start">€ HT</InputAdornment>}}  variant="standard" value={parseFloat(total).toFixed(2)} onChange={changeTotal} />
					</CardContent>
				</Card>
			</Stack>
		</Paper>
	)
})