import React from 'react';
import { Document, StyleSheet, Page } from '@react-pdf/renderer'

import { Header } from './FactureHeader';
import { Body, IntermediaryDueDateBody } from './FactureBody';
import { Footer } from './FactureFooter'
import { Total, IntermediaryDueDateTotal } from './FactureTotal';

const FacturePDF = props => {

    return(
        
        (props.data.balanceInvoice) ? (
            <>
                <Document title={ "Facture " + props.data.nomFacture }>
                    <Page size='A4' style={styles.page} >
                        <Header data={props.data} fixed/>
                        <Body style={styles.body} data={props.data}/>
                        <Footer style={styles.footer} fixed/>
                    </Page>
                    <Page size='A4' style={styles.page} >
                        <Header data={props.data} fixed/>
                        <Total data={props.data} total={props.data.devis.total} accompte={props.data.devis.accompte} tva={20}/>
                        <Footer style={styles.footer} fixed/>
                    </Page>
                </Document>
            </>
            ) : (
            <>
                <Document title={ "Facture " + props.data.nomFacture }>
                    <Page size='A4' style={styles.page} >
                        <Header data={props.data} fixed/>
                        <IntermediaryDueDateBody style={styles.body} total={props.data.devis.total} data={props.data}/>
                        <IntermediaryDueDateTotal data={props.data} total={props.data.devis.total} accompte={props.data.devis.accompte} tva={20}/>
                        <Footer style={styles.footer} fixed/>
                    </Page>
                </Document>
            </>
            )
    )
}

const styles = StyleSheet.create({
    page: {
      paddingTop: 15,
      paddingBottom: "10%",
      paddingHorizontal: 15,
      fontSize: '10pt',
    },
    header: {
    },
    body: {
        paddingVertical: "15px",
        paddingBottom: 50,
        backgroundColor: 'cadetblue',
    },
    footer: {
    }
    
  });

export {FacturePDF}
