import React from 'react'
import { StyleSheet, View, Text, Image } from '@react-pdf/renderer'
import LogoInspiration from '../../../img/LogoInspiration.png'

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        height: "34%",
        maxHeight: "34%",
        marginBottom: "10px",
    },
    leftColumn: {
        flexDirection: 'column',
        width: "47.5%",
        paddingTop: 5,
        paddingRight: 15,
        marginRight: "2.5%"
    },
    rightColumn: {
        flexDirection: 'column',
        width: "47.5%",
        paddingTop: 5,
        paddingRight: 15,
        marginLeft: "2.5%"
    },
    borderH: {
        border: "1px solid black",
        width: '100%',
        textAlign: 'center',
        paddingVertical: "2px"
    },
    borderBH: {
        border: "1px solid black",
        borderTop: "0px solid black",
        width: '100%',
        textAlign: 'center',
        paddingVertical: "2px"
    },  
    borderB: {
        border: "1px solid black",
        borderTop: "0px solid black",
        width: '100%',
        textAlign: 'left',
        paddingVertical: "2px"
    },  
    topSpace: {
        marginTop: "15px"
    },
    interlocuteur: {
        marginTop: "10px",
    },
    contact: {
        marginTop: "15px",
    },
    image: {
        width: "70%",
        height: "30%",
        marginLeft: "25%",
        marginTop: "-5%",
        marginBottom: "5%",
    },
    nomEntite: {
        marginLeft: "15%",
        marginTop: "0px",
    },
    textRight: {
        marginLeft: "15%",
        marginTop: "30px",
    },
    textLeft: {
        marginLeft: "5%",
    }
  });

const Header = props => {
	console.log(props.data);
    var facture = props.data.facture;
    var client = props.data.client;
    return (
        <View style={styles.container} fixed>
            <View style={styles.leftColumn}>
                <View style={styles.textLeft}>
                    <Text>Inspiration Productions</Text>
                    <Text>23, rue Arago</Text>
                    <Text>93400 SAINT-OUEN</Text>
                    <Text>01 80 89 30 50</Text>
                </View>
                <View style={styles.topSpace}>
                    <Text style={styles.borderH}>AVOIR N°{props.data.nom}</Text>
                    <Text style={styles.borderBH}>{props.data.date}</Text>
                </View>
                <View style={styles.topSpace}> 
                    <Text style={styles.borderH}>Siège Social</Text>
                    <View style={styles.borderB}>
                        <Text>{client.raisonSociale}</Text>
                        <Text>{client.adressePersonne}</Text>
                        <Text>{client.codePostalPersonne} {client.villePersonne}</Text>
                        <Text>N° TVA intracom : {client.numeroTvaIntra}</Text>
                    </View>
                </View>
                <View style={styles.contact}>
                    <Text>Contact Client : {facture.contactClient}</Text>
                    <Text>Avoir sur la facture {props.data.nom}</Text>
                </View>
            </View>
            <View style={styles.rightColumn}>
                <Image src={LogoInspiration} style={styles.image}></Image>
                <Text style={styles.nomEntite}>{client.nomEntite}</Text>
                <View style={styles.textRight}>
                    <Text>{client.adresse1}</Text>
                    <Text>{client.adresse2}</Text>
                    <Text>{client.adresse3}</Text>
                    <Text>{client.codePostal} {client.ville} - {client.pays}</Text>
                </View>
                <View style={styles.textRight}>
                    <Text>N° Fournisseur : {facture.fournisseur}</Text>
                </View>
            </View>
            
        </View>
    );
}

export {Header}