import React from 'react';
import { Navbar } from '../components/Navbar';
import { Stack } from '@mui/material';
import { TasksList } from '../components/Taches/TasksList';

export const Tasks = (props) => {
    return (
		<Stack minWidth="100%" direction="row">
			<Navbar />
      		<TasksList common={false}/>
		</Stack>
    );
};
