import React, { useImperativeHandle } from 'react';
import { DELETE, GET, UPDATE } from '../../../utils/AxiosRequest'

import { BriefManager } from './Brief/Brief';
import { DatesManager } from './Dates/Dates';
import { TaskManager } from './Tasks/Tasks';

import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle'

import TextField from '@mui/material/TextField';

import Stack from '@mui/material/Stack';
import { Avatar, Badge, Box, MenuItem, Paper, Typography, Switch, useMediaQuery, useTheme } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import { ImportAllLogos } from '../../utils/Logos';
import { ModifyProjectDevis } from './ModifyProjectDevis';
import { useSearchParams } from 'react-router-dom';
import { SEND_NOTIFICATION_SLACK } from '../../../utils/Slack';
import Session from 'react-session-api';

require('dayjs/locale/fr');
dayjs.locale('fr');

const images = ImportAllLogos();

export const ProjectInformation = (props) => {
	const isDesktop = useMediaQuery('(min-width:1065px)');
	const theme = useTheme();
	const [queryParams] = useSearchParams();
	const projectId = queryParams.get("id");
	const [currentProject, setCurrentProject] = React.useState({});
	const [clientList, setClientList] = React.useState([]);
	const [pepoleList, setPepoleList] = React.useState([]);
	const [currentView, setCurrentView] = React.useState(0);
	const [probleme, setProbleme] = React.useState(false);

	const datesRef = React.useRef(null);
	const taskRef = React.useRef(null);
	const briefRef = React.useRef(null);

	const [briefsSum, setBriefsSum] = React.useState(0);

	React.useEffect(() => {
		LoadProject();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	function LoadProject() {
		var promises = [
			GET("projects/getProjectByID", {projectID: parseInt(projectId)}),
			GET("projects/getClientNom", {}),
			GET("projects/getDevisOfProject", { id: projectId }),
			GET("projects/getAllEmployeInfo", {}),
			GET("briefs/getBriefByProjectID", {projectID: projectId}),
		];
		return Promise.all(promises)
		.then(data => {
			setCurrentProject(data[0][0]);
			setClientList(data[1]);
			setPepoleList(data[3])
			if(data[2].length > 0){
				data[2].forEach(element => {
					if(element.valide !== 1){
						setProbleme("Le devis n'est pas valide");
					}
				})
				
			} else {
				setProbleme("Il n'y a pas de devis pour ce projet");
			}

			var briefs = data[4];
			setBriefsSum(briefs.length);
		})
	}


	async function OpenProjectFolder() {
		var dossier = currentProject.dossier;
		if(dossier !== null || dossier !== undefined || dossier !== ''){
			dossier += '\\';
			await window.folder.openFolder(dossier);
		} 
	}

	async function OpenExternal(link){
		await window.web.openInBrowser(link);
	}
	//#endregion

	function GetHidden(id) { return currentView !== id }
	function GetVimeoLink() {
		return (window.isOnWeb) ? ( <a href={currentProject.vimeo} target='_blank' rel="noopener noreferrer">LIEN VIMÉO</a> ) : ( <span style={{cursor: 'pointer', textDecoration: 'underline', fontWeight: 'bolder', fontSize: '1em'}} onClick={() => OpenExternal(currentProject.vimeo)}>LIEN VIMÉO</span> )
	}
	function GetState(){
		switch(currentProject.etat){
			case 0: return "En Cours";
			case 1: return "En Attente";
			case 2: return "Fini";
			default: return "Pas d'état";
		}
	}

	function UpdateTasksInDates(){
		datesRef.current.ReloadTasks();
	}

	function GetProjectLeaderText(){
		var pepole = pepoleList.find(element => element.id === currentProject.projectLeader);
		var str = '';
		if(pepole !== undefined){
			str = pepole.prenom + " " + pepole.nom;
		}
		return str;
	}


	if(isDesktop){
		return(
			<Box height='100vh' width='100%' overflow='auto'>
				<Stack direction='row' alignItems='center' sx={{width: '100%'}}>
					<Paper color={theme.palette.background.paper} elevation={5} sx={{width: '20%', minHeight: '100vh'}}>
						<Stack direction='column' alignItems='center' spacing={4} width='100%' marginTop={5}>
							<Avatar src={(images[currentProject.clientID + '.svg'] !== undefined) ? images[currentProject.clientID + '.svg'] : images['-1.svg']} sx={{ width: 72, height: 72 }} />
							<Stack direction='column' spacing={2}>
								<Typography variant='h5' textAlign='center' textTransform='uppercase' color={theme.palette.text.primary}>Information sur le projet</Typography>
								<Typography variant='body1' textAlign='center' textTransform='uppercase' color={theme.palette.text.primary}>{currentProject.nom}</Typography>
								<Typography variant='body1' textAlign='center' textTransform='uppercase' color={theme.palette.text.primary}>{currentProject.nomEntite}</Typography>
								<Typography variant='body1' textAlign='center' textTransform='uppercase' color={theme.palette.text.primary}>{GetVimeoLink()}</Typography>
								<Typography variant='body1' textAlign='center' textTransform='uppercase' color={theme.palette.text.primary}>État du projet : {GetState()}</Typography>
								<Typography variant='body1' textAlign='center' textTransform='uppercase' color={theme.palette.text.primary}>{GetProjectLeaderText()}</Typography>
							</Stack>
							<Stack direction='column' spacing={2} paddingX={10} width='100%'>
								{!window.isOnWeb && <Button fullWidth variant='contained' onClick={() => OpenProjectFolder()}>Ouvrir le dossier</Button>}
								<ModifyProjectInfo project={currentProject} clientList={clientList} loadProject={LoadProject} pepoleList={pepoleList}/>
								{probleme !== false && <Typography variant='body2' color="error" textAlign='center'>{probleme}</Typography>}
								<ModifyProjectDevis projectId={projectId} reload={LoadProject}/>
							</Stack>
						</Stack>
						<Stack direction='column' spacing={2} paddingX={10} marginTop={10} width='100%' >
							<UpdateProjectState id={projectId} state={0} name={"en cours"} btnText={"Passer le projet en cours"} loadProject={LoadProject}/>
							<UpdateProjectState id={projectId} state={1} name={"attente"} btnText={"Passer le projet en attente"}btnColor={"warning"} loadProject={LoadProject}/>
							<UpdateProjectState id={projectId} state={2} name={"fini"} btnText={"Finir le projet"} btnColor={"success"} loadProject={LoadProject}/>
							<DeleteProject projectId={projectId}/>
						</Stack>
					</Paper>
					<Box height='100vh' width='80%' overflow='auto'>
						<Tabs value={currentView} onChange={(event, value) => setCurrentView(value)} centered>
							<Tab style={{fontSize: "1.2em", margin: "20px 10px 0 10px", minWidth:"120px"}} label={<StyledBadge centered invisible={briefsSum <= 0} badgeContent={briefsSum} color="primary" ><span style={{fontSize: "1.2em"}}>Brief</span></StyledBadge>} value={1} />
							<Tab style={{fontSize: "1.2em", margin: "20px 10px 0 10px", minWidth:"120px"}} label="Tâches" value={0} />
							<Tab style={{fontSize: "1.2em", margin: "20px 10px 0 10px", minWidth:"120px"}} label="Dates" value={2} />
						</Tabs>
						<TaskManager ref={taskRef} parent={this} projectId={projectId} projectName={currentProject.nom} pepoleList={pepoleList} hidden={GetHidden} updateTasksInDates={UpdateTasksInDates} defaultOpen={queryParams.get('task')}/>
						<BriefManager ref={briefRef} parent={this} projectId={projectId} pepoleList={pepoleList} hidden={GetHidden}/>
						<DatesManager ref={datesRef} parent={this} projectId={projectId} hidden={GetHidden}/>
					</Box>
				</Stack>
			</Box>
		)
	} else {
		return(
			<Stack direction='row' alignItems='center' sx={{width: '100%'}}>
				<Box height='100vh' width='100%' overflow='auto'>
					<Tabs value={currentView} onChange={(event, value) => setCurrentView(value)} centered>
						<Tab label="Informations" sx={{fontSize: '0.7em'}} value={-1}/>
						<Tab label="Brief" sx={{fontSize: '0.7em'}} value={1}/>
						<Tab label="Tâches" style={{fontSize: '0.7em', padding: 0}} value={0}/>
						<Tab label="Dates" sx={{fontSize: '0.7em'}} value={2}/>
					</Tabs>
					<Paper color={theme.palette.background.paper} elevation={0} sx={{width: '100%', minHeight: '100vh'}} hidden={GetHidden(-1)}>
						<Stack direction='column' alignItems='center' spacing={4} width='100%' marginTop={5}>
							<Avatar src={(images[currentProject.clientID + '.svg'] !== undefined) ? images[currentProject.clientID + '.svg'] : images['-1.svg']} sx={{ width: 72, height: 72 }} />
							<Stack direction='column' spacing={2}>
								<Typography variant='h5' textAlign='center' textTransform='uppercase' color={theme.palette.text.primary}>Information sur le projet</Typography>
								<Typography variant='body1' textAlign='center' textTransform='uppercase' color={theme.palette.text.primary}>{currentProject.nom}</Typography>
								<Typography variant='body1' textAlign='center' textTransform='uppercase' color={theme.palette.text.primary}>{currentProject.nomEntite}</Typography>
								<Typography variant='body1' textAlign='center' textTransform='uppercase' color={theme.palette.text.primary}>{GetVimeoLink()}</Typography>
								<Typography variant='body1' textAlign='center' textTransform='uppercase' color={theme.palette.text.primary}>État du projet : {GetState()}</Typography>
							</Stack>
							<Stack direction='column' spacing={2} paddingX={10} width='100%'>
								{/* <Button fullWidth variant='contained' onClick={() => OpenProjectFolder()}>Ouvrir le dossier</Button> */}
								<ModifyProjectInfo project={currentProject} clientList={clientList} loadProject={LoadProject} pepoleList={pepoleList}/>
								{probleme !== false && <Typography variant='body2' color="error" textAlign='center'>{probleme}</Typography>}
								<ModifyProjectDevis projectId={projectId} reload={LoadProject}/>
							</Stack>
						</Stack>
						<Stack direction='column' spacing={2} paddingX={10} marginTop={10} width='100%' >
							<UpdateProjectState id={projectId} state={0} name={"en cours"} btnText={"Passer le projet en cours"} loadProject={LoadProject}/>
							<UpdateProjectState id={projectId} state={1} name={"attente"} btnText={"Passer le projet en attente"} btnColor={"warning"} loadProject={LoadProject}/>
							<UpdateProjectState id={projectId} state={2} name={"fini"} btnText={"Finir le projet"} btnColor={"success"} loadProject={LoadProject}/>
							<DeleteProject projectId={projectId}/>
						</Stack>
					</Paper>
					<TaskManager ref={taskRef} parent={this} projectId={projectId} projectName={currentProject.nom} pepoleList={pepoleList} hidden={GetHidden} updateTasksInDates={UpdateTasksInDates} defaultOpen={queryParams.get('task')}/>
					<BriefManager ref={briefRef} parent={this} projectId={projectId} pepoleList={pepoleList} hidden={GetHidden}/>
					<DatesManager ref={datesRef} parent={this} projectId={projectId} hidden={GetHidden}/>
				</Box>
			</Stack>
		)
	}
	
}

const DeleteProject = (props) => {
	const { projectId } = props;
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);

	

	function Delete(){
		Promise.all([
			DELETE("projects/deleteProject", {id: projectId}),
			DELETE("projects/deleteTaskFromProject", {projectId: projectId}),
			DELETE("projects/deleteSubTaskFromProject", {projectId: projectId}),
			DELETE("projects/deletePlanning", {idProjet: projectId}),
			DELETE("briefs/deleteAllBriefInfo", {idProjet: projectId}),
		]).then( _ => {
			window.open("/", "_self")
		})
	}

	function HandleClose(hasToDelete){
		if(hasToDelete){
			Delete();

			// Remove from favorite projects list if needed
			removeProjectFromFavoriteList(projectId);
			
		} else {
			setOpen(false);
		}
	}

	return (
		<div>
			<Button fullWidth variant='contained' color='error' onClick={() => setOpen(true)}>Supprimer le projet</Button>
			<Dialog fullWidth maxWidth="md" open={open} onClose={() => HandleClose(false)}>
				<DialogTitle color={theme.palette.text.primary}>Supprimer le projet</DialogTitle>
				<DialogContent>
					<Typography variant='body1' color={theme.palette.text.primary}>Voulez-vous supprimer ce projet ? </Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => HandleClose(true)} color="error">Supprimer</Button>
					<Button onClick={() => HandleClose(false)}>Retour</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

const StyledBadge = styled(Badge)(({ theme }) => ({
	'& .MuiBadge-badge': {
	  right: -16,
	  top: 12,
	  border: `2px solid ${theme.palette.background.paper}`,
	  padding: '0 5px',
	  alignSelf: 'center'
	},
}));

const ModifyProjectInfo = React.forwardRef((props, ref) => {
	const { project, clientList, pepoleList } = props;
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const [name, setName] = React.useState("");
	const [client, setClient] = React.useState(0);
	const [urgency, setUrgency] = React.useState(0);
	const [vimeo, setVimeo] = React.useState("");
	const [folder, setFolder] = React.useState("");
	const [deadline, setDeadline] = React.useState(dayjs());
	const [projectLeader, setProjectLeader] = React.useState(0);
	const [transverseProject, setTransverseProject] = React.useState();
	
	const changeName = (event) => setName(event.target.value);
	const changeClient = (event) => setClient(event.target.value);
	const changeVimeo = (event) => setVimeo(event.target.value);
	const changeFolder = (event) => setFolder(event.target.value);
	const changeDeadline = (date) => setDeadline(date);
	const changeUrgency = (event) => setUrgency(event.target.value);
	const changeProjectLeader = (event) => setProjectLeader(event.target.value);
	const changeTransverseProject = (value) => setTransverseProject(value);


	useImperativeHandle(ref, () => ({
	}))

	function HandleOpen(){
		setName(project.nom);
		setClient(project.clientID);
		setVimeo(project.vimeo);
		setFolder(project.dossier);
		setUrgency(project.urgency);
		setProjectLeader(project.projectLeader)
		setTransverseProject(project.transverse)
		setOpen(true);
	}

	const HandleClose = (save) => {
		if(save){
			var params = {
				nom: name,
				clientId: client,
				vimeo: vimeo,
				dossier: folder,
				deadline: GetStrFromDaysJS(deadline),
				urgency: urgency,
				projectLeader: projectLeader,
				transverseProject: transverseProject,
				projectId: project.id,
			}
			return UPDATE("projects/updateProjectInformation", params)
			.then(() => {
				props.loadProject();
				setOpen(false)
			}).then( _ => {
				if(project.projectLeader !== projectLeader){
					const pepoleData = JSON.parse(Session.get('pepoleData'));
					var sender = String(pepoleData.prenom).toLowerCase();
					var user = pepoleList.find(item => item.id === projectLeader);
					var message = "(ProjetctInfo:335) <sender> t'a désigné comme chef de projet pour " + name;
					var url = new URL(window.location.href.replace(window.location.search,''));
					url.searchParams.append('id', project.id);
					SEND_NOTIFICATION_SLACK(sender, String(user.prenom).toLowerCase(), message, url.href);
				}
			})
		}
		setOpen(false);
	}

	async function SelectFolderPath(){
		var res = await window.folder.selectFolder();
		setFolder((res.length > 0) ? res[0] + "\\" : "");
	}

	return (
		<div>
			<Button fullWidth variant='contained' onClick={() => HandleOpen()}>Modififer le projet</Button>
			<Dialog fullWidth maxWidth="md" open={open} onClose={() => HandleClose(false)}>
				<DialogTitle color={theme.palette.text.primary}>Modifier le projet</DialogTitle>
				<DialogContent>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<Stack marginTop={2} spacing={2} direction="column">
							<TextField label="Nom du projet" variant='standard' value={name} onChange={changeName}/>
							<TextField select label="Client" variant="standard" value={client} onChange={changeClient}>
								{clientList.map((cl, i) => (
									<MenuItem value={cl.id} key={cl.id}>{cl.nomEntite}</MenuItem>
								))}
							</TextField>
							<TextField label="Lien Viméo" variant='standard' value={vimeo} onChange={changeVimeo}/>
							{!window.isOnWeb && <Stack direction="row" spacing={2}>
								<TextField fullWidth disabled label="Lien du Dossier sur le serveur" variant='standard' value={folder} onChange={changeFolder}/>
								<Button variant='outlined' onClick={async () => await SelectFolderPath()}>Choisir un dossier</Button>
							</Stack>}
							<TextField variant='standard' label="Urgence du projet" select value={urgency} onChange={changeUrgency}>
								<MenuItem value={0}>Basse</MenuItem>
								<MenuItem value={1}>Moyenne</MenuItem>
								<MenuItem value={2}>Urgent</MenuItem>
							</TextField>
							<DatePicker label="Deadline du projet" value={deadline} onChange={changeDeadline} ampm={false} inputFormat="DD-MM-YYYY" renderInput={(params) => <TextField variant="standard" {...params} />} />
							<TextField select label="Chef de projet" variant="standard" value={projectLeader} onChange={changeProjectLeader}>
								{pepoleList.map(pepole => (
									<MenuItem value={pepole.id} key={pepole.id}>{pepole.prenom + " " + pepole.nom}</MenuItem>
								))}
							</TextField>
							<Stack spacing={2} direction="row">
								<Typography marginTop={2} style={{whiteSpace: "nowrap", marginTop: "7px"}} variant='body1' >Projet transverse</Typography>
								<Switch checked={transverseProject} onChange={(event) => changeTransverseProject(!transverseProject)}/>
							</Stack>
						</Stack>
					</LocalizationProvider>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => HandleClose(true)} color="success">Sauvegarder</Button>
					<Button onClick={() => HandleClose(false)}>Retour</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
})


const UpdateProjectState = (props) => {
	const { id, state, name, btnText, btnColor } = props;
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);

	function HandleClose(save){
		if(save){
			return UPDATE("projects/updateProjectState", {etat: state, id})
			.then( _ => { return props.loadProject(); })
			.then(() => {
				setOpen(false);
				// Remove from favorite projects list if needed
			if(state === 1 || state === 2){
				removeProjectFromFavoriteList(id);
			}
		})

			
		}
		setOpen(false);
	}

	return (
		<div>
			<Button fullWidth color={btnColor} variant='contained' onClick={ _ => setOpen(true) }>{btnText}</Button>
			<Dialog fullWidth maxWidth="md" open={open} onClose={() => HandleClose(false)}>
				<DialogTitle color={theme.palette.text.primary}>Passer ce projet en état : {name} ? </DialogTitle>
				<DialogContent>
					<Typography variant='body1' color={theme.palette.text.primary}>Voulez-vous passer ce projet en dans l'état : {name} ? </Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => HandleClose(true)} color="success">Valider</Button>
					<Button onClick={() => HandleClose(false)}>Retour</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

function GetStrFromDaysJS(date){
	return GetDigits(date.$D) + "-" + GetDigits(date.$M + 1) + "-" + GetDigits(date.$y);
}

function GetDigits(value){
	var str ='' + value;
	return str.padStart(2, '0');
}

const removeProjectFromFavoriteList = (id) => {
	var pepoleData = JSON.parse(Session.get('pepoleData'));
	var tmpFavoriteProjects = JSON.parse(pepoleData.favoriteProjects).slice();
	const index = tmpFavoriteProjects.indexOf(parseInt(id));
	if(index >= 0){
		if(tmpFavoriteProjects.length === 1){
			tmpFavoriteProjects = [-1];
		}
		else{
			tmpFavoriteProjects.splice(index, 1);
		}
		pepoleData.favoriteProjects = JSON.stringify(tmpFavoriteProjects);
		Session.set('pepoleData', JSON.stringify(pepoleData));
		UPDATE("login/updateFavoriteProjects", {favoriteProjects: JSON.stringify(tmpFavoriteProjects)});
	}
	
}