import * as React from "react";
import { DELETE, GET, POST, UPDATE } from "../../utils/AxiosRequest";

import { alpha, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Paper, Stack, TextField, Typography, useTheme } from "@mui/material";

import { Accordion, AccordionDetails, AccordionSummary } from '../utils/Accordion';

import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';

import { Scrollbars } from 'react-custom-scrollbars-2';
import { OpenSnackbar } from "../utils/Snackbar";

import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import DoNotDisturbOnTwoToneIcon from '@mui/icons-material/DoNotDisturbOnTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';


export function Checklist(props){
	const [checklists, setChecklists] = React.useState([]);
	const editCheckRef = React.useRef(null);

	React.useEffect( _ => {
		LoadData()
	},[])

	function LoadData(){
		return GET("checklist/getAllChecklists")
		.then(data => {
			setChecklists([...data]);
		})
	}

	function AddNewChecklist(name){
		var newChecklist = {nom: name, contenu: '[]'}
		return POST('checklist/insertNewChecklist', newChecklist)
		.then( _ => {
			setChecklists([...checklists, newChecklist]);
		})
	}

	function DeleteChecklist(id){
		console.log('delete = ' + id);
		
		var index = checklists.findIndex(element => element.id === id);

		if(index !== -1){
			return DELETE("checklist/deleteChecklist", {id})
			.then( _ => {
				var tmp = checklists;
				tmp.splice(index, 1);
				setChecklists([...tmp]);
			})
		} 
	}

	function OpenEditCheck(id, group, text){
		editCheckRef.current.handleOpen(id, group, text);
	}

	function UpdateChecklist(id, group, text){
		var index = checklists.findIndex(item => item.id === group);
		var contenu = JSON.parse(checklists[index].contenu);
		contenu[id] = text;
		return UPDATE("checklist/updateChecklist", {contenu: JSON.stringify(contenu), id: group})
		.then( _ => {
			var tmp = checklists;
			tmp[index] = {...tmp[index], contenu: JSON.stringify(contenu)}
			setChecklists([...tmp]);
		})
	}

	

	return (
		<Stack direction='column' alignItems='center' sx={{width: '100%'}} spacing={3}>
			<Scrollbars>
				<Button variant="contained" color="success" onClick={() => OpenSnackbar(0, 'Les process ont été enregistrés.', 2000)} sx={{marginX: 4, marginTop: 2}}>Sauvegarder</Button>
				<Grid gridAutoFlow container spacing={2} paddingX={4} paddingBottom={2} marginY={2}>
					{checklists.map(checklist => (
						<CheckGroup key={checklist.id} group={checklist} delete={DeleteChecklist} edit={OpenEditCheck}/>
					))}
				</Grid>
				<Stack direction="row" spacing={2} marginBottom={15} paddingX={4}>
					<AddNewGroup add={AddNewChecklist} name={"Ajouter une nouveau process"}/>
				</Stack>
			</Scrollbars>
			<EditCheck ref={editCheckRef} update={UpdateChecklist}/>
		</Stack>
	)
}

function CheckGroup(props){
	const theme = useTheme();
	const [expanded, setExpanded] = React.useState(false);
	const [group, setGroup] = React.useState(props.group);
	const [items, setItems] = React.useState((group.contenu !== '') ? JSON.parse(group.contenu) : []);

	React.useEffect( _ => {
		if(props.group){
			// if(props.group.id === 1)
			setGroup(props.group);
			setItems((props.group.contenu !== '') ? JSON.parse(props.group.contenu) : [])
		}
	}, [props])

	function AddNewCheck(text){
		var newItems = [...items, text];
		
		return UPDATE("checklist/updateChecklist", {contenu: JSON.stringify(newItems), id: group.id})
		.then(_ => {
			setItems(newItems);
			OpenSnackbar(0, "Le check a bien été ajouté", 2000);
		});
	}

	function DeleteItem(id){
		var tmp = items;
		tmp.splice(id, 1);
		
		return UPDATE("checklist/updateChecklist", {contenu: JSON.stringify(tmp), id: group.id})
		.then( _ => {
			setItems([...tmp])
			OpenSnackbar(0, "Le check a bien été supprimé", 2000)
		})
	}

	return (
		<Grid item xs={12} sm={12} md={12} lg={12}>
			<Accordion expanded={expanded} onChange={ _ => setExpanded(!expanded)}>
				<AccordionSummary sx={{height: '50px'}}>
					<Stack direction="row" width="100%" spacing={2} alignItems="center">
						<Typography width="100%" variant="h6" color={theme.palette.text.primary}>{group.nom}</Typography>
						<IconButton color="error" onClick={ _ => props.delete(group.id)} sx={{zIndex: 10000}}>
							<DeleteForeverTwoToneIcon />
						</IconButton>
					</Stack>
				</AccordionSummary>
				<AccordionDetails>
					<Stack spacing={2}>
						<Paper elevation={3} sx={{ paddingY: 0.5, paddingX: 4}}>
							<Stack spacing={1}>
								{items && items.map((item, i) => (
									<div key={i}>
										<CheckItem index={i} item={item} delete={DeleteItem} edit={props.edit} group={group.id}/>
										{i < items.length - 1 && <Divider component="div" sx={{borderColor: alpha(theme.palette.text.primary, 0.3)}} />}
									</div>
								))}
							</Stack>
						</Paper>
						<AddNewGroup add={AddNewCheck} name={'Ajouter un nouveau check'}/>
					</Stack>
				</AccordionDetails>
			</Accordion>
		</Grid>
	)
}

function CheckItem(props){
	const theme = useTheme();
	const [checked, setChecked] = React.useState(false);

	function GetCheckIcon(){
		if(checked) return <DoNotDisturbOnTwoToneIcon/>
		return <CheckCircleTwoToneIcon/>
	}

	return (
		<Stack direction="row" spacing={1} width='100%' justifyContent="space-between" alignItems="center">
			<Typography width="100%" variant="body1" color={theme.palette.text.primary} sx={{textDecoration: (checked) ? 'line-through 2px' : "none"}}>{props.item}</Typography>
			<IconButton color={(checked) ? "warning" : "success"} size="small" onClick={ _ => setChecked(!checked)}>
				{GetCheckIcon()}
			</IconButton>
			<IconButton color="info" size="small" onClick={ _ => props.edit(props.index, props.group, props.item)}>
				<EditTwoToneIcon/>
			</IconButton>
			<IconButton color='error' size="small" onClick={ _ => props.delete(props.index)}>
				<DeleteForeverTwoToneIcon/>
			</IconButton>
		</Stack>
	)
}

function AddNewGroup(props){
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const [text, setText] = React.useState('');

	function HandleClose(save){
		if(save){
			return props.add(text)
			.then( _ => {
				setOpen(false);
				setText("");
			})
		} else {
			setOpen(false)
			setText('');
		}
	}

	return (
        <div>
            <Button variant='contained' onClick={() => setOpen(true)}> {props.name}</Button>
            <Dialog maxWidth="md" open={open} onClose={() => HandleClose(false)}>
				<DialogTitle>
					<Typography fontSize='1.2em' color={theme.palette.text.primary}>{props.name}</Typography>
				</DialogTitle>
				<DialogContent>
					<Stack marginTop={2} spacing={2} direction="column">
                        <TextField variant='standard' label="Nom" value={text} onChange={(event) => setText(event.target.value)}/>  
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => HandleClose(true)} color="success">Ajouter</Button>
					<Button onClick={() => HandleClose(false)}>Retour</Button>
				</DialogActions>
			</Dialog>
        </div>
    )
}

const EditCheck = React.forwardRef((props, ref) => {
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const [text, setText] = React.useState('');
	const [id, setId] = React.useState(0);
	const [groupId, setGroupId] = React.useState(0);

	React.useImperativeHandle(ref, _ => ({
		handleOpen: (id, group, text) => {
			setId(id);
			setGroupId(group);
			setText(text);
			setOpen(true);
		}
	}))

	function HandleClose(save){
		if(save){
			return props.update(id, groupId, text)
			.then( _ => {
				setOpen(false);
				setText("");
				setId(0);
				setGroupId(0);
			})
		}
		setOpen(false);
		setText("");
		setId(0);
		setGroupId(0);
	}

	return(
		<Dialog maxWidth="xl" fullWidth open={open} onClose={() => HandleClose(false)}>
			<DialogTitle>
				<Typography fontSize='1.2em' color={theme.palette.text.primary}>Editer le check</Typography>
			</DialogTitle>
			<DialogContent>
				<Stack marginTop={2} spacing={2} direction="column">
					<TextField variant='standard' label="Check" value={text} onChange={(event) => setText(event.target.value)}/>  
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => HandleClose(true)} color="success">Modifier</Button>
				<Button onClick={() => HandleClose(false)}>Retour</Button>
			</DialogActions>
		</Dialog>
	)
})