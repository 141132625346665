import React from "react";
import { POST } from '../../utils/AxiosRequest'

import { PDFViewer } from "@react-pdf/renderer";
import { BonLivraisonPDF } from "./BonLivraisonPDF/BonLivraisonPdfGenerator";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle'
import { useImperativeHandle } from "react";
import { useTheme } from "@mui/material";

export const BonLivraisonViewer = React.forwardRef((props, ref) => {
	const theme = useTheme()
	const [open, setOpen] = React.useState(false);
	const [name, setName] = React.useState("");
	const [bonData, setBonData] = React.useState({});
	const [save, setSave] = React.useState(false);

	useImperativeHandle(ref, () => ({
		handleOpen: (bonId, devis, client, isAbleToSave) => {
			GenerateBonData(bonId, devis, client, isAbleToSave);
		}
	}));


	function HandleClose(){
		setOpen(false);
	}

	function GenerateBonData(bonId, devis, client, commande, isAbleToSave){
		if(devis === null || client === null) return;
		var nom = "BL_" + String(bonId).padStart(6, "0");
		var date = GetShortDate(new Date());
		var echeance = GetEcheanceDate(parseInt(client[0].echeance));
		var data = {
			id: bonId,
			clientData: client[0],
			bonData: devis,
			nomBon: nom,
			date: date,
			echeance: echeance,
			commande: commande,
		}
		setBonData(data);
		setSave(true);
		
		setName("Bon Livraison N°" + String(bonId).padStart(6, "0") + " - " + client[0].nomEntite + ".pdf")
		setOpen(true);
    }

	function SaveBon(){
		var params = {
			id: bonData.id,
            devisId: bonData.bonData.id,
			factureId: -1,
            nom: bonData.nomBon,
		}
		return POST("bonLivraison/insertNewBon", params)
		.then(setSave(false));
	}

	return (
		<Dialog fullWidth maxWidth="xl" open={open} onClose={HandleClose}>
			<DialogTitle color={theme.palette.text.primary}>Créer un bon de livraison</DialogTitle>
			<DialogContent>
				<Stack spacing={2} direction="column" alignItems='center'>
					<CopyToClipboard text={name} style={{ marginLeft: "10px" }}>
 						<Tooltip title={"Copier le nom du pdf : " + name}>
		 					<div style={{display: 'flex', flexDirection: 'column'}}>
		 						<p>Cliquer ici pour copier le nom du pdf</p>
		 						<IconButton>
		 							<ContentPasteIcon color="primary" fontSize="small" />
		 						</IconButton>
							</div>
	 					</Tooltip>
	 				</CopyToClipboard>
					<PDFViewer style={{minHeight:"750px", maxHeight: '1500px', width: '100%'}}>
						<BonLivraisonPDF data={bonData} />
					</PDFViewer>
				</Stack>
			</DialogContent>
			<DialogActions>
				{save && <Button variant="contained" color="success" onClick={() => SaveBon()}>Sauvegarder</Button>}
				<Button onClick={() => HandleClose()}>Fermer</Button>
			</DialogActions>
		</Dialog>
		
	)
})


function GetShortDate(date){
	const yyyy = date.getFullYear();
	let mm = date.getMonth() + 1; // Months start at 0!
	let dd = date.getDate();

	if (dd < 10) dd = "0" + dd;
	if (mm < 10) mm = "0" + mm;

	return dd + "/" + mm + "/" + yyyy;
};

function GetEcheanceDate(id){
	var echeance = new Date();
	switch(id){
		case 2: echeance.setDate(echeance.getDate() + 30);
			break;
		case 3: 
			echeance = new Date(echeance.getFullYear(), echeance.getMonth() + 1, 1);
			echeance.setDate(echeance.getDate() + 30);
			break;
		case 4: 
			echeance = new Date(echeance.getFullYear(), echeance.getMonth() + 1, 1);
			echeance.setDate(echeance.getDate() + 45);
			break;
		case 5: 
			echeance = new Date(echeance.getFullYear(), echeance.getMonth() + 1, 10);
			break;
		case 6: echeance.setDate(echeance.getDate() + 60);
			break;
		default: break;
	}
	return GetShortDate(echeance);
}
