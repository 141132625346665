import React from 'react';
import {GET, UPDATE } from '../../../utils/AxiosRequest';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, TextField, Typography, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';

export const ModifyProjectDevis = (props) => {
	const { projectId } = props;
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const [projectDevisList, setProjectDevisList] = React.useState([]);
	const [devisWithoutProjectList, setDevisWithoutProjectList] = React.useState([]);

	function HandleOpen(){
		return Promise.all([
			GetProjectDevis(projectId),
			GetProjectDevis(-1),
		]).then(data => {
			setProjectDevisList(data[0]);
			setDevisWithoutProjectList(data[1]);
			setOpen(true)
		})
		
	}

	function HandleClose(){
		return props.reload()
		.then(_ => {return setOpen(false)})
		
	}

	//#region AXIOS_REQUEST
	function GetProjectDevis(id){
		return GET("devis/getDevisByProjectId", {id})
	}

	function UpdateProjetDevis(devisId, remove){
		return UPDATE("devis/updateDevisProjet", {id: devisId, idProjet: (remove) ? -1 : projectId})
		.then( _ => {
			HandleOpen();
		})
	}

	function ValidateDevis(devisId){
		var devis = projectDevisList.find(element => element.id === devisId);
		if(devis && devis.valide === 0){
			return UPDATE("devis/updateDevisValide", {id: devisId})
			.then( _ => {
				HandleOpen();
			})
		}
		
	}

	//#endregion

	return (
		<div>
			<Button variant='contained' fullWidth onClick={() => HandleOpen()}>Voir les devis</Button>
			<Dialog maxWidth="xl" fullWidth open={open} onClose={HandleClose}>
				<DialogTitle color={theme.palette.text.primary}>Devis du projet</DialogTitle>
				<DialogContent>
					<Stack spacing={2} direction="column">
						<DevisGrid projectDevis={projectDevisList} setDevisList={setProjectDevisList} devisWithoutProject={devisWithoutProjectList} update={UpdateProjetDevis} validate={ValidateDevis}/>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => HandleClose(false)}>Retour</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

const DevisGrid = (props) => {
	const { devisWithoutProject } = props;

	const columns = [
		{ field: 'name', headerName: 'Nom', width: 150, renderCell: (params) => (
			<div style={{cursor:'pointer'}}	onClick={() => window.location.href = `/devis/?id=${params.row.id}`}>{ params.row.name }</div>
		)},
		{ field: 'reference', headerName: 'Reference', minWidth: 250, flex: 1 },
		{ field: 'client', headerName: 'Client', minWidth: 150, flex: 1 },
		{ field: 'commande', headerName: 'Numéro Commande', minWidth: 200, flex: 1, editable: true, renderCell: (params) => {
			if(params.row.commande === ""){
				return <Typography color="error" variant="body2">Pas de numéro de commande</Typography>
			}
		}},
		{ field: 'facture', headerName: 'Facture', minWidth: 150, flex: 1, renderCell: (params) => {
			if(params.row.commande === ""){
				return <Typography color="error" variant="body2">Pas de facture</Typography>
			}
		} },
		{ field: 'valide', headerName: 'Valide', width: 100, renderCell: (params) => (
			<Button onClick={() => props.validate(params.row.id)}>
				{params.row.valide === 0 && <ErrorTwoToneIcon color='error'/>}
				{params.row.valide === 1 && <CheckCircleTwoToneIcon color='success'/>}
			</Button>
		)  },
		{ field: 'action', headerName: 'Action', width: 200, renderCell: (params) => (
			<Button fullWidth color='error' onClick={() => props.update(params.row.id, true)}>Supprimer</Button>
		)},
	]

	const rows = [];
	if(props.projectDevis){
		props.projectDevis.forEach(devis => {
			rows.push({id: devis.id, name: devis.nom, commande: devis.numeroCommande, reference: devis.reference, client: devis.contactClient, facture: devis.nomFacture, valide: devis.valide})
		})
	}

	function HandleRowEditStart(params, event){
		event.defaultMuiPrevented = true;
	};
	
	function handleRowEditStop(params, event){
		event.defaultMuiPrevented = true;
	};

	function processRowUpdate(newRow){
		const updatedRow = { ...newRow };
		return UPDATE("devis/updateNumeroCommande", {numeroCommande: updatedRow.commande, id: updatedRow.id})
		.then(() => {
			props.setProjectDevis(props.projectDevis.map(row =>(row.id === updatedRow.id) ? updatedRow : row));
			return updatedRow;
		})
	};

	return (

		<Stack direction='column' spacing={2}>
			<div style={{height: "500px"}}>
				<DataGrid columns={columns} rows={rows} pageSize={5}
					experimentalFeatures={{ newEditingApi: true }}
					editMode='cell'
					onRowEditStart={HandleRowEditStart}
					onRowEditStop={handleRowEditStop}
					processRowUpdate={processRowUpdate}
				/>
			</div>
			<AddDevis devisWithoutProject={devisWithoutProject} update={props.update}/>
		</Stack>
	)
}

const AddDevis = (props) => {
	const { devisWithoutProject } = props;
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const [devisId, setDevisId] = React.useState(0);
	const ChangeDevisId = (event) => setDevisId(event.target.value);

	function HandleOpen(){
		if(devisWithoutProject){
			setDevisId(devisWithoutProject[0].id);
		}
		setOpen(true);
	}

	function HandleClose(save){
		if(save){
			if(devisId !== 0){
				props.update(devisId, false);
			}
		}
		setOpen(false)
	}
	
	return (
		<div>
			<Button variant='contained' onClick={() => HandleOpen()}>Ajouter un devis</Button>
			<Dialog maxWidth="md" fullWidth open={open} onClose={HandleClose}>
				<DialogTitle color={theme.palette.text.primary}>Modifier les devis</DialogTitle>
				<DialogContent>
					<TextField variant='standard' fullWidth select value={devisId} onChange={ChangeDevisId}>
						{devisWithoutProject && devisWithoutProject.map(devis => (
							<MenuItem key={devis.id} value={devis.id}> {devis.nom} | {devis.reference} | {devis.contactClient} </MenuItem>
						))}
					</TextField>
				</DialogContent>
				<DialogActions>
					<Button color='success' onClick={() => HandleClose(true)}>Ajouter</Button>
					<Button onClick={() => HandleClose(false)}>Annuler</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}