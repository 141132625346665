import React from 'react';

import classNames from "classnames";
import styles from "../ProjectHome.module.css";

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { Box, FormControl, InputLabel, Paper, Select, Stack, useMediaQuery } from '@mui/material';

import { ProjectCreation } from '../AddProjet/ProjectCreation';

import Session from 'react-session-api';


export const FilterBar = React.forwardRef((props, ref) => {
	// const theme = useTheme();
	const isDesktop = useMediaQuery('(min-width:1065px)');
	let pepoleData = JSON.parse(Session.get('pepoleData'));
	const { pepoleList, clientList, reload } = props;
	const [selectedPepole, setSelectedPepole] = React.useState(JSON.parse((pepoleData.pepoleFilter === "") ? "[-1]": pepoleData.pepoleFilter));
	const [selectedClient, setSelectedClient] = React.useState(-1);

	const changeSelectedPepole = (data) => {
		var array = [];
		if(data.length === 0 || data.at(-1) === -1){
			array = [-1];
		} else {
			var allIndex = data.findIndex(element => element === -1)
			if(allIndex !== -1){
				data.splice(allIndex, 1);
			}
			array = data;
		}

		props.updatePepoleFilter(array);
		setSelectedPepole(array)

		// Only for session storage below: Cancels multiselection if "Tout le monde" has been selected
		if(data.length >1 && data.includes(-1)){
			data = [-1];
		}
		// Save project selection (pepoleFilter select) in session storage - Firstly the pepoleData is reread in case it could have change in between
		pepoleData = JSON.parse(Session.get('pepoleData'));
		pepoleData.pepoleFilter = JSON.stringify(data);
		Session.set('pepoleData', JSON.stringify(pepoleData));
	};
	const changeSelectedClient = (event) => {
		props.updateClientFilter(event.target.value);
		setSelectedClient(event.target.value)
	};

	if(isDesktop){
		return (
			<Paper elevation={1}>
				<Stack direction='row' justifyContent={"space-between"} sx={{ px: 1.6 }}>
					<Stack  direction='row' spacing={4}  marginX={4} marginY={2} alignItems="center" sx={{overflowX: "auto"}}>
						<FormControl  style={{marginTop: 10}} sx={{ m: 1, width: 300 }}>
							<InputLabel>Projet de </InputLabel>
							<Select style={{marginTop: 6}} multiple variant='standard' textFieldProps={{label: 'Projet de'}} value={selectedPepole} onChange={(event) => changeSelectedPepole(event.target.value)} >
								<MenuItem key={-1} value={-1}>Tout le monde</MenuItem>
								<MenuItem key={-2} value={-2}>Non assigné</MenuItem>
								{pepoleList.map(pepole => (
									<MenuItem key={pepole.id} value={pepole.id}>{pepole.prenom + " " + pepole.nom}</MenuItem>
								))}
							</Select>
						</FormControl>
						<TextField className={classNames(styles.filterElement)} label="Client" variant='standard' select value={selectedClient} onChange={changeSelectedClient}>
							<MenuItem key={-1} value={-1}>Tout les clients</MenuItem>
							{clientList.map(client => (
								<MenuItem key={client.id} value={client.id}>{client.nomEntite}</MenuItem>
							))}
						</TextField>
					</Stack>
					<ProjectCreation clientList={clientList} pepoleList={pepoleList} reload={reload}/>
				</Stack>
			</Paper>
		)
	} else {
		return (
			<Box marginTop={2}>
				<Stack direction='column' spacing={0.5} alignItems='center'>

					<FormControl sx={{ width: 300 }} className={classNames(styles.filterElement)} >
						<InputLabel sx={{textAlign: "center", alignSelf: "center", height: "25px",}}>Projet de </InputLabel>
						<Select multiple variant='standard' label='Projet de' value={selectedPepole} onChange={(event) => changeSelectedPepole(event.target.value)} >
							<MenuItem key={-1} value={-1}>Tout le monde</MenuItem>
							<MenuItem key={-2} value={-2}>Non assigné</MenuItem>
							{pepoleList.map(pepole => (
								<MenuItem key={pepole.id} value={pepole.id}>{pepole.prenom + " " + pepole.nom}</MenuItem>
							))}
						</Select>
					</FormControl>
	
					<TextField className={classNames(styles.filterElement)} label="Client" variant='standard' select value={selectedClient} onChange={changeSelectedClient}>
						<MenuItem key={-1} value={-1}>Tout les clients</MenuItem>
						{clientList.map(client => (
							<MenuItem key={client.id} value={client.id}>{client.nomEntite}</MenuItem>
						))}
					</TextField>
					<ProjectCreation clientList={clientList} pepoleList={pepoleList} reload={reload}/>
				</Stack>
			</Box>
		)
	}
	
})