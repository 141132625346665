import React from 'react';
import { useParams } from 'react-router-dom'
//CSS
import '../Css/Projects.css';
import { ProjectInformation as Information } from '../components/Projets/Project_Information/ProjectInformation';
import { Navbar } from '../components/Navbar';
import { Stack } from '@mui/material';

const ProjectInformation = () => {
	const { id } = useParams();
  return (
    <Stack minWidth="100%" direction="row">
		<Navbar/>
      	<Information projectId={id}/>
	</Stack>
 
  );
};

export default ProjectInformation;
