/* eslint-disable array-callback-return */
import React from 'react'
import { StyleSheet, View, Text } from '@react-pdf/renderer'

const nbLinesPerPage = 26;

const styles = StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: 'column',
        
    },
    tableHeader: {
        width: "100%",
        display: "flex",
        flexDirection: 'row',
        justifyContent: "space-between",
        borderBottom: "1px black solid",
        paddingBottom: "10px"

    },
    tableEnd: {
        width: "100%",
        position: 'absolute',
        top: '480',
        display: "flex",
        flexDirection: 'row',
        justifyContent: "space-between",
        borderTop: "1px dashed black",
        paddingTop: "10px",
        marginTop: "10px"

    },
    tableBody: {
        width: "100%",
        display: "flex",
        flexDirection: 'column',
        justifyContent: "space-between",
         
    },
    designation: {
        width: '40%',
    },
    quantite: {
        width: '15%',
        textAlign: "right",
    },
    prix: {
        width: '20%',
        textAlign: "right",
    },
    total: {
        width: '20%',
        textAlign: "right",
    },
    
    line: {
        width: "100%",
        display: "flex",
        flexDirection: 'row',
        justifyContent: "space-between",
        paddingVertical: "3px"

    },
    lineST: {
        width: "100%",
        display: "flex",
        flexDirection: 'row',
        justifyContent: "space-between",
        borderTop: "1px dashed black",
        paddingVertical: '5px',
    },
    space: {
        padding: "10px",

    },
    dueDateInformation: {
        marginTop: '50px',
        marginBottom: '70px',
    },
    bonText:{
        fontSize: '10pt',
        textAlign: 'left',
    }
  });

const Body = props => {
    var factureData = props.data.devis;
    var body = JSON.parse(factureData.devisData);
    var sumPages = [];

    var j = -1;
    for (let i = 0; i < body.length; i++) {
        if(i % nbLinesPerPage === 0){
            if(j === -1) sumPages.push(parseFloat(0));
            else sumPages.push(sumPages[j]);
            j++;
        }

        if(body[i].total && body[i].total !== ''){
            sumPages[j] += parseFloat(body[i].total);
        }        
    }

    return (
        <View >
            <View fixed style={styles.tableHeader}>
                <Text style={styles.designation}>Désignation</Text>
                <Text style={styles.quantite}>Quantité</Text>
                <Text style={styles.prix}>Prix Unitaire</Text>
                <Text style={styles.total}>Total</Text>
            </View>  
            <View style={styles.tableBody}>
                <View style={styles.line}>
                    <Text style={styles.designation}></Text>
                    <Text style={styles.quantite}></Text>
                    <Text style={styles.prix}></Text>
                    <Text style={styles.total}></Text>
                </View>
                {body.map((line, i) => {
                    if(line.reference > 0 ||  line.reference === -2){
                        return (
                            <View style={(line.designation === "SOUS-TOTAL") ? styles.lineST : styles.line} key={i}>
                                <Text style={styles.designation}>{line.designation}</Text>
                                <Text style={styles.quantite}>{(line.quantity !== "") ? parseFloat(line.quantity).toFixed(2) : ""}</Text>
                                <Text style={styles.prix}>{(line.price !== "") ? parseFloat(line.price).toFixed(2) + '€' : ""}</Text>
                                <Text style={styles.total}>{(line.total !== "") ? parseFloat(line.total).toFixed(2) + '€' : ""}</Text>
                            </View>
                        )
                    } else if(String(line.reference) === "-3"){
                        return (
                            <View style={styles.space} key={i}>
                                <Text style={styles.designation}></Text>
                                <Text style={styles.quantite}></Text>
                                <Text style={styles.prix}></Text>
                                <Text style={styles.total}></Text>
                            </View>
                        )
                    } else if(line.reference === -4 || line.reference === '') {
                        return (
                            <View style={styles.line} key={i}>
                                <Text style={styles.designation}>{line.designation}</Text>
                                <Text style={styles.quantite}></Text>
                                <Text style={styles.prix}></Text>
                                <Text style={styles.total}></Text>
                            </View>
                        )
                    }
                })}
            </View>
            <View fixed style={styles.tableEnd}>
                <Text style={styles.designation}>SOUS-TOTAL</Text>
                <Text style={styles.quantite}></Text>
                <Text style={styles.prix}></Text>
                <Text style={styles.total} render={({pageNumber, totalPages}) => {
                    return sumPages[pageNumber - 1].toFixed(2).toString() + '€' 
                }}/>
            </View>  
        </View>
    );
}

const IntermediaryDueDateBody = props => {
    var devisData = props.data.devis;

    return (
        <View >
            <View fixed style={styles.tableHeader}>
                <Text style={styles.designation}>Désignation</Text>
                <Text style={styles.quantite}>Quantité</Text>
                <Text style={styles.prix}>Prix Unitaire</Text>
                <Text style={styles.total}>Total</Text>
            </View>  
            <View style={styles.tableBody}>
                <View style={styles.line}>
                    <Text style={styles.designation}></Text>
                    <Text style={styles.quantite}></Text>
                    <Text style={styles.prix}></Text>
                    <Text style={styles.total}></Text>
                </View>
                <View style={styles.space}>
                    <Text style={styles.designation}></Text>
                    <Text style={styles.quantite}></Text>
                    <Text style={styles.prix}></Text>
                    <Text style={styles.total}></Text>
                </View>
                <View style={styles.line}>
                    <Text style={styles.designation}>Acompte sur prestation de service selon le devis {devisData.nom}</Text>
                    <Text style={styles.quantite}></Text>
                    <Text style={styles.prix}></Text>
                    <Text style={styles.total}></Text>
                </View>
                <View style={styles.line}>
                    <Text style={styles.designation}>{devisData.dueDate.information} {devisData.dueDate.percentage}%</Text>
                    <Text style={styles.quantite}>{parseFloat(1).toFixed(2)}</Text>
                    <Text style={styles.prix}>{parseFloat(props.total * (devisData.dueDate.percentage / 100)).toFixed(2) + '€'}</Text>
                    <Text style={styles.total}>{parseFloat(props.total * (devisData.dueDate.percentage / 100)).toFixed(2) + '€'}</Text>
                </View>
            </View>  
            <View style={styles.dueDateInformation}>
                <Text style={styles.bonText}>Échéance : {devisData.dueDate.term}</Text>
                <Text style={styles.bonText}>Après règlement, reste à payer : {parseFloat(props.total -  props.data.alreadyPaidAmount - props.data.currentInvoiceTotal).toFixed(2) + '€ HT'}</Text>
            </View>
        </View>
    );
}

export {Body}
export {IntermediaryDueDateBody}