import React from 'react';

//CSS
import '../Css/Projects.css';
import { Administration as Home } from '../components/Administration/Administration';
import { Stack } from '@mui/material';
import { Navbar } from '../components/Navbar';

export const Administration = () => {
  return (
	<Stack minWidth="100%" direction="row">
		<Navbar/>
		<Home/>
	</Stack>
  );
};


