import React from "react";
import { GET, POST } from '../../utils/AxiosRequest'

import { PDFViewer } from "@react-pdf/renderer";
import { AvoirPDF } from "./AvoirPDF/AvoirPdfGenerator";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useImperativeHandle } from "react";

export const AvoirViewer = React.forwardRef((props, ref) => {
	const [open, setOpen] = React.useState(false);
	const [name, setName] = React.useState("");
	const [avoirData, setAvoirData] = React.useState({});
	const [save, setSave] = React.useState(false);

	useImperativeHandle(ref, () => ({
		handleOpen: (facture, montant, designation) => {
			Promise.all([
				GET("factures/getNewAvoirId"),
				GET("factures/getFactureById", {id: facture.id}),
				GET("factures/getClientById", {id: facture.clientId}),
				GET("factures/getAccompteByFactureId", {id: facture.id}),
				
			]).then(data => {
				var avoir = {
					id: data[0][0].avoirId + 1,
					nom: 'AV' + String(data[0][0].avoirId + 1).padStart(4,'0'),
					date: getShortDate(new Date()),
					montant: -montant,
					designation: designation,
					facture: data[1][0],
					client: data[2][0],
					accompte: data[3][0].accompte,
				}
				setAvoirData(avoir);
				setSave(true);
				setName("Avoir N°" + avoir.nom + " - " + avoir.client.nomEntite + ".pdf")
				setOpen(true);
			})
			
		}
	}))

	function HandleClose(){
		setOpen(false);
	}

	function SaveAvoir(){
		var params = {
			id: avoirData.id,
			nom: avoirData.nom,
			montant: avoirData.montant,
			designation: avoirData.designation,
			factureId: avoirData.facture.id,
			date: avoirData.date,
		}
		return POST("factures/insertNewAvoir", params)
		.then(setSave(false));

	}

	

	return (
		<Dialog fullWidth maxWidth="xl" open={open} onClose={HandleClose}>
			<DialogTitle>Changer un devis en facture</DialogTitle>
			<DialogContent>
				<Stack spacing={2} direction="column" alignItems='center'>
					<CopyToClipboard text={name} style={{ marginLeft: "10px" }}>
 						<Tooltip title={"Copier le nom du pdf : " + name}>
		 					<div style={{display: 'flex', flexDirection: 'column'}}>
		 						<p>Cliquer ici pour copier le nom du pdf</p>
		 						<IconButton>
		 							<ContentPasteIcon color="primary" fontSize="small" />
		 						</IconButton>
							</div>
	 					</Tooltip>
	 				</CopyToClipboard>
					<PDFViewer style={{minHeight:"750px", maxHeight: '1500px', width: '100%'}}>
						<AvoirPDF data={avoirData}></AvoirPDF>
					</PDFViewer>
				</Stack>
			</DialogContent>
			<DialogActions>
				{save && <Button variant="contained" color="success" onClick={() => SaveAvoir()}>Sauvegarder</Button>}
				<Button onClick={() => HandleClose()}>Fermer</Button>
			</DialogActions>
		</Dialog>
		
	)
})


function getShortDate(date){
	const yyyy = date.getFullYear();
	let mm = date.getMonth() + 1; // Months start at 0!
	let dd = date.getDate();

	if (dd < 10) dd = "0" + dd;
	if (mm < 10) mm = "0" + mm;

	return dd + "/" + mm + "/" + yyyy;
};

// function getEcheanceDate(id){
// 	var echeance = new Date();
// 	switch(id){
// 		case 2: echeance.setDate(echeance.getDate() + 30);
// 			break;
// 		case 3: 
// 			echeance = new Date(echeance.getFullYear(), echeance.getMonth() + 1, 1);
// 			echeance.setDate(echeance.getDate() + 30);
// 			break;
// 		case 4: 
// 			echeance = new Date(echeance.getFullYear(), echeance.getMonth() + 1, 1);
// 			echeance.setDate(echeance.getDate() + 45);
// 			break;
// 		case 5: 
// 			echeance = new Date(echeance.getFullYear(), echeance.getMonth() + 1, 10);
// 			break;
// 		case 6: echeance.setDate(echeance.getDate() + 60);
// 			break;
// 		default: break;
// 	}
// 	return getShortDate(echeance);
// }
