import { Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, styled, textFieldClasses, Button, useTheme } from '@mui/material';
import React from 'react';

export const ProjectCreationResume = React.forwardRef((props, ref) => {
	const { pepoleList, clientList, devisList } = props;
	const [data, setData] = React.useState({ brief: {}, information: {}, tasks: [], });
	const [client, setClient] = React.useState({id: -1});
	const [devis, setDevis] = React.useState({id: -1});
	const theme = useTheme();
	
	React.useImperativeHandle(ref, () => ({
		UpdateData: (project) => {
			setData(project);
			var clientTmp = clientList.find(element => element.id === project.information.client);
			setClient((clientTmp !== undefined) ? clientTmp : {id: -1});
			var devisTmp = devisList.find(element => element.id === project.information.devis);
			setDevis((devisTmp !== undefined) ? devisTmp : {id: -1});
		},
	}))


	function Change(element){}

	function GetPersonFromArray(array){
		var str = "";
		array.forEach(element => {
			var pepole = pepoleList.find(pep => pep.id === element);
			if(pepole !== undefined){
				str += pepole.prenom + ' ' + pepole.nom + ', '
			}
		});
		return str.slice(0,-2);
	}

	
	return (
		<Stack direction='column' spacing={2} hidden={props.hidden(3)}>
			<Paper elevation={4} sx={{padding: 5, marginTop: 2}}>
				<Typography mb={2} textAlign='center' variant="h6" color={theme.palette.text.primary}>Brief</Typography>
				<StyledTextfield fullWidth multiline label='Contenu du brief' rows={5} variant='standard' disabled value={(data.brief.brief === undefined) ? '' : data.brief.brief} onChange={(element) => Change()}/>
			</Paper>

			<Paper elevation={4} sx={{padding: 5, marginTop: 2}}>
				<Typography mb={2} textAlign='center' variant="h6" color={theme.palette.text.primary}>Information du projet</Typography>
				<Stack direction='column' spacing={2}>
					<Stack direction='row' spacing={2}>
						<StyledTextfield fullWidth variant='standard' label='Nom du projet' disabled value={(data.information.name === undefined) ? '' : data.information.name} onChange={(element) => Change()}/>
						<StyledTextfield fullWidth variant='standard' label='Lien viméo du projet' disabled value={(data.information.vimeo === undefined) ? '' : data.information.vimeo} onChange={(element) => Change()}/>
						<StyledTextfield fullWidth variant='standard' label='Chef de projet' disabled value={(data.information.projectLeader === '') ? 'Pas de chef de projet' : GetPersonFromArray([data.information.projectLeader])} onChange={(element) => Change()}/>
					</Stack>
					<Stack direction='row' spacing={2}>
						<StyledTextfield fullWidth label='Nom du client' variant='standard' disabled value={(data.information.clientName === undefined) ? '' : data.information.clientName} onChange={(element) => Change()}/>
						<StyledTextfield fullWidth variant='standard' label='Mail du client' disabled value={(data.information.clientMail === undefined) ? '' : data.information.clientMail} onChange={(element) => Change()}/>
					</Stack>
					<Stack direction='row' spacing={2}>
						<StyledTextfield fullWidth label='Entreprise du client' variant='standard' disabled value={(client.id === -1) ? '' : client.nomEntite} onChange={(element) => Change()}/>
						<StyledTextfield fullWidth label='Devis du projet' variant='standard' disabled value={(devis.id === -1) ? '' : devis.nom + " - " + devis.nomEntite + ' - ' + devis.contactClient} onChange={(element) => Change()}/>
					</Stack>
					<Stack direction='row' spacing={2}>
						<StyledTextfield fullWidth label='Entreprise du client' variant='standard' disabled value={(data.information.folder === undefined) ? '' : data.information.folder} onChange={(element) => Change()}/>
					</Stack>
				</Stack>
			</Paper>

			<Paper elevation={4} sx={{padding: 5, marginTop: 2}}>
				<Typography mb={2} textAlign='center' variant="h6" color={theme.palette.text.primary}>Tâches</Typography>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell sx={{textTransform: 'uppercase', width: '25%'}}>Nom Tâche</TableCell>
							<TableCell sx={{textTransform: 'uppercase', width: '25%'}}>Nom Sous-Tâche</TableCell>
							<TableCell sx={{textTransform: 'uppercase', width: '50%'}}>Personnes</TableCell>
						</TableRow>
					</TableHead>
					{data.tasks.map((task, i) => (
						<TableBody key={i}>
							{task.subtasks !== undefined && task.subtasks.map((element, j) => (
								<TableRow key={j}>
									<TableCell>{task.task.nom}</TableCell>
									<TableCell>{(element.subtask.nom === 'Autre') ? element.name : element.subtask.nom}</TableCell>
									<TableCell>{GetPersonFromArray(element.pepoles)}</TableCell>
								</TableRow>
							))}
						</TableBody>
					))}
				</Table>
			</Paper>

			<Button variant='contained' color='success' onClick={() => props.create()} disabled={data.tasks.length === 0 && !data.information.transverseProject}>Ajouter le projet</Button>
		</Stack>
	)
});

const StyledTextfield = styled(TextField)(({ theme }) => ({
	[`&.${textFieldClasses.root} .Mui-disabled`]: {
		color: theme.palette.text.primary,
		WebkitTextFillColor: theme.palette.text.primary
	},
}));
