import React from 'react';

import classNames from "classnames";
import styles from "../ProjectHome.module.css";

import { Box, FormControl, InputLabel, Paper, Select, Stack, useMediaQuery, MenuItem, TextField } from '@mui/material';

import Session from 'react-session-api';

export const FilterBarUrgent = React.forwardRef((props, ref) => {
	const { taskList, pepoleList } = props;
	const isDesktop = useMediaQuery('(min-width:1065px)');
	const pepoleData = JSON.parse(Session.get('pepoleData'));
	const [selectedTask, setSelectedTask] = React.useState((pepoleData.taskFilter === "") ? [-1] : JSON.parse(pepoleData.taskFilter));
	const [selectedPepole, setSelectedPepole] = React.useState(JSON.parse((pepoleData.pepoleFilter === "") ? "[-1]": pepoleData.pepoleFilter));

	const ChangeSelectedPepole = (data) => {
		var array = [];
		if(data.length === 0 || data.at(-1) === -1){
			array = [-1];
		} else {
			var allIndex = data.findIndex(element => element === -1)
			if(allIndex !== -1){
				data.splice(allIndex, 1);
			}
			array = data;
		}
		props.updatePepoleFilter(array);
		setSelectedPepole(array);
	}

	function ChangeSelectedTask(data){
		var array = [];
		if(data.length === 0) {
			array = [-1];
		} else {
			var allIndex = data.indexOf(-1);
			if(allIndex !== -1){
				if(data.length > 1)
					data.splice(allIndex, 1);
					array = data;
			} else {
				array = data;
			}
		}
		props.updateTaskFilter(array);
		setSelectedTask(array);
	}

	if(isDesktop){
		return (
			<Paper elevation={1}>
				<Stack  direction='row' spacing={4}  marginX={4} marginY={2} justifyContent="space-between" sx={{overflowX: 'none'}}>
					<FormControl variant="standard" sx={{ m: 0, width: '45%' }}>
						<InputLabel>Type de tâche</InputLabel>
						<Select label="Type de tâche" multiple value={selectedTask} onChange={(event) => ChangeSelectedTask(event.target.value)}>
							<MenuItem key={-1} value={-1}>Tous les types</MenuItem>
							{taskList.map(task => (
								<MenuItem key={task.id} value={task.id}>{task.nom}</MenuItem>
							))}
						</Select>
					</FormControl>	
					<FormControl sx={{ m: 0, width: '45%' }}>
						<InputLabel>Projet de </InputLabel>
						<Select multiple variant='standard' label='Projet de' value={selectedPepole} onChange={(event) => ChangeSelectedPepole(event.target.value)} >
							<MenuItem key={-1} value={-1}>Tout le monde</MenuItem>
							<MenuItem key={-2} value={-2}>Non assigné</MenuItem>
							{pepoleList.map(pepole => (
								<MenuItem key={pepole.id} value={pepole.id}>{pepole.prenom + " " + pepole.nom}</MenuItem>
							))}
						</Select>
					</FormControl>
				</Stack>
			</Paper>
		)
	} else {
		return (
			<Box marginTop={2}>
				<Stack  direction='column'  marginX='10%' marginY={2} spacing={2} alignItems='center' width='80%'>
				<FormControl variant="standard" sx={{ m: 0, minWidth: 120 }}>
						<InputLabel>Type de tâche</InputLabel>
						<Select className={classNames(styles.filterElement)} label="Type de tâche" multiple value={selectedTask} onChange={(event) => ChangeSelectedTask(event.target.value)}>
							<MenuItem key={-1} value={-1}>Tous les types</MenuItem>
							{taskList.map(task => (
								<MenuItem key={task.id} value={task.id}>{task.nom}</MenuItem>
							))}
						</Select>
					</FormControl>	
					<TextField className={classNames(styles.filterElement)} label="Projet de" variant='standard' select value={selectedPepole} onChange={ChangeSelectedPepole}>
						<MenuItem key={-1} value={-1}>Tout le monde</MenuItem>
						<MenuItem key={-2} value={-2}>Non assigné</MenuItem>
						{pepoleList.map(pepole => (
							<MenuItem key={pepole.id} value={pepole.id}>{pepole.prenom + " " + pepole.nom}</MenuItem>
						))}
					</TextField>
				</Stack>
			</Box>
		)
	}
})