import React from "react";
import { GET, UPDATE } from '../../../utils/AxiosRequest'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, TextField, useTheme } from "@mui/material";

export const AddNumeroCommande = (props) => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [devisList, setDevisList] = React.useState([]);
    const [selectedDevis, setSelectedDevis] = React.useState(0);
    const [numeroCommande, setNumeroCommande] = React.useState("");

    function ChangeSelectedDevis(event) {
        setSelectedDevis(event.target.value);
        var devis = devisList.find(element => element.id === event.target.value);
        setNumeroCommande((devis.numeroCommande) ? devis.numeroCommande : "");
    }

    function HandleOpen(){
        return GetAllDevisFromDb()
        .then(data => {
            setDevisList(data)
            setSelectedDevis(data[0].id);
            setNumeroCommande(data[0].numeroCommande);
            setOpen(true);
        })
    }

    function HandleClose(save){
        setOpen(false);
        if(save){
            return UpdateNumeroCommde()
            .then( _ => setNumeroCommande(""))
        }
        setNumeroCommande("");
    }

    //#region AXIOS REQUEST
    function GetAllDevisFromDb() { return GET("factures/getAllDevis") }
    function UpdateNumeroCommde(id) { return UPDATE("devis/updateNumeroCommande", {numeroCommande, id: selectedDevis}) }
    //#endregion
    
    return (
        <div>
            <Button fullWidth variant="contained" onClick={() => HandleOpen()} >Ajouter un numéro de commande à un devis</Button>
            <Dialog fullWidth maxWidth="md" open={open} onClose={() => HandleClose(false)}>
				<DialogTitle color={theme.palette.text.primary}>Ajouter un numéro de commande à un devis</DialogTitle>
				<DialogContent>
					<Stack spacing={2} direction="column">
                        <TextField select variant="standard" label="Devis pour le bon de livraison" value={selectedDevis} onChange={ChangeSelectedDevis}>
                            {devisList && devisList.map(devis => (
                                <MenuItem key={devis.id} value={devis.id}>{devis.nom + " - " + devis.nomEntite}</MenuItem>
                            ))}
                        </TextField>
                        <TextField variant="standard" label="Numéro de commande" value={numeroCommande} onChange={(event) => setNumeroCommande(event.target.value)} />
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => HandleClose(true)} color="success">Ajouter le numéro de commande</Button>
					<Button onClick={() => HandleClose(false)}>Retour</Button>
				</DialogActions>
			</Dialog>
        </div>
    )
}