import React from 'react';
import { GET } from '../../../../utils/AxiosRequest'; 

import classNames from "classnames";
import styles from "../ProjectHome.module.css";


import { alpha, Stack, Typography, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';

import { Scrollbars } from 'react-custom-scrollbars-2';

import dayjs from 'dayjs';
import { createSearchParams, useNavigate } from 'react-router-dom';

import Session from 'react-session-api';

require('dayjs/locale/fr')
dayjs.locale('fr')

// const MIN_DAYS_TO_URGENT = 3

export const UrgentProject = React.forwardRef((props, ref) => {
	const { taskTemplate, pepoleList } = props;
	const isDesktop = useMediaQuery('(min-width:1065px)');
	const pepoleData = JSON.parse(Session.get('pepoleData'));
	const transparency = (parseInt(pepoleData.transparency) === 1) ? true : false;
	const [tasks, setTasks] = React.useState([]);
	const [filters, setFilters] = React.useState({
		type: (pepoleData.taskFilter === "") ? [-1] : JSON.parse(pepoleData.taskFilter), 
		pepole: JSON.parse((pepoleData.pepoleFilter === "") ? "[-1]": pepoleData.pepoleFilter),
	});

	const navigate = useNavigate();

	React.useImperativeHandle(ref, () => ({
		updateType: (type) => setFilters({...filters, type}),
		updatePepole: (pepole) => setFilters({...filters, pepole})
	}))

	React.useEffect(() => {
		return GET("projects/getPlanningWithTask")
		.then(data => {
			setTasks(data.sort((a, b) => new Date(a.dateDebut) - new Date(b.dateDebut)));
		})
	}, [])

	function IsTaskHidden(task){
		var hidden = true;
		if(filters.type.includes(-1)) hidden = false;
		if(filters.type.includes(task.idTache)) hidden = false;
		if(!hidden) hidden = IsPepoleNotInTask(task);
		return hidden;
	}

	function IsPepoleNotInTask(task){
		var taskPepole = JSON.parse((task.employe && task.employe !== '') ? task.employe : '[]');
		if(filters.pepole.includes(-1)) return false;
		// if(filters.pepole.length === 1 && filters.pepole[0] === -2){
		// 	return taskPepole.length !== 0;
		// }

		if(filters.pepole.includes(-2) ){
			if(taskPepole.some((element) => filters.pepole.includes(element))) return false;
			return taskPepole.length !== 0
		}
		
		if(taskPepole.some((element) => filters.pepole.includes(element))) return false;


		return true;

		// if(filters.pepole.includes(-1)) return false;
		// if(filters.pepole.includes(-2) && filters.pepole.length === 1){
		// 	return taskPepole.length !== 0;
		// }
		// if(taskPepole.includes(filters.pepole)) return false;

		// if(filters.pepole === -1) return false;
		// if(filters.pepole === -2){
		// 	return taskPepole.length !== 0;
		// }
		// if(taskPepole.some((element) => filters.pepole.includes(element))) return false;
		// return true;
	}

	function GetColor(id){
		var index = taskTemplate.findIndex(element => element.id === id);
		return (index !== -1) ? taskTemplate[index].color : "#641e16";
	}

	function GetName(pepoles){
		var tmp = JSON.parse(pepoles);
		var str = "";
		tmp.forEach(pepole => {
			var index = pepoleList.findIndex(element => element.id === pepole);
			if(index !== -1){
				str += pepoleList[index].prenom + ", "
			}
		})
		return str.substring(0,str.length-2);
	}

	function NavigateToProject(task){
		var params = {
			id: task.idProjet,
			task: task.idTache,
		}
		navigate({
			pathname: `/projetInformation/`,
			search: `?${createSearchParams(params)}`
		});
	}

	function GetList(){
		return (
			<Grid gridAutoFlow container spacing={2} padding={4} paddingBottom={0} marginBottom={15}>
				{tasks.map((data, i) => {
					return (
						<Grid key={i} item xs={12} sm={12} md={12} lg={12} hidden={IsTaskHidden(data)}>
							<Card sx={{minHeight: 25}} >
								<CardActionArea onClick={() => NavigateToProject(data)}>
									<CardContent sx={
										(transparency) ? {
											borderLeft: "5px solid",
											borderLeftColor: GetColor(data.idTache),
											"&:hover": {
												backgroundColor: alpha(GetColor(data.idTache), 0.2)
											}
										} : {
											backgroundColor: GetColor(data.idTache),
											paddingY: 0.5,
										}
									}>
										<Stack>
											<Stack direction={{xs: 'column', sm: 'column', md:'column', lg:'row'}} justifyContent='space-between'>
												<Typography gutterBottom variant="button" component="div" margin={0}>{(data.nom === "Autre") ? data.nomProjet + " - " + data.texte : data.nomProjet + " - " + data.nom}</Typography>
												<Typography gutterBottom variant="body2" component="div">{GetStrFromDaysJS(dayjs(data.dateDebut))}</Typography>
											</Stack>
											<Typography gutterBottom variant="body2" component="div">{GetName(data.employe)}</Typography>
										</Stack>
									</CardContent>
								</CardActionArea>
							</Card>
						</Grid>
					)
				})}
			</Grid>
		)
	}

	if(isDesktop){
		return (
			<Box className={classNames(styles.urgentDates)}>
				<Scrollbars style={{height: "90%"}}>
					{GetList()}
				</Scrollbars>
			</Box>
		)
	} else {
		return (
			<Box className={classNames(styles.urgentDates)} paddingBottom={10}>
				<Grid gridAutoFlow container spacing={2} padding={4} paddingBottom={0} marginBottom={15}>
					{GetList()}
				</Grid>
			</Box>
		)
	}
})



function GetStrFromDaysJS(date){
	return GetDigits(date.$D) + "-" + GetDigits(date.$M + 1) + "-" + GetDigits(date.$y) + " | " + GetDigits(date.$H) + ':' + GetDigits(date.$m) ;
}

function GetDigits(value){
	var str ='' + value;
	return str.padStart(2, '0');
}