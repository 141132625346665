import React from 'react'
import { StyleSheet, View, Text } from '@react-pdf/renderer'

const styles = StyleSheet.create({
    container: {
        width: "100%",
    },
    droit: {
        paddingBottom: '5px',
        borderBottom: '1px solid black',
        marginTop: '20px',
    },
    text: {
        fontSize: '8.5pt',
    },
    reglement: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    modalite: {
        width: '35%',
        paddingTop: '20px',
    },
    label: {
        width: '40%',
        paddingTop: '15px',
        textAlign: 'right',
    },
    prix: {
        width: '20%',
        paddingTop: '15px',
        textAlign: 'right',
    },
    textPrix: {
        marginBottom: '5px',
    },
    bonAccord:{
        width: '60%',
        marginTop: '20px',
    },
    bonTitre:{
        marginTop: '10px',
        fontSize: '11pt',
    },
    bonText:{
        fontSize: '10pt',
        textAlign: 'left',
    },
    bonFoot:{
        fontSize: '7pt',
        fontStyle: 'oblique',
        textAlign: 'center',
        marginTop: '20px',

    },
    textRegle: {
        fontSize: '10pt',
    }

})

const Total = props => {
	console.log(props.data);
    var totalHT = parseFloat(props.data.montant);
    var tva = parseFloat(totalHT * (parseFloat(props.tva) / 100));
    var totalTTC = totalHT + tva;
    var accompte = totalTTC * (props.accompte / 100);
    var net = totalTTC - accompte;
    return (
        <View style={styles.container}>
            <View style={styles.droit}>
                <Text style={styles.text}>Droit du réalisateur et du photographe :</Text>
                <Text style={styles.text}>Le budget de production inclus :</Text>
                <Text style={styles.text}>- La cession des droits de propriété intellectuelle (reproduction/ représentation/ adaptation) sur tout support; pour la durée de la protection de la propriété intellectuelle, pour le Monde entier.</Text>
                <Text style={styles.text}>- La cession de tous les documents supports de la Création (photographie, rushs, maquettes, négatifs, masters...)</Text>
                <Text style={styles.text}>- La cession des droits de propriété intellectuelle (reproduction/ représentation/ adaptation) pour les captures d'écran, pour les utilisations suivantes : PR, POS/Duratrans, Petites éditions, Multimédia* et CRM**, pour le Monde entier.</Text>
            </View>
            <View style={styles.reglement}>
                <View style={styles.modalite}>
                    <Text style={styles.textRegle}>En votre aimable règlement</Text>
                    <Text style={styles.textRegle}>Titulaire du compte : Inspiration Productions</Text>
                    <Text style={styles.textRegle}>RIB : 30003 02841 00020007582 92</Text>
                    <Text style={styles.textRegle}>Domiciliation : SG NEUILLY ESPACE PRO (02841)</Text>
                    <Text style={styles.textRegle}>IBAN : FR76 3000 3028 4100 0200 0758 292</Text>
                    <Text style={styles.textRegle}>BIC : SOGEFRPP</Text>
                </View>
                <View style={styles.label}>
                    <Text style={styles.textPrix}>Accompte de 0% versé</Text>
                    <Text style={styles.textPrix}>Total HT</Text>
                    <Text style={styles.textPrix}>TVA à 20%</Text>
                    <Text style={styles.textPrix}>Total TTC</Text>
                    <Text style={styles.textPrix}>Net à payer</Text>
                    
                </View>
                <View style={styles.prix}>
                    <Text style={styles.textPrix}>{parseFloat(accompte).toFixed(2)}€</Text>
                    <Text style={styles.textPrix}>{parseFloat(totalHT).toFixed(2)}€</Text>
                    <Text style={styles.textPrix}>{parseFloat(tva).toFixed(2)}€</Text>
                    <Text style={styles.textPrix}>{parseFloat(totalTTC).toFixed(2)}€</Text>
                    <Text style={styles.textPrix}>{net.toFixed(2)}€</Text>
                    
                </View>
            </View>
            <View style={styles.bonAccord}>
				<Text style={styles.bonText}>Pas d'escompte en cas de paiement anticipé.</Text>
				<Text style={styles.bonText}>En cas de retard de paiement, il sera appliqué une pénalité de 1,5 %</Text>
				<Text style={styles.bonText}>par mois de retard (loi du 31/12/92 et du 29/01/93)</Text>
			</View>
        </View>
    )
}

export {Total}