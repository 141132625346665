import React from "react";
import { GET, POST, UPDATE } from '../../../utils/AxiosRequest'

import { DataGrid } from "@mui/x-data-grid";

import ModeEditOutlineTwoToneIcon from '@mui/icons-material/ModeEditOutlineTwoTone';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Paper, TextField, Typography, Stack } from "@mui/material";


export const ClientView = (props) => {
    const [rows, setRows] = React.useState([]);
    const editRef = React.useRef(null);
    const addRef = React.useRef(null);

    const columns = [
        {field: 'edit', headerName: 'Editer', width:'75', renderCell: (params) => (
            <IconButton color="info" onClick={() => editRef.current.handleOpen(params.row)}>
                <ModeEditOutlineTwoToneIcon/>
            </IconButton>
        )},
        {field: 'id', headerName:'ID', width: '50'},
        {field: 'nomEntite', headerName:'Nom Entité', minWidth: '400'},
        {field: 'echeance', headerName:'Échéance', minWidth: '200', renderCell: (params) => (getEcheanceStr(params.row.echeance))},

        {field: 'adresse1', headerName:'Adresse 1', minWidth: '300'},
        {field: 'adresse2', headerName:'Adresse 2', minWidth: '300'},
        {field: 'adresse3', headerName:'Adresse 3', minWidth: '300'},
        {field: 'codePostal', headerName:'Code Postal', minWidth: '100'},
        {field: 'ville', headerName:'Ville', minWidth: '300'},
        {field: 'raisonSociale', headerName:'raisonSociale', minWidth: '300'},
        {field: 'civilite', headerName:'Civilite', width: '100'},
        {field: 'nom', headerName:'Nom', minWidth: '150'},
        {field: 'prenom', headerName:'Prenom', minWidth: '150'},
        {field: 'adressePersonne', headerName:'Adresse Personne', minWidth: '300'},
        {field: 'codePostalPersonne', headerName:'CP Personne', width: '100'},
        {field: 'villePersonne', headerName:'Ville Personne', minWidth: '300'},
        {field: 'pays', headerName:'Pays', width: '75'},
        {field: 'numeroTvaIntra', headerName:'Numéro TVA Intra', minWidth: '150'},
        {field: 'telephone', headerName:'Téléphone', minWidth: '200'},
        {field: 'mail', headerName:'Mail', minWidth: '400'},
        {field: 'numeroFournisseur', headerName:'Numéro Fournisseur', minWidth: '150'},
        {field: 'mailComptabilite', headerName:'Mail Comptabilite', minWidth: '400'},
        {field: 'groupe', headerName:'Groupe', minWidth: '150'},
        {field: 'centreCout', headerName:'Centre Coût', minWidth: '150'},
    ]


    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
    React.useEffect(() => {
		LoadAllClients()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

    function LoadAllClients() {
        return GetAllClient()
        .then(data => {
            var rowsTMP = [];
            data.forEach(client => {
                rowsTMP.push(GenerateClient(client))
            })
            rowsTMP = rowsTMP.sort((a, b) => a.id - b.id);
            setRows(rowsTMP);
        })
    }

    function GenerateClient(client){
        return {
            id: client.id,
            nomEntite: client.nomEntite,
            echeance: client.echeance,
            adresse1: (client.adresse1 === null) ? '' : client.adresse1,
            adresse2: (client.adresse2 === null) ? '' : client.adresse2,
            adresse3: (client.adresse3 === null) ? '' : client.adresse3,
            codePostal: (client.codePostal === null) ? '' : client.codePostal,
            ville: (client.ville === null) ? '' : client.ville,
            raisonSociale: (client.raisonSociale === null) ? '' : client.raisonSociale,
            civilite: (client.civilite === null) ? '' : client.civilite,
            nom: (client.nom === null) ? '' : client.nom,
            prenom: (client.prenom === null) ? '' : client.prenom,
            adressePersonne: (client.adressePersonne === null) ? '' : client.adressePersonne,
            codePostalPersonne: (client.codePostalPersonne === null) ? '' : client.codePostalPersonne,
            villePersonne: (client.villePersonne === null) ? '' : client.villePersonne,
            pays: (client.pays === null) ? '' : client.pays,
            numeroTvaIntra: (client.numeroTvaIntra === null) ? '' : client.numeroTvaIntra,
            telephone: (client.telephone === null) ? '' : client.telephone,
            mail: (client.mail === null) ? '' : client.mail,
            numeroFournisseur: (client.numeroFournisseur === null) ? '' : client.numeroFournisseur,
            mailComptabilite: (client.mailComptabilite === null) ? '' : client.mailComptabilite,
            groupe: (client.groupe === null) ? '' : client.groupe,
            centreCout: (client.centreCout === null) ? '' : client.centreCout,
        }
    }

    
    //#region AXIOS REQUEST
    function GetAllClient(){ 
		return GET("administration/getAllClient") 
	}
    //#endregion

    return (
        <Stack direction='column' spacing={2} justifyContent='center' height='80%' margin={5}>
            <Button variant="contained" onClick={() => addRef.current.handleOpen()}>Ajouter un client</Button>
            <DataGrid columns={columns} rows={rows} sx={{bgcolor: 'background.default'}}/>
            <EditClient useForAddingClient={false} loadClient={LoadAllClients} ref={editRef}/>
            <EditClient useForAddingClient={true} loadClient={LoadAllClients} ref={addRef}/>
        </Stack>
            

    )
}

const EditClient = React.forwardRef((props, ref) => {
    const [open, setOpen] = React.useState(false);
    const [id, setId] = React.useState(0);
    const [nomEntite, setNomEntite] = React.useState('');
    const [echeance, setEcheance] = React.useState('');
    const [adresse1, setAdresse1] = React.useState('');
    const [adresse2, setAdresse2] = React.useState('');
    const [adresse3, setAdresse3] = React.useState('');
    const [codePostal, setCodePostal] = React.useState('');
    const [ville, setVille] = React.useState('');
    const [raisonSociale, setRaisonSociale] = React.useState('');
    // const [civilite, setCivilite] = React.useState('');
    const [nom, setNom] = React.useState('');
    const [prenom, setPrenom] = React.useState('');
    const [adressePersonne, setAdressePersonne] = React.useState('');
    const [codePostalPersonne, setCodePostalPersonne] = React.useState('');
    const [villePersonne, setVillePersonne] = React.useState('');
    const [pays, setPays] = React.useState('');
    const [numeroTvaIntra, setNumeroTvaIntra] = React.useState('');
    const [telephone, setTelephone] = React.useState('');
    const [mail, setMail] = React.useState('');
    const [numeroFournisseur, setNumeroFournisseur] = React.useState('');
    const [mailComptabilite, setMailComptabilite] = React.useState('');
    const [groupe, setGroupe] = React.useState('');
    const [centreCout, setCentreCout] = React.useState('');

    React.useImperativeHandle(ref, () => ({
        handleOpen: (client) => {
            if(!props.useForAddingClient){
                setId(IsValid(client.id));
                setNomEntite(IsValid(client.nomEntite));
                setEcheance(IsValid(parseInt(client.echeance)));
                setAdresse1(IsValid(client.adresse1));
                setAdresse2(IsValid(client.adresse2));
                setAdresse3(IsValid(client.adresse3));
                setCodePostal(IsValid(client.codePostal));
                setVille(IsValid(client.ville));
                setRaisonSociale(IsValid(client.raisonSociale));
                // setCivilite(IsValid(client.civilite));
                setNom(IsValid(client.nom));
                setPrenom(IsValid(client.prenom));
                setAdressePersonne(IsValid(client.adressePersonne));
                setCodePostalPersonne(IsValid(client.codePostalPersonne));
                setVillePersonne(IsValid(client.villePersonne));
                setPays(IsValid(client.pays));
                setNumeroTvaIntra(IsValid(client.numeroTvaIntra));
                setTelephone(IsValid(client.telephone));
                setMail(IsValid(client.mail));
                setNumeroFournisseur(IsValid(client.numeroFournisseur));
                setMailComptabilite(IsValid(client.mailComptabilite));
                setGroupe(IsValid(client.groupe));
                setCentreCout(IsValid(client.centreCout));
            }
            setOpen(true);
        }
    }))

    function IsValid(value){ 
		return (value === null || value === undefined) ? '' : value;
	}

    function HandleClose(save){
        if(save){
            if(props.useForAddingClient){
                var params = { nomEntite, echeance, adresse1, adresse2, adresse3, codePostal, ville, raisonSociale, nom, prenom, adressePersonne, codePostalPersonne, villePersonne, pays, numeroTvaIntra, telephone, mail, numeroFournisseur, mailComptabilite, groupe, centreCout };
                return POST("administration/insertClient", params)
                .then(() => {
                    props.loadClient();
                    setOpen(false);
                })
            } else {
                var paramsUpdate = { id, nomEntite, echeance, adresse1, adresse2, adresse3, codePostal, ville, raisonSociale, nom, prenom, adressePersonne, codePostalPersonne, villePersonne, pays, numeroTvaIntra, telephone, mail, numeroFournisseur, mailComptabilite, groupe, centreCout };
                return UPDATE("administration/updateClient", paramsUpdate)
                .then(() => {
                    props.loadClient();
                    setOpen(false);
                })
            }
        }
        setOpen(false);
    }

    return (
        <Dialog fullWidth maxWidth="xl" open={open} onClose={() => HandleClose(false)}>
            <DialogTitle>{(props.useForAddingClient) ? "Ajouter un client" : "Editer un client"}</DialogTitle>
            <DialogContent>
                <Stack direction='column' spacing={2} mt={5}>
                    <Paper elevation={5} sx={{padding: 5}} >
                        <Typography mb={2} textAlign='center' variant="h6">Entité</Typography>
                        <Stack direction='row' spacing={2}>
                            <TextField fullWidth variant="standard" label='Nom Entite' value={nomEntite} onChange={(event) => setNomEntite(event.target.value)}></TextField>
                            <TextField fullWidth variant="standard" select label='Échance' value={echeance} onChange={(event) => setEcheance(event.target.value)}>
                                <MenuItem value={2}>{getEcheanceStr(2)}</MenuItem>
                                <MenuItem value={3}>{getEcheanceStr(3)}</MenuItem>
                                <MenuItem value={4}>{getEcheanceStr(4)}</MenuItem>
                                <MenuItem value={5}>{getEcheanceStr(5)}</MenuItem>
                                <MenuItem value={6}>{getEcheanceStr(6)}</MenuItem>
                            </TextField>
                        </Stack>
                        
                    </Paper>
                    <Paper elevation={5} sx={{padding: 5}} >
                        <Typography mb={2} textAlign='center' variant="h6">Adresse Entité</Typography>
                        <Stack direction='column' spacing={2}>
                            <Stack direction='row' spacing={2}>
                                <TextField fullWidth variant="standard" label='Adresse 1' value={adresse1} onChange={(event) => setAdresse1(event.target.value)}></TextField>
                                <TextField fullWidth variant="standard" label='Adresse 2' value={adresse2} onChange={(event) => setAdresse2(event.target.value)}></TextField>
                                <TextField fullWidth variant="standard" label='Adresse 3' value={adresse3} onChange={(event) => setAdresse3(event.target.value)}></TextField>
                            </Stack>
                            <Stack direction='row' spacing={2}>
                                <TextField fullWidth variant="standard" label='Code Postal' value={codePostal} onChange={(event) => setCodePostal(event.target.value)}></TextField>
                                <TextField fullWidth variant="standard" label='Ville' value={ville} onChange={(event) => setVille(event.target.value)}></TextField>
                                <TextField fullWidth variant="standard" label='Raison Sociale' value={raisonSociale} onChange={(event) => setRaisonSociale(event.target.value)}></TextField>
                            </Stack>
                        </Stack>
                    </Paper>
                    <Paper elevation={5} sx={{padding: 5}} >
                        <Typography mb={2} textAlign='center' variant="h6">Siège Social</Typography>
                        <Stack direction='column' spacing={2}>
                            <Stack direction='row' spacing={2}>
                                {/* <TextField fullWidth variant="standard" label='Civilité' value={civilite} onChange={(event) => setCivilite(event.target.value)}></TextField> */}
                                <TextField fullWidth variant="standard" label='Nom' value={nom} onChange={(event) => setNom(event.target.value)}></TextField>
                                <TextField fullWidth variant="standard" label='Prénom' value={prenom} onChange={(event) => setPrenom(event.target.value)}></TextField>
                            </Stack>
                            <Stack direction='row' spacing={2}>
                                <TextField fullWidth variant="standard" label='Adresse Personne' value={adressePersonne} onChange={(event) => setAdressePersonne(event.target.value)}></TextField>
                                <TextField fullWidth variant="standard" label='Ville Personne' value={villePersonne} onChange={(event) => setVillePersonne(event.target.value)}></TextField>
                                <TextField fullWidth variant="standard" label='Code Postal Personne' value={codePostalPersonne} onChange={(event) => setCodePostalPersonne(event.target.value)} sx={{width: 300}}></TextField>
                                <TextField fullWidth variant="standard" label='Pays' value={pays} onChange={(event) => setPays(event.target.value)} sx={{width: 200}}></TextField>
                            </Stack>
                        </Stack>
                    </Paper>
                    <Paper elevation={5} sx={{padding: 5}} >
                        <Typography mb={2} textAlign='center' variant="h6">Autres Informations</Typography>
                        <Stack direction='column' spacing={2}>
                            <Stack direction='row' spacing={2}>
                                <TextField fullWidth variant="standard" label='Numéro TVA Intra' value={numeroTvaIntra} onChange={(event) => setNumeroTvaIntra(event.target.value)}></TextField>
                                <TextField fullWidth variant="standard" label='Téléphone' value={telephone} onChange={(event) => setTelephone(event.target.value)}></TextField>
                                <TextField fullWidth variant="standard" label='Mail' value={mail} onChange={(event) => setMail(event.target.value)}></TextField>
                            </Stack>
                            <Stack direction='row' spacing={2}>
                                <TextField fullWidth variant="standard" label='Numéro Fournisseur' value={numeroFournisseur} onChange={(event) => setNumeroFournisseur(event.target.value)}></TextField>
                                <TextField fullWidth variant="standard" label='Mail Comptabilité' value={mailComptabilite} onChange={(event) => setMailComptabilite(event.target.value)}></TextField>
                                <TextField fullWidth variant="standard" label='Groupe' value={groupe} onChange={(event) => setGroupe(event.target.value)}></TextField>
                                <TextField fullWidth variant="standard" label='Centre Coût' value={centreCout} onChange={(event) => setCentreCout(event.target.value)}></TextField>
                            </Stack>
                        </Stack>
                    </Paper>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => HandleClose(true)} color="success">{(props.useForAddingClient) ? "Ajouter le client" : "Sauvgarder les changements"}</Button>
                <Button onClick={() => HandleClose(false)}>Retour</Button>
            </DialogActions>
        </Dialog>
    )
})


function getEcheanceStr(id){
    id = parseInt(id);
    var str = ''
	switch(id){
		case 2: str = "Date du jour + 30 jours";
			break;
		case 3: 
			str = "Fin du mois + 30 jours";
			break;
		case 4:
            str = "Fin du mois + 45 jours";
			break;
		case 5: 
            str = "Le 10 du mois suivant";
			break;
		case 6: 
            str = "Fin du mois + 60 jours";
			break;
		default: break;
	}
	return str;
}