import React from 'react'
import { StyleSheet, View, Text } from '@react-pdf/renderer'

const styles = StyleSheet.create({
    container: {
        fontSize: "8pt",
        textAlign: "center",
        maxHeight: "4%",
        objectPositionY : "bottom",
        position: 'absolute',
        bottom: 15,
        left: 0,
        right: 0,
    },
    pageNumber: {
        bottom: 25,
    },
    inspiration: {
        bottom: 15,
    },
    capital: {
        bottom: 5,
    },
})

const Footer = () => {
    return (
        <View style={styles.container} fixed>
            <Text style={styles.pageNumber} render={({pageNumber, totalPages}) => (
                `${pageNumber} / ${totalPages}`
            )} />
            <Text style={styles.inspiration}>Inspiration Productions, 23, rue Arago, 93400 Saint-Ouen - Tél. : 01 80 89 30 50</Text>
            <Text style={styles.capital}>SA au capital de 515 572 € - RCS BOBIGNY B 452 322 712 - APE 5911B - SIRET : 452 322 712 00038 - N° INTRA FR 344523227</Text>
            
        </View>
    )
}

export {Footer}