import React from "react";

import Stack from '@mui/material/Stack';

import { ChangeFactureCommande } from "./FactureButtons/ChangeFactureCommande";
import { DevisToFacture } from "./FactureButtons/ChangeDevisToPDF";
import { DeleteFacture } from "./FactureButtons/DeleteFacture";
import { CreateAvoir } from "./FactureButtons/CreateAvoir";
import { ShowFactureAndAvoir } from "./FactureButtons/ShowFactures";
import { CreateBonLivraison } from "./FactureButtons/CreateBonLivrason";
import { AddNumeroCommande } from "./FactureButtons/AddCommandeNum";

export const Facture = (props) => {
	return(
		<Stack width='50%'  marginTop={10} marginLeft="25%" spacing={2}>
			<AddNumeroCommande/>
			<CreateBonLivraison/>
			<DevisToFacture/>
			<ChangeFactureCommande/>
			<DeleteFacture/>
			<CreateAvoir/>
			<ShowFactureAndAvoir/>
		</Stack>
	)
}