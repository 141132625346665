import React, {} from 'react';
import { GET, UPDATE, POST } from '../../../../utils/AxiosRequest';

import { createSearchParams, useNavigate  } from "react-router-dom";

import classNames from "classnames";
import styles from "../ProjectHome.module.css";

import { alpha, Box, Card, CardContent, CardHeader, Grid, IconButton, styled, Tooltip, Typography, Dialog, DialogTitle, DialogContent, TextField, MenuItem, DialogActions, Select, useMediaQuery, useTheme } from '@mui/material';

import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

import InfoTwoTone from '@mui/icons-material/InfoTwoTone';
import PostAddTwoTone from '@mui/icons-material/PostAddTwoTone';

import Chip from '@mui/material/Chip';

import Avatar from '@mui/material/Avatar';
import { Button, Stack } from '@mui/material';

import { Scrollbars } from 'react-custom-scrollbars-2';

import dayjs from 'dayjs';
import { Accordion, AccordionDetails, AccordionSummary } from '../../../utils/Accordion';
import { ImportAllLogos } from '../../../utils/Logos';

import Session from 'react-session-api';

import { SEND_NOTIFICATION_SLACK } from '../../../../utils/Slack';

require('dayjs/locale/fr')
dayjs.locale('fr')

const images = ImportAllLogos();

const ID_DEADLINE_1 = 78;

export const ProjectList = React.forwardRef((props, ref) => {
	var { pepoleList } = props;
	const isDesktop = useMediaQuery('(min-width:1065px)');
	const pepoleData = JSON.parse(Session.get('pepoleData'));
	let favoriteProjects = JSON.parse(pepoleData.favoriteProjects);

	const updateFavoriteProjects = (list) =>{
		favoriteProjects = list;
		GET("projects/getAllProjects")
		.then(data => {	
			data = SortBase(data)
			setProjectList(data)
			setProjectListOrdered(data);
		})
	}
	const [projectList, setProjectList] = React.useState([]);
	const [projectListOrdered, setProjectListOrdered] = React.useState([]);
	const [filters, setFilters] = React.useState({
		pepole : JSON.parse((pepoleData.pepoleFilter === "") ? "[-1]": pepoleData.pepoleFilter),
		client: -1, 
		order: -1, 
		simplified: (parseInt(pepoleData.simplified) === 1), 
		masked: (parseInt(pepoleData.masked) === 1), 
		subtaskOnly: (parseInt(pepoleData.subtaskOnly) === 1)
	});
	const projectRefs = React.useRef([]);

	React.useImperativeHandle(ref, () => ({
		reload: () => { return LoadProjectList(); },
		changePepoleFilter: (pepole) => setFilters({...filters, pepole}),
		changeClientFilter: (client) => setFilters({...filters, client}),
		changeOrderFilter: (order) => {
			setProjectListOrdered(SortProjectList(projectList, order))
		},
		changeSimplified: (simplified) => setFilters({...filters, simplified}),
		changeMasked: (masked) => setFilters({...filters, masked}),
	}))	

	//Mount
	React.useEffect(() => {
		let isMounted = true;
		GET("projects/getAllProjects")
		.then(data => {
			if(isMounted){
				
				data = SortBase(data)
				setProjectList(data)
				setProjectListOrdered(data);
			}
		})
		
		return _ => { isMounted = false; };
		// eslint-disable-next-line
	}, [])

	function LoadProjectList(){
		return GET("projects/getAllProjects")
		.then(data => {
			data = SortProjectList(data, filters.order);
			setProjectList(data)
			setProjectListOrdered(SortProjectList(data, filters.order));
		})
	}

	function SortProjectList(data, order){
		setFilters({...filters, order})
		switch(order){
			case -1: return SortBase(data);
			case 2: return SortListByDeadline(data, true, ID_DEADLINE_1);
			case 3: return SortListByDeadline(data, false, ID_DEADLINE_1);
			default : return data;
		}
	}

	function SortBase(array){
		array = array.sort((a, b) => b.urgency - a.urgency);
		array = array.sort((a, b) => favoriteProjects.includes(b.id) - favoriteProjects.includes(a.id));
		return array.sort((a, b) => a.etat - b.etat);
	}

	function SortProjectByFavori(array){
		array = SortBase(array)
		setProjectList([...array]);
		setProjectListOrdered([...array]);
	}

	function SortListByDeadline(array, isEnd, deadlineId){
		// Default JS date format is MM/DD/YYYY in which JS can compare dates by creating a date : new Date("MM/DD/YYYY")
		// At project creation, no date required, the deadline date is created by ProjectCreation.GetStrFromDaysJS(...) based on SubTasks and the format is YYYY-MM-DDT00:00
		// In ProjectInformation, the deadline date can be choosen and set in db in the following format DD-MM-YYYY
		// The comparison below change either YYYY-MM-DDT00:00 or DD-MM-YYYY dates formats to MM/DD/YYYY so JS can compare
		if(isEnd)
			return array.sort((a, b) => new Date((a.deadline.endsWith("T00:00") || a.deadline.endsWith("T00:00:00")) ? a.deadline.substring(0,10).replace(/^(\d{4})-(\d{1,2})-(\d{1,2})$/, "$2/$3/$1") : a.deadline.replace(/^(\d{1,2})-(\d{1,2})-(\d{4})$/, "$2/$1/$3")) - new Date((b.deadline.endsWith("T00:00") || b.deadline.endsWith("T00:00:00")) ? b.deadline.substring(0,10).replace(/^(\d{4})-(\d{1,2})-(\d{1,2})$/, "$2/$3/$1") : b.deadline.replace(/^(\d{1,2})-(\d{1,2})-(\d{4})$/, "$2/$1/$3")));
		else
			return array.sort((b, a) => new Date((a.deadline.endsWith("T00:00") || a.deadline.endsWith("T00:00:00")) ? a.deadline.substring(0,10).replace(/^(\d{4})-(\d{1,2})-(\d{1,2})$/, "$2/$3/$1") : a.deadline.replace(/^(\d{1,2})-(\d{1,2})-(\d{4})$/, "$2/$1/$3")) - new Date((b.deadline.endsWith("T00:00") || b.deadline.endsWith("T00:00:00")) ? b.deadline.substring(0,10).replace(/^(\d{4})-(\d{1,2})-(\d{1,2})$/, "$2/$3/$1") : b.deadline.replace(/^(\d{1,2})-(\d{1,2})-(\d{4})$/, "$2/$1/$3")));
	}

	if(isDesktop){
		return (
			<Box className={classNames(styles.projectList)} >
				<Scrollbars>
					<Grid gridAutoFlow container spacing={2} padding={4} paddingBottom={10} marginBottom={15}>
						{projectListOrdered.map((data, i) => (
							<Project key={data.id} ref={(element) => projectRefs.current[i] = element} projectData={data} pepoleList={pepoleList} sortFavori={SortProjectByFavori} filters={filters} pepoleData={pepoleData} favoriteProjects={favoriteProjects} updateFavoriteProjects={updateFavoriteProjects}/>
						))}
					</Grid>
				</Scrollbars>
			</Box>
		)
	} else {
		return (
			<Box sx={{overflowY: 'scroll', minHeight: '100vh'}} >
				<Grid gridAutoFlow container spacing={2} padding={4} paddingTop={1} paddingBottom={10} marginBottom={15}>
					{projectListOrdered.map((data, i) => (
						<Project key={data.id} ref={(element) => projectRefs.current[i] = element} projectData={data} pepoleList={pepoleList} sortFavori={SortProjectByFavori} filters={filters} pepoleData={pepoleData} favoriteProjects={favoriteProjects} updateFavoriteProjects={updateFavoriteProjects}/>
					))}
				</Grid>
			</Box>
			
		)
	}
	
})

const Project = React.forwardRef((props, ref) => {
	const { projectData, pepoleList, filters, pepoleData, favoriteProjects, updateFavoriteProjects } = props;

	const theme = useTheme();
	const navigate = useNavigate();
	const [tasks, setTasks] = React.useState([]);
	const [deadlines, setDeadlines] = React.useState([]);
	const [pepoles, setPepoles] = React.useState([]);
	const [expanded, setExpanded] = React.useState(false);
	const [hidden, setHidden] = React.useState(false);
	const [probleme, setProbleme] = React.useState(false);
	const changeExpanded = (panel) => (event, isExpanded) => setExpanded(isExpanded ? panel : false);
	const [hasChanged, setHasChanged] = React.useState(false);

	const [taskCreationOpen, setTaskCreationOpen] = React.useState(false);
	const [employeesList, setEmployeesList] = React.useState([]);

	React.useImperativeHandle(ref, () => ({
		GetDeadline : () => {
			return deadlines;
		}
	}))

	React.useEffect(() => {
		return Promise.all([
			GET("projects/getAllTasks", { projectID: projectData.id }),
			GET("projects/getAllSubTasksByProject", { projectID: projectData.id }),
			GET("projects/getDevisOfProject", { id: projectData.id }),
		]) 
		.then(data => {
			if(data[2].length > 0){
				data[2].forEach(element => {
					if(element.valide !== 1){
						setProbleme("Le devis n'est pas valide");
					}
				})
			} else {
				setProbleme("Il n'y a pas de devis pour ce projet");
			}
			var  tasksData = data[0].sort((a, b) => a.idTache - b.idTache);
			var pepoleList = [];
			tasksData.forEach(element => element.subtasks = data[1].filter(e => e.idTache === element.idTache));
			
			// Make tasks unique in a project subtasks list - To be removed when subtasks won't be displayed by tasks/category order anymore
			const uniqueTasksIdentifiers = [];
			const uniqueTasksData = [];
			tasksData.forEach(element => {
				if(!uniqueTasksIdentifiers.includes(element.idTache)){
					uniqueTasksIdentifiers.push(element.idTache)
					uniqueTasksData.push(element);
				}
			});

			uniqueTasksData.forEach(task => {
				task.subtasks.forEach(subtask => {
					var array = JSON.parse(subtask.employe);
					if(array.length === 0 && subtask.etat === 0){
						if(!pepoleList.includes(-2)) {
							pepoleList.push(-2)
						}
					}
					else if( filters.pepole.indexOf(projectData.projectLeader) !== -1 ){ // Add current project in the list for project leader
						if(pepoleList.indexOf(projectData.projectLeader) === -1)
								pepoleList.push(projectData.projectLeader);
					}
					else if( subtask.etat === 0 ){ // People is concerned on a project only for non terminated subtasks: subtask.etat === 0
						array.forEach(pepole => {
							if(pepoleList.indexOf(pepole) === -1)
								pepoleList.push(pepole);
						})
					}
					
				})
			})
			setTasks(uniqueTasksData);
			setPepoles(pepoleList);
			loadEmployees();
			return GET("dates/getPlanningByProjectId", {projectId: projectData.id})
		}).then(data => {
			setDeadlines((data) ? data : []);
		})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[hasChanged]);

	React.useEffect(() => {
		setHidden(GetProjectHidden());
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, pepoles, projectData.clientID, projectData.etat])

	function GetProjectHidden(){
		if(filters.masked && projectData.etat === 1) return true;
		if(filters.client !== -1 && filters.client !== projectData.clientID) return true;

		if(projectData.projectLeader === pepoleData.id) return false;
		if(filters.pepole.includes(-1)) return false;

		if(filters.pepole.includes(-2)){
			if(pepoles.some((element) => filters.pepole.includes(element))) return false;
			return !pepoles.includes(-2);
		}

		if(pepoles.some((element) => filters.pepole.includes(element))) return false;

		return true;
	}

	function GetPepolesFromArray(array, useLastName){
		var str = "";
		pepoleList.forEach(element => {
			if(array.includes(element.id)){
				str+= (useLastName) ? element.prenom + " " + element.nom + ", " : element.prenom + ", ";
			}
		})
		return str.substring(0, str.length - 2);
	}

	function UpdateCheck(id, state){
		return UPDATE("projects/updateSubTaskStatus", {etat: (state === 0) ? 1 : 0, subTaskID: id})
		.then(() => GET("projects/getAllSubTasksByProject", { projectID: projectData.id }))
		.then(data => {
			var  tasksData = tasks;
			tasksData.forEach(element => {
				element.subtasks = data.filter(e => e.idTache === element.idTache)
			})
			setTasks([...tasksData]);
		})
	}

	function UpdateFavori(){
		var tmpFavoriteProjects = favoriteProjects.slice();
		var index = tmpFavoriteProjects.indexOf(parseInt(projectData.id))
		if(index >= 0){
			if(tmpFavoriteProjects.length === 1){
				tmpFavoriteProjects = [-1];
			}
			else{
				tmpFavoriteProjects.splice(index, 1);
			}
		}
		else if(tmpFavoriteProjects.includes(-1)){
			tmpFavoriteProjects = [];
			tmpFavoriteProjects.push(projectData.id);
		}
		else{
			tmpFavoriteProjects.push(projectData.id);
		}
	
		updateFavoriteProjects(tmpFavoriteProjects);
		pepoleData.favoriteProjects = JSON.stringify(tmpFavoriteProjects);
		Session.set('pepoleData', JSON.stringify(pepoleData));

		return UPDATE("login/updateFavoriteProjects", {favoriteProjects: JSON.stringify(tmpFavoriteProjects)});
		
	}

	function GetProjectColor(){
		if(projectData.etat === 1) return alpha("#808b96", 0.2);
		else if (favoriteProjects.includes(projectData.id)) return alpha(theme.palette.warning.light, 0.2);
		else if(projectData.urgency === 2) return alpha(theme.palette.error.light, 0.2);
		else if(projectData.urgency === 1) return alpha(theme.palette.info.light, 0.2);
		else return alpha("#f2f7fe", 0.4);
	}

	function IsTasksHidden(task){
		var array = [];
		if(task){
			task.subtasks.forEach(subtask => {
				var tmp = JSON.parse(subtask.employe);
				if(filters.pepole.includes(-1)) array.push(subtask.id);
				else if(filters.pepole.includes(-2)){
					if(tmp.length === 0) array.push(subtask.id);
					else if(tmp.some(pepole => filters.pepole.includes(pepole))) array.push(subtask.id);

				}
				else if(JSON.parse(subtask.employe).some(pepole => filters.pepole.includes(pepole))) array.push(subtask.id);
			})
		}
		return array;
	}

	function loadEmployees(){
        var promises = [
                GET("projects/getAllEmployeInfo", {}),
            ];
            return Promise.all(promises)
            .then(data => {
                setEmployeesList(data[0]);
            })
      }

	function NavigateToProject(id, task){
		var params = (task !== undefined) ? {id, task} : {id};
		navigate({
			pathname: `/projetInformation/`,
			search: `?${createSearchParams(params)}`
		});
	}

	function addTask(project, category, type, text, employees ){
		var paramsCategory = { // Category here means old table tache (should-be-removed table)
			etat: 0,
			idTache: category.id,
			idProjet: project.id,
		}

		return POST("projects/insertNewTask", paramsCategory)
		.then( (newCategory) => { // Category means old table tache (should be removed table)
			var params = {
				idSousTache: type.id, // type 'Autre' in case of undefined type
				etat: 0,
				employe: JSON.stringify(employees),
				idTache: newCategory.insertId,
				idProjet: project.id,
				texte: type.nom === 'Autre' ? text : '',
			}
			POST("projects/insertNewSubTask", params)
			.then( (result) => {
				var subtaskParams = {
					id: result.insertId,
				}
				UPDATE("projects/updateSubtaskDefaultOrderIndex", subtaskParams);
			})

			// Notify if a project is set
			const pepoleData = JSON.parse(Session.get('pepoleData'));
			var messageAdded = "(ProjectList:389) <sender> t'a assigné la sous-tâche " + ((text && text !== '') ? text : type.nom) + " appartenant à la tâche " + (category ? category.nom : 'Autre') + " du projet " + project.nom;
			var sender = String(pepoleData.prenom).toLowerCase();

			employees.forEach(async element => {
				var tmp = employeesList.find(item => item.id === element)
				if(tmp === undefined) return;

				var user = String(tmp.prenom).toLowerCase();

				var url = new URL(window.location.href.replace(window.location.search,''));
				url.searchParams.append("id", project.id)
				url.searchParams.append("task", newCategory.insertId)

				await SEND_NOTIFICATION_SLACK(sender, user, messageAdded, url);
			})
			setHasChanged(!hasChanged);
		})
		
	}
	

	function GetSubtasks(data, array){
		return (
			<div key={data.id}>
				{data.subtasks.map(subtask =>{
					if((subtask.etat === 1)) return null;
					var pepoles = JSON.parse(subtask.employe);
					var str = (pepoles.length > 0) ? GetPepolesFromArray(pepoles, false) : "Pas assignée";
					return (
						<Stack key={subtask.id} direction="row" alignItems="center" justifyContent="space-between" hidden={!array.includes(subtask.id)} sx={{paddingLeft: 1}}>
							<Typography title={(subtask.nom === 'Autre') ? subtask.texte : subtask.nom} className={classNames(styles.subtaskText, (subtask.etat === 1) ? styles.crossed : "")} variant='body2' color="text.secondary">{(subtask.nom === 'Autre') ? subtask.texte : subtask.nom}</Typography>
							<Typography className={classNames(styles.subtaskText, (subtask.etat === 1) ? styles.crossed : "")} variant='body2' color="text.secondary">{str}</Typography>
							<Stack direction="row">
								<Button size='sm' onClick={() => NavigateToProject(projectData.id, data.idTache)} color="info"><Typography key={subtask.id} variant='body2'>go</Typography></Button>
								<Button size='sm' onClick={() => UpdateCheck(subtask.id, subtask.etat)} color={(subtask.etat === 0) ? 'success' : 'warning'}><Typography key={subtask.id} variant='body2'>check</Typography></Button>
							</Stack>
						</Stack>
					)
				})}
			</div>
			
		)
	}

	function GetSubHeaderText(){
		var leader = "Pas de chef de projet";
		if(projectData.projectLeader !== -1){
			leader = GetPepolesFromArray([projectData.projectLeader], true);
		}
		return projectData.nomClient + " | " + leader;
	}

	return(
		<Grid item xs={12} sm={12} md={6} lg={6}  hidden={hidden}>
			{!hidden && <Card >
				<CardHeader title={projectData.nom} subheader={GetSubHeaderText()} avatar={<Avatar sx={{ width: 24, height: 24 }} src={(images[projectData.clientID + '.svg'] !== undefined) ? images[projectData.clientID + '.svg'] : images['-1.svg']}/>} 
					action={(
							<Stack direction="row" alignItems="center">
								{probleme !== false && 
									<Tooltip title={probleme}>
										<WarningTwoToneIcon />
									</Tooltip>
								}
									<Tooltip title="Informations" placement="bottom"><IconButton color="info" onClick={() => NavigateToProject(projectData.id)}><InfoTwoTone /></IconButton></Tooltip>
									{!favoriteProjects.includes(projectData.id) && <IconButton onClick={() => UpdateFavori()}> <StarBorderIcon color='warning'/> </IconButton>}
									{ favoriteProjects.includes(projectData.id) && <IconButton onClick={() => UpdateFavori()} > <StarIcon color='warning'/> </IconButton>}
									<Tooltip title="Ajouter une tâche" placement="bottom"><IconButton color="info" onClick={() =>setTaskCreationOpen(true)}><PostAddTwoTone color='info'/></IconButton></Tooltip>
									<TaskCreation open={taskCreationOpen} setOpen={setTaskCreationOpen} project={projectData} addTask={addTask}/>
								
							</Stack>
					)} 
					sx={{backgroundColor: GetProjectColor(), paddingY: 1, "& .MuiCardHeader-title": {wordBreak: "break-all"}}} 
				/>
				{!filters.simplified && !filters.subtaskOnly &&
					<CardContentNoPadding sx={{padding: 0, paddingTop: 0}} >
						{tasks.map((data, i) => {
							var array = IsTasksHidden(data);
							var checked = 0;
							data.subtasks.forEach(subtask => checked += subtask.etat)
							if(checked === data.subtasks.length) return null
							return (
								<Accordion  key={i} expanded={expanded === data.nom} onChange={changeExpanded(data.nom)} sx={{width: "100%", }} elevation={1} hidden={array.length === 0}>
									<AccordionSummary sx={{paddingY: 0}}>
										<Stack sx={{width: "100%"}} direction="row" justifyContent="space-between" alignItems="center">
											<Typography variant='body2' color="text.primary">{data.nom}</Typography>
											<Chip sx={{height: 0.5}}  label="" color={(checked === data.subtasks.length) ? "success" : ((checked > 0) ? "warning" : "error")}/>	
										</Stack>
									</AccordionSummary>
									<AccordionDetails sx={{paddingY: 0}}>
										{GetSubtasks(data, array)}
									</AccordionDetails>
								</Accordion >
							)
						})}
					</CardContentNoPadding>
				}
				{ !filters.simplified && filters.subtaskOnly &&
					tasks.map((data, i) => {
						var array = IsTasksHidden(data);
						var checked = 0;
						data.subtasks.forEach(subtask => checked += subtask.etat)
						if(checked === data.subtasks.length) return null
						return GetSubtasks(data, array);
					})
				}
			</Card>}
		</Grid>
	)
})

const CardContentNoPadding = styled(CardContent)(`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
`);

export const TaskCreation = React.forwardRef((props, ref) => {
	const pepoleData = JSON.parse(Session.get('pepoleData'));
	

	const [category, setCategory] = React.useState();
	const [type, setType] = React.useState("");
	const [text, setText] = React.useState('');
	const [employees, setEmployees] = React.useState([pepoleData.id]);
	const theme = useTheme();
	const changeCategory = (event) => {setCategory(event.target.value);};
	const changeType = (event) => {setType(event.target.value);};
	const changeEmployees = (event) => setEmployees(event.target.value);

	const [taskTemplates, setTaskTemplates] = React.useState([]);
	const [autreTaskTemplate, setAutreTaskTemplate] = React.useState([]); // Specific 'autre' task in table templateTask
	const [employeesList, setEmployeesList] = React.useState([]);

	React.useEffect(() => {
		loadTaskTemplates();
		loadEmployees();
	}, [])

	function loadTaskTemplates(){
		// First get templatesoustache so that its possible to populate a task with all its possible soustache templates
		GET("projects/getSubTaskTemplate", {})
			.then(templatesoustachedata => {
			GET("projects/getAllTasksTemplate", {})
			.then(data => {
				var categories = [];
				data.forEach(taskTemplate => {

					if(taskTemplate.nom === 'Autre'){ // Set the 'autre' category in case of undefined at task creation
						setAutreTaskTemplate(taskTemplate);
					}

					taskTemplate.types = [];
					templatesoustachedata.forEach(el => {
						if(el.idTache === taskTemplate.id){
							taskTemplate.types.push(el);
							if(el.nom === "Autre"){
								taskTemplate.autreType = el;
							}
						}
					});

					if(!taskTemplate.nom.includes('Tournage') && !taskTemplate.nom.includes('Deadline')){
						categories.push(taskTemplate);
					}
				});
				setTaskTemplates(categories.sort((a,b) => a.id - b.id));
			})
		})
	}

	function getTaskTemplateById(id) {
		return taskTemplates.find(element => element.id === id)
	}

	function loadEmployees(){
        var promises = [
			GET("projects/getAllEmployeInfo", {}),
		];
		return Promise.all(promises)
		.then(data => {
			setEmployeesList(data[0]);
		})
	}
	
	function HandleClose(save) {
		if(save){
			// category 'autre' if undefined
			var cat = category ? category : autreTaskTemplate;
			props.addTask(props.project, cat, type ? type : getTaskTemplateById(cat.id).autreType, text, employees )
		}
		setCategory("");
		setType("");
		setText("");
		props.setOpen(false);
	}
  return (
		<div>
			<Dialog maxWidth="md" open={props.open} onClose={() => HandleClose(false)}>
				<DialogTitle>
					<Typography fontSize='1.2em' color={theme.palette.text.primary}>Ajouter une tâche</Typography>
				</DialogTitle>
				<DialogContent>
					<Stack direction="column" alignItems="left" spacing={3}>
						<Typography fontSize='1em' color={theme.palette.text.primary}>{props.project.nom}</Typography>

						<TextField fullWidth select label="Catégorie" variant="standard" value={category} onChange={changeCategory}>
							{taskTemplates.map((t, i) => <MenuItem value={t} key={i} selected={t.nom === 'Autre'}>{t.nom}</MenuItem>)}
						</TextField>

						{category &&
							<TextField fullWidth select label="Type" variant="standard" value={type} onChange={changeType}>
								{category && category.types.map((t, i) => <MenuItem value={t} key={i}>{t.nom}</MenuItem>)}
							</TextField>
						}
						{(!type.nom || 'Autre' === type.nom) &&
							<TextField fullWidth label="Texte" variant="standard" selected={text} onChange={(event) => setText(event.target.value)}></TextField>
						}
						<Select style={{minWidth: "30%"}} variant='standard' multiple value={employees} onChange={changeEmployees}>
							{employeesList.map((employee, i) => (
								<MenuItem value={employee.id} key={employee.id}>{employee.prenom}</MenuItem>
							))}	
						</Select>
					</Stack>
				</DialogContent>
				
				<DialogActions>
					<Button onClick={() => HandleClose(false)}>Retour</Button>
					<Button onClick={() => HandleClose(true)} color="success">Ajouter</Button>
				</DialogActions>
			</Dialog>
		</div>
		
	)
})