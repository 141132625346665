import React from "react";


import { Button, Divider, Paper, Stack } from "@mui/material";

export const AdministrationButtons = (props) => {
	const { currentView } = props;

	function GetButtonColor(id){
		return (id === currentView) ? 'success' : 'info';
	}

    return (
        <Paper elevation={4} sx={{width: '20%'}}>
            <Stack direction='column' spacing={2} marginX={5} marginY={5}>
				<Button fullWidth variant="contained" color={GetButtonColor(6)} onClick={() => props.updateView(6)}>Personnel</Button>
				<Divider variant="middle"/>
                <Button fullWidth variant="contained" color={GetButtonColor(0)} onClick={() => props.updateView(0)}>Clients</Button>
                <Button fullWidth variant="contained" color={GetButtonColor(1)} onClick={() => props.updateView(1)}>Chiffre d'affaire</Button>
				<Divider variant="middle"/>
                <Button fullWidth variant="contained" color={GetButtonColor(4)} onClick={() => props.updateView(4)}>Valider un devis</Button>
                <Button fullWidth variant="contained" color={GetButtonColor(3)} onClick={() => props.updateView(3)}>Projets En Cours</Button>
                <Button fullWidth variant="contained" color={GetButtonColor(2)} onClick={() => props.updateView(2)}>Projets Terminés</Button>
                <Button fullWidth variant="contained" color={GetButtonColor(5)} onClick={() => props.updateView(5)}>Voir les factures par projet</Button>
                <Button fullWidth variant="contained" color={GetButtonColor(7)} onClick={() => props.updateView(7)}>Valider les factures</Button>
            </Stack>
        </Paper>
    )
}