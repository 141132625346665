import React from 'react';
import { GET, POST, DELETE, UPDATE } from '../../../../utils/AxiosRequest';

import { styled } from '@mui/material/styles';

import { DataGrid } from '@mui/x-data-grid';


import {Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, linearProgressClasses, MenuItem, Select, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme} from '@mui/material';

import Badge from '@mui/material/Badge';

import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import { Accordion, AccordionDetails, AccordionSummary } from '../../../utils/Accordion';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { SEND_NOTIFICATION_SLACK } from '../../../../utils/Slack';

import Session from 'react-session-api';

import IconButton from '@mui/material/IconButton';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

export const TaskManager = React.forwardRef((props, ref) => {
	const { projectId, pepoleList } = props;
	const defaultOpen = (props.defaultOpen !== null) ? parseInt(props.defaultOpen) : -1
	const theme = useTheme();
	const [tasks, setTasks] = React.useState([]);
	const [templateTask, setTemplateTask] = React.useState([]);
	const [templateSubtask, setTemplateSubtask] = React.useState([]);

	const [expanded, setExpanded] = React.useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
	  	setExpanded(isExpanded ? panel : false);
	};

	

	// eslint-disable-next-line react-hooks/exhaustive-deps
	React.useEffect(() => {
		let isMounted = true;
		
		var obj = {};
		GetAllTaskTemplateFromDb()
		.then((data) => {
			obj.templateTask = data
			return GetAllSubtaskTemplateFromDb();
		}).then((data) => {
			obj.templateSubtask = [];
			obj.templateTask.forEach(task => {
				obj.templateSubtask.push({id: task.id, data: data.filter(element => element.idTache === task.id)})
			})
			return GetAllTaskAndSubtaskFromBd();
		}).then(data => {
			if(isMounted){
				setTemplateTask(obj.templateTask);
				setTemplateSubtask(obj.templateSubtask);

			// Make tasks unique in a project subtasks list - To be removed when subtasks won't be displayed by tasks/category order anymore
			const uniqueTasksIdentifiers = [];
			const uniqueTasksData = [];
			data.forEach(element => {
				if(!uniqueTasksIdentifiers.includes(element.taskId)){
					uniqueTasksIdentifiers.push(element.taskId)
					uniqueTasksData.push(element);
				}
				else{
					const existingCategory = data.find(elt => elt.taskId === element.taskId)
					element.subtask.forEach((st) => existingCategory.subtask.push(st));
				}
			});

				setTasks(uniqueTasksData);
				
				if(defaultOpen !== -1){
					var index = data.findIndex(element => element.taskId === defaultOpen);
					if(index !== -1){
						setExpanded(data[index].id)
					}
				}
			}
		})

		return _ => { isMounted = false; };
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function ReloadTasks(){
		return GetAllTaskAndSubtaskFromBd()
		.then(data => {
			// Make tasks unique in a project subtasks list - To be removed when subtasks won't be displayed by tasks/category order anymore
			const uniqueTasksIdentifiers = [];
			const uniqueTasksData = [];
			data.forEach(element => {
				if(!uniqueTasksIdentifiers.includes(element.taskId)){
					uniqueTasksIdentifiers.push(element.taskId)
					uniqueTasksData.push(element);
				}
				else{
					const existingCategory = data.find(elt => elt.taskId === element.taskId)
					element.subtask.forEach((st) => existingCategory.subtask.push(st));
				}
			});

			setTasks([...uniqueTasksData])
		});	
	}

	//#region AXIOS REQUESTS
	function GetAllTaskTemplateFromDb() { 
		return GET("projects/getAllTasksTemplate", {})
		.then(data => {
			return data = data.sort((a,b) => a.id - b.id);
		})
	 }

	function GetAllSubtaskTemplateFromDb() {
		return GET("projects/getSubTaskTemplate", {})
		.then((data) => {
			return data
		})
	}

	function GetAllTaskAndSubtaskFromBd() {
		var tmp = [];
		return GET("projects/getAllTasks", {projectID: projectId})
		.then((data) => {
			var promises = []
			data.forEach((task) => {
				promises.push(
					GET("projects/getAllSubTasksFromSpecificTask", {taskID: task.id})
					.then((data) => {
						var obj = {
							id: task.id,
							taskId: task.idTache,
							nom: task.nom,
							subtask: data
						}
						tmp.push(obj);
						
					})
				)
			});
			return Promise.all(promises)
			.then(() => {
				return tmp.sort((a, b) => a.taskId - b.taskId);
			})
		})
	}

	
	function AddSubtasksInDb(idSousTache, idTache, texte, shouldAddDate, start, end) {
		var params = {
			idSousTache: idSousTache,
			etat: 0,
			employe: "[]",
			idTache: idTache,
			idProjet: projectId,
			texte: texte,
		}
		return POST("projects/insertNewSubTask", params)
		.then( (result) => {
			var subtaskParams = {
				id: result.insertId,
			}
			UPDATE("projects/updateSubtaskDefaultOrderIndex", subtaskParams);
			if(shouldAddDate){
				return AddDateinDb(idTache, start, end)
			}
		})
		.then( _ => { return ReloadTasks() })
		.then( _ => { return props.updateTasksInDates(); })
	} 

	function AddDateinDb(idTache, start, end){
		var params = {
			idProjet: projectId,
			idTache: idTache,
			idSousTache: -1,
			dateDebut: start,
			dateFin: end
		}
		return GET("projects/getLastInsertedSubtask", {id: projectId})
		.then(data => {
			params.idSousTache = data[0].id
			return POST("dates/insertNewPlanning", params);
		})
	}

	function AddTaskInDb(task) {
		var params = {
			etat: 0,
       		idTache: task,
        	idProjet: projectId,
		}
		return POST("projects/insertNewTask", params)
		.then( _ => { return ReloadTasks() })
		.then( _ => { return props.updateTasksInDates(); })
	} 

	function DeleteSubtask(id) {
		return Promise.all([
			DELETE("projects/deleteSpecificSubtask", {id: id}),
			DELETE("projects/deleteOrphanTasks", {})
		])
		.then( _ => { return ReloadTasks() });
	}

	function orderUp(subtask){
		var params = {
			subtask1Id : subtask.idDb,
			subtask2Id : subtask.previous.id,
			subtask2Id_bis : subtask.previous.id,
			subtask1Id_bis : subtask.idDb  
		}
		return UPDATE("projects/swapOrderIndexBetweenTwoSubtasks", params)
		.then( _ => { return ReloadTasks() });
	}

	function orderDown(subtask){
		var params = {
			subtask1Id : subtask.idDb,
			subtask2Id : subtask.next.id,
			subtask2Id_bis : subtask.next.id,
			subtask1Id_bis : subtask.idDb  
		}
		return UPDATE("projects/swapOrderIndexBetweenTwoSubtasks", params)
		.then( _ => { return ReloadTasks() });
	}

	function UpdateSubtaskStatus(id, etat) {
		var params = {
			etat: (etat === 0) ? 1 : 0,
        	subTaskID: id,
		}
		return UPDATE("projects/updateSubTaskStatus", params)
		.then( _ => { return ReloadTasks() });
	}

	function DeleteTask(id) {
		Promise.all([
			DELETE("projects/deleteSpecificSubTaskFromProject", {taskId: id}),
			DELETE("projects/deleteSpecificTaskFromProject", {id: id})
		]).then( _ => { return ReloadTasks() });

	}
	//#endregion

	function GetSubtaskArray(id) {
		return templateSubtask.find(element => element.id === id)
	}

	function GetHidden(panel){
		return expanded !== panel;
	}

	return(
		<Stack direction='column' margin="2em auto 2em 2.5%" width="95%" spacing={3} hidden={props.hidden(0)}>
			{tasks.map((task, i) => {
				var progress = 0;
				if(task.subtask.length === 0) progress = 100;
				else {
					task.subtask.forEach((sub, i) => progress += (1 / task.subtask.length) * 100 * sub.etat);
					progress = Math.ceil(progress); 
				}
				
				return (
					<Accordion key={i} expanded={expanded === task.id} onChange={handleChange(task.id)}>
						<AccordionSummary >
							<Stack width='100%' alignItems="center" direction='row' spacing='2' justifyContent="space-between">
								<Stack direction='row' spacing='2'>
									<Typography variant='body1' color={theme.palette.text.primary}>{task.nom}</Typography>
									{task.subtask.length > 0 && <StyledBadge badgeContent={tasks[i].subtask.length} color="primary" />}
								</Stack>
							<TaskCompletion sx={{width: '20%'}} variant='determinate' value={progress} color={(progress === 100) ? 'success' : (progress > 50) ? 'warning' : 'error'}/>
							</Stack>
						</AccordionSummary>
						<AccordionDetails>
							<TaskInformation data={task} projectId={parseInt(props.projectId)} projectName={props.projectName}  add={AddSubtasksInDb} updateStatus={UpdateSubtaskStatus} delete={DeleteSubtask} deleteTask={DeleteTask} orderUp={orderUp} orderDown={orderDown} pepoleList={pepoleList} templateSubtask={GetSubtaskArray} hidden={GetHidden}/>
						</AccordionDetails>
					</Accordion>
				)
			})}
			<AddTask add={AddTaskInDb} tasks={tasks} templateTask={templateTask}/>
		</Stack>
	)
});

const StyledBadge = styled(Badge)(({ theme }) => ({
	'& .MuiBadge-badge': {
	  right: -25,
	  top: 13,
	  border: `2px solid ${theme.palette.background.paper}`,
	  padding: '0 10px',
	  alignSelf: 'center'
	},
}));

const TaskCompletion = styled(LinearProgress)(({ theme }) => ({
	height: 10,
	borderRadius: 5,

	[`&.${linearProgressClasses.colorPrimary}`]: {
	  backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
	},
  }));

//#region TASKS INFO
const TaskInformation = (props) => {
	const columns = [
		{ field: 'id', headerName: 'ID', width: 70 },
		{ field: 'subtask', headerName: 'Sous-Tâche', width: 400, editable: true },
		{ field: 'state', headerName: 'État', width: 75, renderCell: (params) => {
			if(params.row.state === 0) return <ErrorTwoToneIcon color='error'/>
			else return <CheckCircleTwoToneIcon color='success'/>
		}},
		{ field: 'pepole', headerName: 'Personne', minWidth: 200, flex: 1, renderCell: (params) => (<SubtaskLine pepole={params.row.pepole} pepoleList={props.pepoleList} idDb={params.row.idDb} projectId={props.projectId}  projectName={props.projectName} task={props.data} subtask={params.row.subtask} />)},
		{ field: 'action', headerName: 'Action', width: 250, renderCell: (params) => (
			<Stack  direction='row' spacing={2} >
				<Button variant="outlined" color="warning" size="small" onClick={ _ => props.updateStatus(params.row.idDb, params.row.state)}>Check</Button>
				<Button variant="outlined" color="error" size="small" onClick={ _ => props.delete(params.row.idDb)}>Supprimer</Button>
				<Stack  direction='column' >
					<IconButton outline aria-label="orderUp" size="small" sx={{padding:0}} onClick={  _ => props.orderUp(params.row) } >
						<ArrowDropUp fontSize="inherit" />
					</IconButton>
					<IconButton outline aria-label="orderDown" size="small" sx={{padding:0}} onClick={ _ => props.orderDown(params.row) } >
						<ArrowDropDown fontSize="inherit" />
					</IconButton>
				</Stack>
			</Stack>
		) },
	];
	// console.log(props.data);
	let rows = [];
	let subtasksRows = [...props.data.subtask];
	subtasksRows.sort((a, b) => a.orderIndex - b.orderIndex);
	subtasksRows.forEach((sub, i) => {
		rows.push({ id: i + 1, isEditable: sub.nom === "Autre", subtask: (sub.nom === "Autre") ? sub.texte : sub.nom, pepole: JSON.parse(sub.employe), idDb: sub.id, state: sub.etat });
	})
  // Set previous and next subtasks
	rows.forEach((sub, i) => {
		sub.previous = subtasksRows[i===0?subtasksRows.length-1:i-1];
		sub.next = subtasksRows[i===subtasksRows.length-1?0:i+1];
	})

	if(props.hidden(props.data.id)){
		return <div></div>
	}

	function HandleRowEditStart(params, event){
		event.defaultMuiPrevented = true;
	};
	
	function HandleRowEditStop(params, event){
		event.defaultMuiPrevented = true;
	};

	function ProcessRowUpdate(newRow, oldRow){
		const updatedRow = { ...newRow };
		if(oldRow.subtask === updatedRow.subtask) return updatedRow;
		
		return UPDATE("projects/updateSubtaskName", {id: updatedRow.idDb, texte: updatedRow.subtask})
		.then( _ => {
			return updatedRow;
		})
	};

	return (
		<div>
			<div style={{height: "500px"}}>
				<DataGrid columns={columns} rows={rows} pageSize={25} rowsPerPageOptions={[5, 25, 50]}
					experimentalFeatures={{ newEditingApi: true }}
					editMode='cell'
					onRowEditStart={HandleRowEditStart}
					onRowEditStop={HandleRowEditStop}
					processRowUpdate={ProcessRowUpdate}
					isCellEditable={(params) =>  params.row.isEditable}
				/>
			</div>
			<Stack marginTop={2} direction="row" spacing={2}>
				<AddSubtaskInfo add={props.add} templateSubtask={props.templateSubtask} task={props.data}/>
				<DeleteTask deleteTask={props.deleteTask} name={props.data.nom} taskId={props.data.id}/>	
			</Stack>
		</div>
	)
}

const SubtaskLine = (props) => {
	const pepoles = props.pepole;
	const [selectedPepole, setSelectedPepole] = React.useState(props.pepole)
	const changeSelectedPepole = (event) => setSelectedPepole(event.target.value);

	function GetUrlForNotif(){
		var url = new URL(window.location.href.replace(window.location.search,''));
		url.searchParams.append("id", props.projectId)
		url.searchParams.append("task", props.task.taskId)
		return url.href;
	}

	function UpdateSelectedEmployes(){
		var params = {employe: JSON.stringify(selectedPepole), id: props.idDb};
		UPDATE("projects/updateSpecificSubtask", params)
		.then( _ => {
			var pepoleToNotify = [];
			selectedPepole.forEach(element => {
				if(!pepoles.includes(element)){
					pepoleToNotify.push({id: element, added: true});
				}
			})
			pepoles.forEach(element => {
				if(!selectedPepole.includes(element)){
					pepoleToNotify.push({id: element, added: false});
				}
			})

			const pepoleData = JSON.parse(Session.get('pepoleData'));
			var messageAdded = "(Tasks:421) <sender> t'a assigné à la sous-tâche " + props.subtask + " appartenant à la tâche " + props.task.nom + " du projet " + props.projectName;
			var messageRemoved = "(Tasks:422) <sender> t'a retiré de la sous-tâche " + props.subtask + " appartenant à la tâche " + props.task.nom + " du projet " + props.projectName;
			var sender = String(pepoleData.prenom).toLowerCase();

			pepoleToNotify.forEach(async element => {
				var tmp = props.pepoleList.find(item => item.id === element.id)
				if(tmp === undefined) return;

				var user = String(tmp.prenom).toLowerCase();
				var url = GetUrlForNotif();

				if(element.added){
					await SEND_NOTIFICATION_SLACK(sender, user, messageAdded, url);
				} else {
					await SEND_NOTIFICATION_SLACK(sender, user, messageRemoved, url);
				}
			})
		})
	}

	return (
		<Select style={{minWidth: "100%"}} variant='standard' multiple value={selectedPepole} onChange={changeSelectedPepole} onClose={async () => await UpdateSelectedEmployes()}>
			{props.pepoleList.map((pepole, i) => (
				<MenuItem value={pepole.id} key={pepole.id}>{pepole.prenom + " " + pepole.nom}</MenuItem>
			))}
		</Select>
	)
}

const AddSubtaskInfo = (props) => {
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const [subtask, setSubtask] = React.useState(0);
	const [text, setText] = React.useState("");
	const [templateSubtask, setTemplateSubtask] = React.useState([]);
	const [addDate, setAddDate] = React.useState(1);
	const [start, setStart] = React.useState(dayjs());
	const [end, setEnd] = React.useState(dayjs(start.hour(start.hour() +1)));
	const changeStart = (event) => {
			setEnd(event.add('1', 'hour'));
			setStart(event);
	}
	const changeEnd = (event) => setEnd(event);
	const changeSubtask = (event) => setSubtask(event.target.value);
	const changeText = (event) => setText(event.target.value);

	function HandleClose(save){
		if(save){
			props.add(templateSubtask[subtask].id, props.task.id, (templateSubtask[subtask].nom === "Autre") ? text : "", addDate === 0, GetStrFromDaysJS(start), GetStrFromDaysJS(end));
		}
		setSubtask(0);
		setText("");
		setOpen(false);
	}

	function HandleOpen(){
		setTemplateSubtask(props.templateSubtask(props.task.taskId).data);
		
		setOpen(true)
	}

	function HandleAddDate(event, value){
		if(value !== null){
			setAddDate(value);
		}
	}

	return (
		<div>
			<Button variant='contained' onClick={() => HandleOpen()}>Ajouter une sous-tâche</Button>
			<Dialog maxWidth="md" open={open} onClose={() => HandleClose(false)}>
				<DialogTitle>
					<Typography fontSize='1.2em' color={theme.palette.text.primary}>Ajouter une sous-tâche</Typography>
				</DialogTitle>
				<DialogContent>
					<Stack marginTop={2} spacing={2} direction="column">
						<TextField fullWidth select label="Tâche" variant="standard" value={subtask} onChange={changeSubtask}>
							{templateSubtask.map((sub, i) => (
								<MenuItem value={i} key={i}>{sub.nom}</MenuItem>
							))}
							<MenuItem value={-10}></MenuItem>
						</TextField>
						{templateSubtask[subtask] !== undefined && templateSubtask[subtask].nom === 'Autre' && <TextField label="Nom de la tâche" variant="standard" value={text} onChange={changeText}/>}
						<Stack marginX="auto" marginTop={2} alignItems="center">
							<Typography variant='body1'>Ajouter une date ?</Typography>
							<ToggleButtonGroup value={addDate} label="Lien internet" color="primary" onChange={HandleAddDate} exclusive >
								<ToggleButton value={0} size="small">OUI</ToggleButton>
								<ToggleButton value={1} size="small">NON</ToggleButton>
							</ToggleButtonGroup>
						</Stack>
						<LocalizationProvider dateAdapter={AdapterDayjs} >
							<Stack spacing={2} direction="column" hidden={addDate === 1}>
								<DateTimePicker hidden={addDate === 0} label="Date & Heure de début" value={start} onChange={changeStart} ampm={false} inputFormat="DD-MM-YYYY  HH:mm" 
									shouldDisableTime={(timeValue, clockType) => {
										if (clockType === 'minutes' && timeValue % 5) {
										return true;
										}
										return false;
									}}
									renderInput={(params) => <TextField variant="standard" {...params} />} 
								/>
								<DateTimePicker label="Date & Heure de fin" minDateTime={start} value={end} onChange={changeEnd} ampm={false} inputFormat="DD-MM-YYYY  HH:mm"
									shouldDisableTime={(timeValue, clockType) => {
										if (clockType === 'minutes' && timeValue % 5) {
										return true;
										}
										return false;
									}}
									renderInput={(params) => <TextField variant="standard" {...params} />} 
								/>
							</Stack>
						</LocalizationProvider>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => HandleClose(true)} color="success">Ajouter</Button>
					<Button onClick={() => HandleClose(false)}>Retour</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

const DeleteTask = (props) => {
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);

	function HandleClose(hasToDelete) {
		if(hasToDelete){
			props.deleteTask(props.taskId)
		}
			
		setOpen(false);
	}
	return (
		<div>
			<Button variant='contained' color='error' onClick={() => setOpen(true)}>Supprimer la tâche</Button>
			<Dialog maxWidth="md" open={open} onClose={HandleClose}>
				<DialogTitle>
					<Typography fontSize='1.2em' color={theme.palette.text.primary}>Supprimer une tâche</Typography>
				</DialogTitle>
				<DialogContent>
					<p>Voulez-vous supprimer la tâche : {props.name}</p>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => HandleClose(true)} color="error">Supprimer</Button>
					<Button onClick={() => HandleClose(false)}>Retour</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

const AddTask = (props) => {
	const [open, setOpen] = React.useState(false);
	const [task, setTask] = React.useState(0);
	const theme = useTheme();
	const changeTask = (event) => setTask(event.target.value);

	function HandleClose(save) {
		if(save){
			props.add(props.templateTask[task].id)
		} 
		setTask(0);
		setOpen(false);
	}
	return (
		<div>
			<Button variant='contained' onClick={() => setOpen(true)}>Ajouter une tâche</Button>
			<Dialog maxWidth="md" open={open} onClose={() => HandleClose(false)}>
				<DialogTitle>
					<Typography fontSize='1.2em' color={theme.palette.text.primary}>Ajouter une tâche</Typography>
				</DialogTitle>
				<DialogContent>
					<TextField fullWidth select label="Tâche" variant="standard" value={task} onChange={changeTask}>
						{props.templateTask !== undefined && props.tasks !== undefined && props.templateTask.map((task, i) => props.tasks.filter(e => e.taskId === task.id).length === 0  && <MenuItem value={i} key={i}>{task.nom}</MenuItem>)}
					</TextField>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => HandleClose(true)} color="success">Ajouter</Button>
					<Button onClick={() => HandleClose(false)}>Retour</Button>
				</DialogActions>
			</Dialog>
		</div>
		
	)
}


function GetStrFromDaysJS(date){
	return date.$y + "-" + GetDigits(date.$M + 1) + "-" + GetDigits(date.$D) + "T" + GetDigits(date.$H) + ':' + GetDigits(date.$m) + ":" + GetDigits(date.$s);
}

function GetDigits(value){
	var str ='' + value;
	return str.padStart(2, '0');
}
//#endregion