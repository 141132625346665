
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import AdminPanelSettingsTwoToneIcon from '@mui/icons-material/AdminPanelSettingsTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
//import Groups3TwoToneIcon from '@mui/icons-material/Groups3TwoTone';
import FactCheckTwoToneIcon from '@mui/icons-material/FactCheckTwoTone';
import ThumbsUpDownTwoToneIcon from '@mui/icons-material/ThumbsUpDownTwoTone';

export const NavItems = [
	{
		id: 1,
		title: "Projets",
		path: "/projects",
		nName: "nav-item",
		sName: "sidebar-item",
		icon: <HomeTwoToneIcon color='info'/>,
		level: 1,
		mobile: true,
	},
	{
		id: 11,
		title: "Tâches",
		path: "/tasks",
		nName: "nav-item",
		sName: "sidebar-item",
		icon: <AssignmentTwoToneIcon color='info'/>,
		level: 1,
		mobile: true,
	},
	{
		id: 2,
		title: "Tâches Personnalisées",
		path: "/customTask",
		nName: "nav-item",
		sName: "sidebar-item",
		icon: <AssignmentIndTwoToneIcon color='info'/>,
		level: 1,
		mobile: true,
	},
	// {
	// 	id: 8,
	// 	title: "Synthèse Semaine",
	// 	path: "/commonTask",
	// 	nName: "nav-item",
	// 	sName: "sidebar-item",
	// 	icon: <Groups3TwoToneIcon color='info'/>,
	// 	level: 1,
	// 	mobile: true,
	// },
	{
		id: 3,
		title: "Planning",
		path: "/planning",
		nName: "nav-item",
		sName: "sidebar-item",
		icon: <CalendarMonthTwoToneIcon color='info'/>,
		level: 1,
		mobile: true,
	},
	{
		id: 4,
		title: "Devis",
		path: "/devis",
		nName: "nav-item",
		sName: "sidebar-item",
		icon: <ArticleTwoToneIcon color='info'/>,
		level: 2,
		mobile: true,
	},
	{
		id: 9,
		title: "Process",
		path: "/checklist",
		nName: "nav-item",
		sName: "sidebar-item",
		icon: <FactCheckTwoToneIcon color='info'/>,
		level: 1,
		mobile: true,
	},
	{
		id: 10,
		title: "Point d'amélioration",
		path: "/improvement",
		nName: "nav-item",
		sName: "sidebar-item",
		icon: <ThumbsUpDownTwoToneIcon color='info'/>,
		level: 1,
		mobile: true,
	},
	{
		id: 5,
		title: "Factures",
		path: "/facture",
		nName: "nav-item",
		sName: "sidebar-item",
		icon: <ReceiptTwoToneIcon color='info'/>,
		level: 3,
		mobile: true,
	},
	{
		id: 6,
		title: "Administration",
		path: "/administration",
		nName: "nav-item",
		sName: "sidebar-item",
		icon: <AdminPanelSettingsTwoToneIcon color='info' />,
		level: 4,
		mobile: true,
	},
	{
		id: 7,
		title: "Compte",
		path: "/accompte",
		nName: "nav-item",
		sName: "sidebar-item",
		icon: <AccountCircleTwoToneIcon color='info'/>,
		level: 1,
		mobile: true,
	},
]