import React from 'react';
import {LoginComponent} from '../components/Login/LoginComponent';

// Page Login
const Login = () => {
    return (
		    <LoginComponent/>
    );
};

export default Login;