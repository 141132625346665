import React  from "react";
import { GET, UPDATE } from '../../../utils/AxiosRequest';

import Button from '@mui/material/Button';

import { DataGrid } from '@mui/x-data-grid';

import { Stack, darken, lighten } from "@mui/material";


export const ValidateFactureAdmin = (props) => {
	const [factures, setFactures] = React.useState([]);


	React.useEffect(() => {
		LoadData();

	}, [])

	const columns = [
		{field: 'status', headerName:'État', width: 150},
		{ field: 'name', headerName: 'Nom Facture', width: 200 },
		{ field: 'date', headerName: 'DateFacture', width: 200 },
		{ field: 'echeance', headerName: 'Échéance', width: 200 },
		{ field: 'total', headerName: 'Total', width: 200 },

		{ field: 'aciton', headerName: 'Action', minWidth: 600, flex: 1, renderCell: (params) => {
			return (
				<Stack direction="row" justifyContent="space-between" width="100%" marginX={2}>
					<Button variant="contained" color="warning" onClick={() => ChangeFactureType(params.row.id, 0)}>Passer en Facture Crée</Button>
					<Button variant="contained" color="info" onClick={() => ChangeFactureType(params.row.id, 1)}>Passer en Facture Envoyée</Button>
					<Button variant="contained" color="success" onClick={() => ChangeFactureType(params.row.id, 2)}>Passer en Facture Réglée</Button>
				</Stack>
			)
		}},
	]

	const rows = [];

	factures.forEach(facture => {
		rows.push({
			id: facture.id,
			type: facture.valide,
			status: GetRowTypeText(facture.valide),
			name: facture.nom,
			date: facture.date,
			echeance: facture.echeance,
			total: parseFloat(facture.total).toFixed(2) + " € HT",
		})
	})

	function ChangeFactureType(id, type){
		return UPDATE("administration/updateFactureStatus", {id, valide: type})
		.then( _ => {
			// setFactures( oldArray => {
			// 	var index = oldArray.findIndex(item => item.id === id);
			// 	if(index !== -1){
			// 		oldArray[index].type = type;
			// 		oldArray[index].status = GetRowTypeText(type);
			// 	}
			// 	return [...oldArray];
			// })
			return LoadData();
		})
	}


	function GetRowTypeText(type){
		switch (type) {
			case 0: return "Facture Crée";
			case 1: return "Facture Envoyée";
			case 2: return "Facture Payée";
			default: return "Problème";
		}
	}

	function GetRowClasseName(type){ 
		switch (type) {
			case 0: return classes.created;
			case 1: return classes.sended;
			case 2: return classes.payed;
		
			default: return classes.created;
		}
	}

	function LoadData(){
		return Promise.all([
			GET("administration/getAllFactures"),
			GET("administration/getAllClient")
		])
		.then(data => {
			var tmp = []
			data[0].forEach(element => {
				var client = data[1].find(item => item.id === element.clientId);
				tmp.push({
					...element,
					nomEntite: client.nomEntite
				})
			})
			setFactures(tmp);
		})

	}

    return (
		<Stack justifyContent="center" height="80%" margin={5} spacing={2} sx={styles}>
			<DataGrid columns={columns} rows={rows} rowsPerPageOptions={[20, 50, 100]} pagination initialState={{pagination: { pageSize: 100, }}} getRowClassName={(params) => GetRowClasseName(params.row.type)} />
		</Stack>
        
					
    )
}

const PREFIX = 'Inspiration';

const classes = {
    created: `${PREFIX}-created`,
    sended: `${PREFIX}-sended`,
    payed: `${PREFIX}-payed`,

};


const getBackgroundColor = (color, mode) => mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) => mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedBackgroundColor = (color, mode) => mode === 'dark' ? darken(color, 0.3) : lighten(color, 0.3);



const styles = {
	'.MuiDataGrid-cell': {
		border: 'none !important',
	},
	// '.Mui-selected': {
	// 	bgcolor: "blue !important"
	// },
	[`& .${classes.created}`]: {
		bgcolor : (theme) => getBackgroundColor(theme.palette.warning.main, theme.palette.mode),
		'&:hover': {
			bgcolor: (theme) => getHoverBackgroundColor(theme.palette.warning.main, theme.palette.mode) + ' !important',
		},
		'&.Mui-selected': {
			bgcolor: (theme) => getSelectedBackgroundColor(theme.palette.warning.main, theme.palette.mode) + ' !important',
		},
	}, 
	[`& .${classes.sended}`]: {
		bgcolor : (theme) => getBackgroundColor(theme.palette.info.main, theme.palette.mode),
		'&:hover': {
			bgcolor: (theme) => getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode) + ' !important',
		},
		'&.Mui-selected': {
			bgcolor: (theme) => getSelectedBackgroundColor(theme.palette.info.main, theme.palette.mode) + ' !important',
		},
	}, 
	[`& .${classes.payed}`]: {
		bgcolor : (theme) => getBackgroundColor(theme.palette.success.main, theme.palette.mode),
		'&:hover': {
			bgcolor: (theme) => getHoverBackgroundColor(theme.palette.success.main, theme.palette.mode) + ' !important',
		},
		'&.Mui-selected': {
			bgcolor: (theme) => getSelectedBackgroundColor(theme.palette.success.main, theme.palette.mode) + ' !important',
		},
	}, 
}