import React from "react";
import { Link } from "react-router-dom";
import Session from 'react-session-api'

import useMediaQuery from '@mui/material/useMediaQuery';


import { NavItems } from "./NavItem";
import { alpha, useTheme } from "@mui/material";

import { Sidebar, Menu, MenuItem, useProSidebar  } from 'react-pro-sidebar';
import { subscribe, unsubscribe } from "../utils/Event";

export const Navbar = (props) => {
	const isDesktop = useMediaQuery('(min-width:1065px)');
	const { collapseSidebar } = useProSidebar();
	const theme = useTheme();
	const level = JSON.parse(Session.get('pepoleData'))?.authorisationLevel;

	React.useEffect(() => {
		subscribe('onToggleNavbar', (data) => {
			collapseSidebar(data.detail.open);
		});
		return () => {
			unsubscribe("onToggleNavbar", () => {});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if(level === undefined) return null;
	return (
		<Sidebar style={{height: '100vh'}} width={(isDesktop) ? '200px' : '100%'} collapsedWidth={(isDesktop) ? "80px" : '0px'} defaultCollapsed onMouseEnter={() => collapseSidebar(false)} onMouseLeave={() => collapseSidebar(true)} transitionDuration={500} backgroundColor={alpha(theme.palette.background.paper, 0.5)}>
			<Menu style={{marginTop: 15}} menuItemStyles={{
				button: {
					backgroundColor: alpha(theme.palette.background.paper, 0.5),
					'&:hover': {
						backgroundColor: (theme.palette.mode === 'light') ? alpha('#000000', 0.2) : alpha('#ffffff', 0.2),
					}
				}
			}}>
				{NavItems.map(item => {
						if(level !== undefined && level >= item.level && (isDesktop || item.mobile))
							return (<MenuItem key={item.id} component={<Link to={item.path}/>} icon={item.icon} style={{}}>{item.title}</MenuItem>)
						else return null
					})
				}
			</Menu>
		</Sidebar>
	)
}