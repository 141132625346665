import React, { useImperativeHandle }  from "react";
import { GET, UPDATE } from '../../../utils/AxiosRequest';

import Button from '@mui/material/Button';

import dayjs from 'dayjs';

import { DataGrid } from '@mui/x-data-grid';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle'

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


import { Typography, useTheme } from "@mui/material";
import { ProjectCreation } from "../../Projets/ProjectHome/AddProjet/ProjectCreation";

const NUMBER_OF_DAYS_TO_RELANCE = 15;

export const ValidateDevis = (props) => {
	const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = React.useState(0);
	const [devisValide, setDevisValide] = React.useState([]);
	const [devisRelance, setDevisRelance] = React.useState([]);
	const [devisNonValide, setDevisNonValide] = React.useState([]);
	const confirmRef = React.useRef(null);
	const createProjectRef = React.useRef(null);
    const changeSelected = (event, tab) => setSelected(tab);

	const columns = [
		{ field: 'name', headerName: 'Nom Devis', minWidth: 100, flex: 0.5},
		{ field: 'entite', headerName: 'Nom Entité', minWidth: 200, flex: 1},
		{ field: 'client', headerName: 'Nom Client', minWidth: 200, flex: 1},
		{ field: 'reference', headerName: 'Référence', minWidth: 200, flex: 1},
		{ field: 'mail', headerName: 'Mail', minWidth: 200, flex: 1},
		{ field: 'commande', headerName: 'Numéro Commande', minWidth: 200, flex: 1, editable: true, renderCell: (params) => {
			if(params.row.commande === ""){
				return <Typography color="error" variant="body2">Pas de numéro de commande</Typography>
			}
		}},
		{ field: 'aciton', headerName: 'Action', width: 150, renderCell: (params) => {if(params.row.save) return <Button color="success" onClick={() => ValidateDevis(params.row.id)}>Valider</Button>}},
	]

    function HandleOpen(){
		return GET("devis/getAllDevis")
		.then(data => {
			var dv = [];
			var dr = [];
			var dnv = [];
			var currentDate = dayjs();
			data.forEach(devis => {
				if(devis.valide === 1) dv.push({id: devis.id, mail: devis.mailClient, commande: devis.numeroCommande, name: devis.nom, entite: devis.nomEntite, client: devis.contactClient, reference: devis.reference, save: false});
				else{
					var devisDate = dayjs(new Date(devis.date.split('/')[2], parseInt(devis.date.split('/')[1]) - 1, devis.date.split('/')[0]));
					if(currentDate.diff(devisDate, 'day') > NUMBER_OF_DAYS_TO_RELANCE) 
						dr.push({id: devis.id, mail: devis.mailClient, commande: devis.numeroCommande, name: devis.nom, entite: devis.nomEntite, client: devis.contactClient, reference: devis.reference, save: true});
					dnv.push({id: devis.id, name: devis.nom, mail: devis.mailClient, commande: devis.numeroCommande, entite: devis.nomEntite, client: devis.contactClient, reference: devis.reference, save: true});
				}	
			})
			setDevisValide(dv);
			setDevisRelance(dr);
			setDevisNonValide(dnv);
			setOpen(true);
		})
		
    }

    function HandleClose(){
        setOpen(false);
    }

	function GetView(){
		switch(selected){
			case 0 : return DevisView(devisValide);
			case 1 : return DevisView(devisRelance);
			case 2 : return DevisView(devisNonValide);
			default: return DevisView(devisValide);
		}
	}

	function HandleRowEditStart(params, event){
		event.defaultMuiPrevented = true;
	};
	
	function HandleRowEditStop(params, event){
		event.defaultMuiPrevented = true;
	};

	function ProcessRowUpdate(newRow){
		const updatedRow = { ...newRow, isNew: false };

		return UPDATE("devis/updateNumeroCommande", {numeroCommande: updatedRow.commande, id: updatedRow.id})
		.then(() => {
			switch(selected){
				case 0 : setDevisValide(devisValide.map(row =>(row.id === updatedRow.id) ? updatedRow : row));
					break;
				case 1 : setDevisRelance(devisRelance.map(row =>(row.id === updatedRow.id) ? updatedRow : row));
					break;
				case 2 : setDevisNonValide(devisNonValide.map(row =>(row.id === updatedRow.id) ? updatedRow : row));
					break;
				default: setDevisValide(devisValide.map(row =>(row.id === updatedRow.id) ? updatedRow : row));
					break;
			}
			return updatedRow;
		})
		
	  };

	function DevisView(array){
		return <DataGrid sx={{height: "800px"}} columns={columns} rows={array} rowsPerPageOptions={[20, 50, 100]} pagination initialState={{pagination: { pageSize: 100, }}}
			experimentalFeatures={{ newEditingApi: true }}
			editMode='cell'
			onRowEditStart={HandleRowEditStart}
			onRowEditStop={HandleRowEditStop}
			processRowUpdate={ProcessRowUpdate}
		/>
	}

	function ValidateDevis(id){
		UPDATE("devis/updateDevisValide", {id: id})
		.then(confirmRef.current.handleOpen(id, createProjectRef))
		.then(HandleOpen())	
	}

	function CreateProject(id){
		GET("devis/getDevisById", {id})
		.then(data => {
			HandleClose();
			createProjectRef.current.LoadDataFromDevis(data[0]);
		})
	}

    return (
        <div>
            <Button fullWidth variant="contained" onClick={() => HandleOpen()}>Valider des devis</Button>
            <Dialog fullWidth maxWidth="xl" open={open} onClose={() => HandleClose(false)}>
				<DialogTitle color={theme.palette.text.primary}>Valider des devis</DialogTitle>
				<DialogContent>
					<Tabs value={selected} onChange={changeSelected} centered sx={{marginBottom: 2}}>
						<Tab label="Devis Validé" value={0} />
						<Tab label="Devis Non Validé" value={2}/>
						<Tab label="Devis à Relancer" value={1}/>
					</Tabs>
					{GetView()}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => HandleClose(true)} color="success">Valider</Button>
					<Button onClick={() => HandleClose(false)}>Retour</Button>
				</DialogActions>
			</Dialog>
			<SelectProjectCreation ref={confirmRef} close={CreateProject}/>
			<ProjectCreation ref={createProjectRef} insideDevis={true}/>
        </div>
    )
}

const SelectProjectCreation = React.forwardRef((props, ref) => {
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const [devisId, setDevisId] = React.useState(0);
	

	useImperativeHandle(ref, () => ({
		handleOpen: (id) => {
			setDevisId(id);
			setOpen(true);
		}
	}))

	function HandleClose(createProject){
		if(createProject){
			props.close(devisId);
		}
		setOpen(false);
	}

	return (
		<Dialog  maxWidth="sm" open={open} onClose={() => HandleClose(false)}>
			<DialogTitle color={theme.palette.text.primary}>Créer un projet avec ce devis ?</DialogTitle>
			<DialogContent>
				<p>Voulez-vous créer un projet avec ce devis ?</p>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => HandleClose(true)} color="success">Oui</Button>
				<Button onClick={() => HandleClose(false)}>Non</Button>
			</DialogActions>
		</Dialog>
	)
})