import Axios from "axios";
import { OpenSnackbar } from "../components/utils/Snackbar";

const GET = function (path, params) {
  return new Promise((resolve, reject) => {
    Axios.get(process.env.REACT_APP_API + path, {
      params: params,
    })
      .then((response) => resolve(response.data))
      .catch((error) => {
        console.log(error);
        OpenSnackbar(
          1,
          "Impossible de récupérer les données de : " + path,
          5000
        );
      });
  });
};

const POST = async (path, params) => {
  return new Promise((resolve, reject) => {
    Axios.post(process.env.REACT_APP_API + path, params)
      .then((response) => resolve(response.data))
      .catch((error) => {
        console.log(error);
        OpenSnackbar(
          1,
          "Impossible d'envoyer les données vers : " + path,
          5000
        );
      });
  });
};

const DELETE = async (path, params) => {
  return new Promise((resolve, reject) => {
    Axios.delete(process.env.REACT_APP_API + path, { data: params })
      .then((response) => resolve(response.data))
      .catch((error) => {
        console.log(error);
        OpenSnackbar(
          1,
          "Impossible de supprimer les données de : " + path,
          5000
        );
      });
  });
};

const UPDATE = async (path, params) => {
  return new Promise((resolve, reject) => {
    Axios.put(process.env.REACT_APP_API + path, params)
      .then((response) => resolve(response.data))
      .catch((error) => {
        console.log(error);
        OpenSnackbar(
          1,
          "Impossible de mettre à jours les données de : " + path,
          5000
        );
      });
  });
};

export { GET, POST, DELETE, UPDATE };
