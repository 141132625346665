import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs, useTheme } from '@mui/material';

import { ProjectCreationBrief } from './Views/Brief';
import { ProjectCreationInformation } from './Views/Information';
import { ProjectCreationTasks } from './Views/Tasks';
import { ProjectCreationResume } from './Views/Resume';
import { GET, POST, UPDATE } from '../../../../utils/AxiosRequest';

import dayjs from 'dayjs';

const DEADLINES = [78,80];

export const ProjectCreation = React.forwardRef((props, ref) => {
	const { insideDevis } = props;
	const [clientList, setClientList] = React.useState([]);
	const [pepoleList, setPepoleList] = React.useState([]);
	const [open, setOpen] = React.useState(false);
	const [currentTab, setCurrentTab] = React.useState(0);
	const [devisList, setDevisList] = React.useState([]);
	const [taskTemplate, setTaskTemplate] = React.useState([]);
	const [subtaskTemplate, setSubtaskTemplate] = React.useState([]);
	const theme = useTheme();

	const briefRef = React.useRef(null);
	const informationRef = React.useRef(null);
	const tasksRef = React.useRef(null);
	const resumeRef = React.useRef(null);

	function ChangeTab(event, tab){
		if(tab === 3){
			resumeRef.current.UpdateData(GetAllData());
		}
		setCurrentTab(tab);
	}

	React.useImperativeHandle(ref, () => ({
		LoadDataFromDevis: (data) => {
			return HandleOpen()
			.then( _ => {
				informationRef.current.SetDataFromDevis(data);
			})
			
		}
	}))

	function HandleOpen(){
		return Promise.all([
			GET("devis/getAllDevisWithoutProject"),
			GET("projects/getTaskTemplate"),
			GET("projects/getSubTaskTemplate"),
			GET("projects/getAllEmployeInfo", {}),
			GET("projects/getClientNom", {}),
		])
		.then(data => {
			setDevisList(data[0]);
			setTaskTemplate(data[1].sort((a, b) => a.id - b.id));
			setSubtaskTemplate(data[2]);
			setPepoleList(data[3]);
			setClientList(data[4]);
			setOpen(true);
		});
		
	}

	function HandleClose(){
		
		briefRef.current.Reset();
		informationRef.current.Reset();
		tasksRef.current.Reset();
		setOpen(false);
	}

	function GetAllData(){
		return {
			brief: (briefRef.current !== undefined) ? briefRef.current.GetData() : {}, 
			information: (informationRef.current !== undefined) ? informationRef.current.GetData() : {},
			tasks: (tasksRef.current !== undefined) ? tasksRef.current.GetData() : [],
		}
	}

	const GetHidden =(id) => { return currentTab !== id; }

	//#region CREATE PROJECT
	function CreateProject(){
		var project = GetAllData();
		var projectId = 0; 
		var tasks = [];
		AddTasksInDB(0, project.tasks)

		// Fix '' inserted as devisId instead of -1 (devisId is defined as INT in db)
		if(project.information.devis === ''){
			project.information.devis = -1;
		}

		AddProjetInDB(project.information)
		.then(data => {
			projectId = data[0].id;
			return AddTasksInDB(projectId, project.tasks);
		}).then(data => {
			tasks = data;
			return AddSubtaskInDB(projectId, project.tasks, tasks);	
		}).then(data => {

			AddDatesInDB(projectId, tasks, data, project.tasks)
			data.forEach((subtask) => {
				UPDATE("projects/updateSubtaskDefaultOrderIndex", {id: subtask.id})
			})	
		}).then( _ => {
			
			if(project.information.devis !== ""){
				return UPDATE("devis/updateDevisProjet", {id: project.information.devis, idProjet: projectId})
			}
		}).then( _ => {
			HandleClose();
			if(props.reload !== undefined){
				return props.reload();
			}
		})
	}

	function AddProjetInDB(information){
		var params = {
			nom: information.name,
			vimeo: information.vimeo,
			dossier: information.folder,
			idTaches: '',
			idClient: information.client,
			nomClient: information.clientName,
			mailClient: information.clientMail,
			dateAjout: GetStrFromDaysJS(dayjs()),
			deadline: GetStrFromDaysJS(dayjs()),
			devisId: information.devis,
			projectLeader: information.projectLeader,
			transverseProject: information.transverseProject,
		}

		return POST("projects/insertNewProject", params)
		.then(() => {
			return GET("projects/getNewProjectId");
		})
	}

	function AddTasksInDB(project, tasks){
		if(tasks && tasks.length > 0){
			var params = [];
			tasks.forEach(element => {
				params.push({idProjet: project, idTache: element.task.id});
			})
			return POST("projects/insertMultipleTask", params)
			.then(() => {
				return GET("projects/getAllTasks", {projectID: project});
			})
		}
	}

	function AddSubtaskInDB(project, tasks, tasksDB){
		if(tasks && tasks.length > 0){
			var params = [];
			tasks.forEach(element => {
				element.subtasks.forEach(subtask => {
					var taskFound = tasksDB.find(search => search.idTache === element.task.id);
					if(taskFound !== undefined){
						params.push({
							idSousTache: subtask.id,
							employe: JSON.stringify(subtask.pepoles),
							idTache: taskFound.id,
							idProjet: project,
							texte: subtask.name,
						})
					}
				})
			})
			return POST("projects/insertMultipleSubtask", params)
			.then(() => {
				return GET("projects/getAllSubTasksByProject", {projectID: project})
				
			})
		}
	}

	function AddDatesInDB(project, tasks, subtasks, data){
		if(tasks && tasks.length > 0){
			var params = [];
			var deadlineTask = tasks.find(element => element.idTache === 0);
			var deadlineCategorie = data.find(element => element.task.id === 0);

			if(deadlineTask !== undefined && deadlineCategorie !== undefined){
				subtasks.forEach(sub => {
					if(DEADLINES.indexOf(sub.idSoustache) !== -1){
						var tmp = deadlineCategorie.subtasks.find(element => element.subtask.id === sub.idSoustache);
						if(tmp !== undefined){
							var endDate = tmp.date.add(1, 'day');
							params.push({
								idProjet: project,
								idTache: deadlineTask.id,
								idSousTache: sub.id,
								dateDebut: GetStrFromDaysJS(tmp.date),
								dateFin: GetStrFromDaysJS(endDate),
							});
						}
					}
				})
				return POST("dates/insertMultipleDates", params)
				.then( () => {
					if(props.reload !== undefined){
						return props.reload();
					} 
				})
			}		
		}
	}
	//#endregion

	return (
		<div>
			<Button variant='contained' onClick={() => HandleOpen()} sx={{margin: 2.5}} hidden={insideDevis} >Nouveau projet</Button>
			<Dialog fullWidth maxWidth="xl" open={open} onClose={() => HandleClose(false)}>
                <DialogTitle color={theme.palette.text.primary}>Ajouter un projet</DialogTitle>
                <DialogContent>
					<Tabs value={currentTab} onChange={ChangeTab} variant="scrollable">
							<Tab label="Brief"/>
							<Tab label="Information du projet"/>
							<Tab label="Tâches"/>
							<Tab label="Résumé"/>
					</Tabs>
					<ProjectCreationBrief ref={briefRef} hidden={GetHidden}/>
					<ProjectCreationInformation ref={informationRef} clientList={clientList} devisList={devisList} pepoleList={pepoleList} hidden={GetHidden}/>
					<ProjectCreationTasks ref={tasksRef} pepoleList={pepoleList} taskTemplate={taskTemplate} subtaskTemplate={subtaskTemplate} hidden={GetHidden}/>
					<ProjectCreationResume ref={resumeRef} hidden={GetHidden} pepoleList={pepoleList} clientList={clientList} devisList={devisList} create={CreateProject}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => HandleClose(false)}>Annuler</Button>
                </DialogActions>
            </Dialog>
		</div>
		
	)
});

function GetStrFromDaysJS(date){
	return date.$y + "-" + GetDigits(date.$M + 1) + "-" + GetDigits(date.$D) + "T" + GetDigits(0) + ':' + GetDigits(0) + ":" + GetDigits(0);
}

function GetDigits(value){
	var str ='' + value;
	return str.padStart(2, '0');
}
