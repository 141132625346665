import React from "react";
import { GET, POST, UPDATE } from '../../utils/AxiosRequest'

import { PDFViewer } from "@react-pdf/renderer";
import { DevisPDF } from "./DevisPDF/DevisPdfGenerator";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography, useTheme } from "@mui/material";
import { OpenSnackbar } from "../utils/Snackbar";

export const DevisViewer = (props) => {
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const [name, setName] = React.useState("");
	const [devisData, setDevisData] = React.useState({});

	function HandleOpen(){
		var data = props.getDataForPDF();
		var header = data.header;

		var devisData = "[";
		data.body.forEach((line) => {
			devisData += JSON.stringify(line) + ",";
		});
		devisData = devisData.substring(0, devisData.length - 1);
		devisData += "]";

		var dueDatesData = "[";
		header.dueDatesData.forEach((dueDate) => {
			dueDatesData += JSON.stringify(dueDate) + ",";
		});
		// At least one dueDate
		if(dueDatesData !== "[") dueDatesData = dueDatesData.substring(0, dueDatesData.length - 1);
		dueDatesData += "]";

		var params = {
			id: header.devisId,
			nom: header.devisNom,
			date: header.devisDate,
			idClient: header.clientId,
			fournisseur: header.fournisseur,
			centreCout: header.centreCout,
			contactClient: header.contact,
			mailClient: header.mailClient,
			reference: header.reference,
			accompte: (header.accompte) ? header.accompte : 0,
			dueDatesData: dueDatesData,
			devisData: devisData,
			nomProjet: header.nomProjet,
			total: parseFloat(data.total)
		};

		if (!data.save){
			return GET("devis/getNewDevisId", {})
			.then(res => {
				if(res === null) return;
				params.id = res[0].devisId + 1;
				params.nom = "D_" + String(res[0].devisId + 1).padStart(6, "0");
				data.header.devisId = params.id;
				data.header.devisNom = params.nom;
				return POST("devis/insertNewDevis", params);
			}).then(() => {
				// DevisComponent make the difference between a template and a real devis (a template implies the devis number must increment)
				// DevisComponent.setSaveOverride(true) implies the current id's devis will be overwritten (without any id increment)
				props.setSaveOverride(true);
				setName("Devis N°" + data.header.devisNom + " - " + header.clientInfo.nomEntite + ".pdf");
				setDevisData(data);
				setOpen(true);
				OpenSnackbar(0, "Le devis a bien été créé", 5000);
			})
		} else {
			return UPDATE("devis/updateDevisById", params)
			.then(() => {
				setName("Devis N°" + data.header.devisNom + " - " + header.clientInfo.nomEntite + ".pdf");
				setDevisData(data)
				setOpen(true);
				OpenSnackbar(0, "Le devis a bien été mis à jour", 5000);
			})
		} 

	}

	function HandleClose(){
		setOpen(false);
	}

	return (
		<div>
            <Button fullWidth variant="contained" color="success" onClick={() => HandleOpen()} disabled={!props.save}>Créer le PDF</Button>
            <Dialog fullWidth maxWidth="xl" open={open} onClose={HandleClose}>
                <DialogTitle color={theme.palette.text.primary}>Création du devis en PDF</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} direction="column" alignItems='center'>
                        <CopyToClipboard text={name} style={{ marginLeft: "10px" }}>
                            <Tooltip title={"Copier le nom du pdf : " + name}>
                                <Stack direction='row' spacing={2} textAlign='center'>
                                    <Typography variant="body1" textAlign='center' paddingBottom={0} color={theme.palette.text.primary}>Cliquer ici pour copier le nom du pdf</Typography>
                                    <IconButton>
                                        <ContentPasteIcon color="primary" fontSize="small" />
                                    </IconButton>
                                </Stack>
                            </Tooltip>
                        </CopyToClipboard>
                        <PDFViewer style={{minHeight:"750px", maxHeight: '1500px', width: '100%'}}>
							<DevisPDF data={devisData}></DevisPDF>
                        </PDFViewer>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => HandleClose()}>Fermer</Button>
                </DialogActions>
            </Dialog>
        </div>
		
	)
}


// function getShortDate(date){
// 	const yyyy = date.getFullYear();
// 	let mm = date.getMonth() + 1; // Months start at 0!
// 	let dd = date.getDate();

// 	if (dd < 10) dd = "0" + dd;
// 	if (mm < 10) mm = "0" + mm;

// 	return dd + "/" + mm + "/" + yyyy;
// };

// function getEcheanceDate(id){
// 	var echeance = new Date();
// 	switch(id){
// 		case 2: echeance.setDate(echeance.getDate() + 30);
// 			break;
// 		case 3: 
// 			echeance = new Date(echeance.getFullYear(), echeance.getMonth() + 1, 1);
// 			echeance.setDate(echeance.getDate() + 30);
// 			break;
// 		case 4: 
// 			echeance = new Date(echeance.getFullYear(), echeance.getMonth() + 1, 1);
// 			echeance.setDate(echeance.getDate() + 45);
// 			break;
// 		case 5: 
// 			echeance = new Date(echeance.getFullYear(), echeance.getMonth() + 1, 10);
// 			break;
// 		case 6: echeance.setDate(echeance.getDate() + 60);
// 			break;
// 		default: break;
// 	}
// 	return getShortDate(echeance);
// }
